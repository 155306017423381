import React, { useEffect, useRef, useState } from 'react';
import { useBrandBooks } from '../../../hooks/useBrandBooks';
import { TfiArrowTopRight } from 'react-icons/tfi';
import ReactPlayer from 'react-player';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Stone: React.FC = () => {
  const { brandbook, isLoading } = useBrandBooks();
  const [observers, setObservers] = useState<IntersectionObserver[]>([]);
  const videoPlayers = useRef<Array<ReactPlayer | null>>([]);
  const playersContainersRef = useRef<Array<React.RefObject<HTMLDivElement>>>(
    [],
  );
  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(min-width: 0px) and (max-width: 767px)').matches,
  );

  useEffect(() => {
    videoPlayers.current = Array.from({ length: 1 }, () => null);
    playersContainersRef.current = Array.from({ length: 1 }, () =>
      React.createRef<HTMLDivElement>(),
    );
    const newObservers = playersContainersRef.current.map(
      (_, index) =>
        new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.seekTo(0);
                  videoPlayers.current[index]?.getInternalPlayer().play();
                }
              } else {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.getInternalPlayer().pause();
                  videoPlayers.current[index]?.seekTo(0);
                }
              }
            });
          },
          { threshold: 0.2 },
        ),
    );

    // Set the state with the initialized arrays
    setObservers(newObservers);

    return () => {
      // Clean up observers when component unmounts
      newObservers.forEach(observer => observer.disconnect());
    };
  }, []);

  useEffect(() => {
    // Observe player containers when isLoading changes
    playersContainersRef.current.forEach((ref, index) => {
      if (ref.current) {
        observers[index].observe(ref.current);
      }
    });
  }, [isLoading]);

  React.useEffect(() => {
    setIsMobile(
      window.matchMedia('(min-width: 0px) and (max-width: 767px)').matches,
    );
    window.addEventListener('resize', () => {
      setIsMobile(
        window.matchMedia('(min-width: 0px) and (max-width: 767px)').matches,
      );
    });
  }, []);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] font-sharonDisplay-font sm:w-full sm:tracking-wide text-home_page text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] font-bold"
      >
        {brandbook?.attributes?.Inicio?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-6 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mt-4 md:mb-0 text-home_page w-full mt-16 mb-14 sm:mb-0 font-inter leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.56vw,30px)] whitespace-pre-wrap">
        {brandbook?.attributes?.Inicio?.Descricao}
      </p>
      <section
        id={brandbook?.attributes?.Overview?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(40px,8.87vw,74px)] font-sharonDisplay-font sm:mt-1 sm:mb-0 md:mt-1 sm:w-full sm:tracking-wide text-home_page text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light">
          {brandbook?.attributes?.Overview?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:my-0 text-home_page w-full my-0 sm:mb-0 font-inter whitespace-pre-wrap">
          {brandbook?.attributes?.Overview?.Descricao}
        </p>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[0]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[0] = ref)}
              muted
              className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
              url={`${brandbook?.attributes?.Overview?.Video?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
        <div
          className={`sm:mt-4 md:grid-cols-1 sm:gap-10 sm:grid-cols-1 grid grid-cols-2 md:gap-8 gap-4 md:mb-4 gap-y-12`}
        >
          {brandbook?.attributes?.Overview?.TituloImagem?.map(
            (uses: any, index: number) => (
              <div key={index} className={`flex flex-col gap-9 md:gap-5`}>
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.11vw,22px)] sm:leading-[clamp(12px,5.11vw,22px)] md:leading-[clamp(22px,6vw,50px)] md:text-[clamp(14px,2.88vw,24px)] font-inter text-home_page font-bold">
                  {uses?.Titulo}
                </h6>
                <img
                  src={`${uses?.Imagem?.data?.attributes?.url}`}
                  alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  className="w-fit"
                />
              </div>
            ),
          )}
        </div>
      </section>
      {brandbook?.attributes?.grafismo_secaos?.data.map(
        (grafismo: any, index: number) => (
          <section
            key={index}
            id={grafismo?.attributes?.Titulo}
            className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
          >
            <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(40px,8.87vw,74px)] font-sharonDisplay-font sm:mt-1 sm:mb-0 md:mt-1 sm:w-full sm:tracking-wide text-home_page text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light">
              {grafismo?.attributes?.Titulo}
            </h4>
            <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:my-0 sm:mb-6 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
              {grafismo?.attributes?.Descricao}
            </p>
            <div className="md:w-full sm:w-full sm:gap-2 flex flex-col gap-6 w-full">
              {grafismo?.attributes?.Midias?.data?.map(
                (midia: any, index: number) => (
                  <img
                    key={index}
                    src={`${midia?.attributes?.url}`}
                    alt={`${midia?.attributes?.name}`}
                  />
                ),
              )}
            </div>
            {grafismo?.attributes?.FormasDeUsoTitulo && (
              <div className="mt-8 md:mb-6 sm:my-12 sm:gap-4 flex flex-row items-center gap-4 mb-2">
                <TfiArrowTopRight className="md:mt-[0.5rem]" color="#00AF55" size={!isMobile ? 30 : 20} />
                <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_stone font-medium font-inter">
                  {grafismo?.attributes?.FormasDeUsoTitulo}
                </span>
              </div>
            )}
            <div
              className={`md:grid-cols-1 sm:grid-cols-1 ${grafismo?.attributes?.FormasDeUsoImagensVerticalGrande
                  .length === 2 && 'grid-cols-2'
                } ${grafismo?.attributes?.FormasDeUsoImagensVerticalGrande.length >=
                3 && 'grid-cols-3'
                }
               ${grafismo?.attributes?.FormasDeUsoImagensVerticalGrande
                  .length === 1 && 'grid-cols-1'
                } grid gap-9 md:gap-16 sm:gap-9`}
            >
              {grafismo?.attributes?.FormasDeUsoImagensVerticalGrande.map(
                (uses: any, index: number) => (
                  <div key={index} className={`flex flex-col gap-9`}>
                    <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.11vw,22px)] sm:leading-[clamp(12px,5.11vw,22px)] md:leading-[clamp(22px,6vw,50px)] md:text-[clamp(14px,2.88vw,24px)] font-inter text-home_page font-bold">
                      {uses?.Titulo}
                    </h6>
                    <div
                      className={`${grafismo?.attributes?.FormasDeUsoImagensVerticalGrande
                          .length === 2 &&
                        'md:flex-col sm:flex-col flex-row flex-wrap'
                        } 
                  ${grafismo?.attributes?.FormasDeUsoImagensVerticalGrande
                          .length !== 2 && 'flex-col'
                        } flex  gap-9`}
                    >
                      <img
                        src={`${uses?.Imagem?.data?.attributes?.url}`}
                        alt={`${uses?.Imagem?.data?.attributes?.name}`}
                      />
                      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:mb-4 md:mt-0 sm:mb-6 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
                        {uses?.Descricao}
                      </p>
                    </div>
                  </div>
                ),
              )}
            </div>
            <div
              className={`md:grid-cols-1 sm:grid-cols-1 grid grid-cols-2 gap-9`}
            >
              {grafismo?.attributes?.FormasDeUsoImagensHorizontal.map(
                (uses: any, index: number) => (
                  <div key={index} className={`flex flex-col gap-9`}>
                    <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.11vw,22px)] sm:leading-[clamp(12px,5.11vw,22px)] md:leading-[clamp(22px,6vw,50px)] md:text-[clamp(14px,2.88vw,24px)] font-inter text-home_page font-bold">
                      {uses?.Titulo}
                    </h6>
                    <img
                      src={`${uses?.Imagem?.data?.attributes?.url}`}
                      alt={`${uses?.Imagem?.data?.attributes?.name}`}
                      className="w-fit"
                    />
                    <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:mb-4 md:mt-0 sm:mb-6 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
                      {uses?.Descricao}
                    </p>
                  </div>
                ),
              )}
            </div>
            <div
              className={`md:grid-cols-1 sm:grid-cols-1 ${grafismo?.attributes?.FormasDeUsoImagensHorizontalPequena
                  .length === 2 && 'grid-cols-2'
                } ${grafismo?.attributes?.FormasDeUsoImagensHorizontalPequena
                  .length >= 3 && 'grid-cols-3'
                }
               ${grafismo?.attributes?.FormasDeUsoImagensHorizontalPequena
                  .length === 1 && 'grid-cols-1'
                } grid gap-9 md:gap-16 sm:gap-9`}
            >
              {grafismo?.attributes?.FormasDeUsoImagensHorizontalPequena.map(
                (uses: any, index: number) => (
                  <div key={index} className={`flex flex-col gap-9`}>
                    <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.11vw,22px)] sm:leading-[clamp(12px,5.11vw,22px)] md:leading-[clamp(22px,6vw,50px)] md:text-[clamp(14px,2.88vw,24px)] font-inter text-home_page font-bold">
                      {uses?.Titulo}
                    </h6>
                    <div
                      className={`${grafismo?.attributes
                          ?.FormasDeUsoImagensHorizontalPequena.length === 2 &&
                        'flex-row flex-wrap'
                        } 
                  ${grafismo?.attributes?.FormasDeUsoImagensHorizontalPequena
                          .length !== 2 && 'flex-col'
                        } flex  gap-9`}
                    >
                      <img
                        src={`${uses?.Imagem?.data?.attributes?.url}`}
                        alt={`${uses?.Imagem?.data?.attributes?.name}`}
                      />
                      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:mb-4 md:mt-0 sm:mb-6 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
                        {uses?.Descricao}
                      </p>
                    </div>
                  </div>
                ),
              )}
            </div>
            {grafismo?.attributes?.PossibilidadeDeAplicacaoTitulo && (
              <div className="flex flex-col">
                <div className="md:mt-8 md:mb-6 sm:mt-8 sm:mb-12 sm:gap-4 flex flex-row items-center gap-4 my-12">
                  <TfiArrowTopRight className="md:mt-[0.5rem]" color="#00AF55" size={!isMobile ? 30 : 20} />
                  <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_stone font-medium font-inter">
                    {grafismo?.attributes?.PossibilidadeDeAplicacaoTitulo}
                  </span>
                </div>
                {grafismo?.attributes?.PossibilidadesDeAplicacaoDescricao && (
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] mt-4 md:mb-12 md:mt-6 sm:mb-6 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
                    {grafismo?.attributes?.PossibilidadesDeAplicacaoDescricao}
                  </p>
                )}
              </div>
            )}
            <div className="md:grid-cols-1 sm:grid-cols-1 sm:gap-9 grid grid-cols-3 gap-4">
              {grafismo?.attributes?.PossibilidadesDeAplicacaoImagensVertical.map(
                (aplicacao: any, index: number) => (
                  <div key={index} className={`flex flex-col`}>
                    <img
                      src={`${aplicacao?.Imagem?.data?.attributes?.url}`}
                      alt={`${aplicacao?.Imagem?.data?.attributes?.name}`}
                    />
                    <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] mt-4 md:mb-12 md:mt-6 sm:mb-6 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
                      {aplicacao?.DescricaoImagem}
                    </p>
                  </div>
                ),
              )}
            </div>
            {grafismo?.attributes?.PossibilidadesDeAplicacaoImagemGrande && (
              <div className={`sm:mb-14 flex flex-col gap-11 md:gap-4`}>
                <img
                  src={`${grafismo?.attributes?.PossibilidadesDeAplicacaoImagemGrande?.Imagem?.data?.attributes?.url}`}
                  alt={`${grafismo?.attributes?.PossibilidadesDeAplicacaoImagemGrande?.Imagem?.data?.attributes?.name}`}
                  className="w-fit"
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:mb-12 md:mt-6 sm:mb-6 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
                  {
                    grafismo?.attributes?.PossibilidadesDeAplicacaoImagemGrande
                      ?.DescricaoImagem
                  }
                </p>
              </div>
            )}
            <div className="md:grid-cols-1 sm:grid-cols-1 grid grid-cols-2 gap-4">
              {grafismo?.attributes
                ?.PossibilidadesDeAplicacaoImagensHorizontal &&
                grafismo?.attributes?.PossibilidadesDeAplicacaoImagensHorizontal.map(
                  (aplicacao: any, index: number) => (
                    <div key={index} className={`md:gap-4 flex flex-col gap-11`}>
                      <img
                        src={`${aplicacao?.Imagem?.data?.attributes?.url}`}
                        alt={`${aplicacao?.Imagem?.data?.attributes?.name}`}
                        className="w-fit"
                      />
                      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:mb-12 md:mt-6 sm:mb-6 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
                        {aplicacao?.DescricaoImagem}
                      </p>
                    </div>
                  ),
                )}
            </div>
          </section>
        ),
      )}
    </>
  );
};

export default Stone;
