import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFooter } from '../../hooks/useFooter';
import Layout from '../../components/Layout';
import ScrollToTop from '../../components/ScrollToTop';
import { useBrandBooks } from '../../hooks/useBrandBooks';
import SkeletonBranded from '../../components/Skeleton/Branded';

const RendaExtra: React.FC = () => {
  const { brand } = useParams();
  const { footer } = useFooter();
  const { brandbook, isLoading } = useBrandBooks();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  if (isLoading)
    return (
      <Layout brand={brand} footer={footer} isMobile={isMobile}>
        <SkeletonBranded />
      </Layout>
    );

  return (
    <Layout brand={brand} footer={footer} isMobile={isMobile}>
      <h3
        id="Inicio"
        className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:w-full sm:tracking-wide text-hover_ton font-bold"
      >
        {brandbook?.attributes?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.56vw,30px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.65vw,20px)] md:w-full md:mt-4 text-hover_ton w-full mt-14 mb-16 font-inter whitespace-pre-wrap font-medium">
        {brandbook?.attributes?.Descricao}
      </p>
      <section
        id={brandbook?.attributes?.Assinaturas?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.Assinaturas?.Titulo}
        </h4>
        <div className="md:gap-2 sm:gap-1 flex flex-col gap-3">
          <img
            className="w-full"
            src={`${brandbook?.attributes?.Assinaturas?.PrimeiraImagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Assinaturas?.PrimeiraImagem?.data?.attributes?.name}`}
          />
          <div className="md:grid-cols-1 sm:grid-cols-1 sm:gap-2 grid grid-cols-2 gap-4">
            {brandbook?.attributes?.Assinaturas?.BlocoDeImagens?.data.map(
              (image: any, index: number) => (
                <img
                  key={index}
                  src={`${image?.attributes?.url}`}
                  alt={`${image?.attributes?.name}`}
                  className="w-full h-auto"
                />
              ),
            )}
          </div>
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Cores?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.Cores?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-8 md:mt-4 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap mb-4">
          {brandbook?.attributes?.Cores?.Descricao}
        </p>
        <img
          src={`${brandbook?.attributes?.Cores?.Midia?.data?.attributes?.url}`}
          alt={`${brandbook?.attributes?.Cores?.Midia?.data?.attributes?.url}`}
        />
      </section>
      <section
        id={brandbook?.attributes?.Grafismos?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.Grafismos?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-8 md:mt-4 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap mb-4">
          {brandbook?.attributes?.Grafismos?.Descricao}
        </p>
        <div className="md:gap-2 sm:gap-2 flex flex-col gap-3">
          <img
            className="w-full"
            src={`${brandbook?.attributes?.Grafismos?.PrimeiraImagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Grafismos?.PrimeiraImagem?.data?.attributes?.name}`}
          />
          <div className="md:grid-cols-1 sm:grid-cols-1 sm:gap-2 grid grid-cols-2 gap-4">
            {brandbook?.attributes?.Grafismos?.BlocoDeImagens?.data.map(
              (image: any, index: number) => (
                <img
                  key={index}
                  src={`${image?.attributes?.url}`}
                  alt={`${image?.attributes?.name}`}
                  className="w-full h-auto"
                />
              ),
            )}
          </div>
          <img
            className="w-full"
            src={`${brandbook?.attributes?.Grafismos?.UltimaImagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Grafismos?.UltimaImagem?.data?.attributes?.name}`}
          />
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Aplicacoes?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.Aplicacoes?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-8 md:mt-4 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap mb-4">
          {brandbook?.attributes?.Aplicacoes?.Descricao}
        </p>
        <div className="md:gap-2 sm:gap-2 flex flex-col gap-3">
          {brandbook?.attributes?.Aplicacoes?.Midias?.data.map(
            (image: any, index: number) => (
              <img
                key={index}
                src={`${image?.attributes?.url}`}
                alt={`${image?.attributes?.name}`}
                className="w-full h-auto"
              />
            ),
          )}
        </div>
      </section>
      <ScrollToTop />
    </Layout>
  );
};

export default RendaExtra;
