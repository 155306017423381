import React from 'react';
import { useEffect, useState } from 'react';
import { useFooter } from '../../hooks/useFooter';
import SkeletonFooter from '../Skeleton/Footer';

interface Footer {
  open?: boolean;
  diffWidth?: boolean;
  isMobile: boolean;
  brand?: string;
  footer?: {
    attributes: {
      CompanyName: string;
      CNPJ: string;
      Description: string;
      Logo: {
        data: any;
      };
    };
  };
}
const Footer = (props: Footer) => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );
  const { isLoading } = useFooter();

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);
  if (props.open) return <></>;

  if (isLoading) return <SkeletonFooter />;

  return (
    <footer
      className={`md:w-full md:mt-4 sm:flex-col sm:w-full sm:items-end sm:mt-11 sm:mb-12 sm:mt-9 flex mt-36 mb-9 relative items-end w-full`}
    >
      <div className="sm:w-full">
        <p
          className={`sm:mb-3 sm:text-sm font-inter ${
            props.brand === 'ton' ? 'text-hover_ton' : 'text-home_page'
          } sm:break-words text-sm`}
        >
          <b>{props?.footer?.attributes?.CompanyName}</b>
          {isMobile ? (
            <>
              <br />
              <span
                className={`${
                  props.brand === 'ton' ? 'opacity-100' : 'opacity-50'
                } mb-3`}
              >
                {props?.footer?.attributes?.CNPJ}
              </span>
            </>
          ) : (
            <span
              className={`${
                props.brand === 'ton' ? 'opacity-100' : 'opacity-50'
              }`}
            >
              &nbsp;{props?.footer?.attributes?.CNPJ}
            </span>
          )}
        </p>
        <p
          className={`md:w-2/2.5 sm:w-full sm:text-sm font-inter ${
            props.brand === 'ton'
              ? 'text-hover_ton opacity-100'
              : 'text-home_page opacity-50'
          }  text-sm`}
        >
          {props?.footer?.attributes?.Description}
        </p>
      </div>
      <div className="md:top-16 md:mb-8 sm:mt-4 sm:relative sm:top-20 absolute right-0">
        {props?.footer?.attributes?.Logo?.data && (
          <img
            src={
              props.brand === 'ton'
                ? props?.footer?.attributes?.Logo?.data[1]?.attributes?.url
                : props?.footer?.attributes?.Logo?.data[0]?.attributes?.url
            }
            className="w-20 h-5"
            alt="logo"
          />
        )}
      </div>
    </footer>
  );
};

export default Footer;
