import React from 'react';
import { Skeleton as SkeletonBase } from 'antd';
import { useParams } from 'react-router-dom';

const SkeletonBranded: React.FC = () => {
  const { brand } = useParams();

  return (
    <div className={`w-full h-full flex flex-col items-center justify-center ${brand === "stone" ? "bg-stone" : "bg-ton"}`}>
      <SkeletonBase active style={{ marginBottom: "10%" }} />
      <SkeletonBase active title={false} style={{ width: "65%", marginRight: "10%", alignSelf: "flex-end" }} />
      <div className="mt-36 flex gap-24 w-full justify-center flex-row sm:flex-col items-center">
        <SkeletonBase.Image active style={{ width: "250px", height: "250px", filter: "invert(1)" }} />
        <SkeletonBase.Image active style={{ width: "250px", height: "250px", filter: "invert(1)" }} />
      </div>
    </div>
  )
};

export default SkeletonBranded;
