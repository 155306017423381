import React, { useEffect, useState } from 'react';
import { useBrandBooks } from '../../../hooks/useBrandBooks';
import SkeletonBranded from '../../../components/Skeleton/Branded';
import { TfiArrowTopRight } from 'react-icons/tfi';

const Ton: React.FC = () => {
  const { brandbook, isLoading } = useBrandBooks();
  const [IS_CELLPHONE, setIsCellphone] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );
  const [IS_TABLET_PORT, setIsTabletPort] = useState(
    window.matchMedia('(min-width: 768px) and (max-width: 1279px)').matches,
  );
  const [IS_DESKTOP, setIsDesktop] = useState(
    window.matchMedia('(min-width: 1280px)').matches,
  );

  useEffect(() => {
    setIsCellphone(window.matchMedia('(max-width: 767px)').matches);
    setIsTabletPort(
      window.matchMedia('(min-width: 768px) and (max-width: 1279px)').matches,
    );
    setIsDesktop(window.matchMedia('(min-width: 1280px)').matches);
    window.addEventListener('resize', () => {
      setIsCellphone(window.matchMedia('(max-width: 767px)').matches);
      setIsTabletPort(
        window.matchMedia('(min-width: 768px) and (max-width: 1279px)').matches,
      );
      setIsDesktop(window.matchMedia('(min-width: 1280px)').matches);
    });
  }, []);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] sm:w-full sm:tracking-wide text-hover_ton text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] font-bold"
      >
        {brandbook?.attributes?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full md:mb-0 sm:w-full sm:mt-5 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-0 text-hover_ton leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.565vw,30px)] w-full mt-14 mb-14 font-inter font-medium whitespace-pre-wrap">
        {brandbook?.attributes?.Descricao}
      </p>
      <section
        id={brandbook?.attributes?.Logotipo?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="md:h-16 md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] sm:h-[4.5rem] sm:-mt-2 font-tonCondensedVF-font sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] h-24 font-light">
          {brandbook?.attributes?.Logotipo?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:font-medium font-inter text-hover_ton text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Logotipo?.Descricao}
        </p>
        <div className="md:gap-3 sm:gap-2 gap-6 flex flex-col">
          <img
            src={`${brandbook?.attributes?.Logotipo?.Imagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Logotipo?.Imagem?.data?.attributes?.name}`}
          />
          {IS_CELLPHONE && (
            <img
              src={`${brandbook?.attributes?.Logotipo?.MidiaMobile?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Logotipo?.MidiaMobile?.data?.attributes?.name}`}
            />
          )}
          {IS_TABLET_PORT && (
            <img
              src={`${brandbook?.attributes?.Logotipo?.MidiaTablet?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Logotipo?.MidiaTablet?.data?.attributes?.name}`}
            />
          )}
          {IS_DESKTOP && (
            <img
              src={`${brandbook?.attributes?.Logotipo?.MidiaDesktop?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Logotipo?.MidiaDesktop?.data?.attributes?.name}`}
            />
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Potencia?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="md:h-14 md:mt-5 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:mb-0 sm:h-[4.5rem] sm:-mt-2 font-tonCondensedVF-font sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] h-24 font-light">
          {brandbook?.attributes?.Potencia?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:font-medium sm:mb-6 font-inter text-hover_ton font-medium  whitespace-pre-wrap">
          {brandbook?.attributes?.Potencia?.Descricao}
        </p>
        <div className="md:gap-3 sm:gap-2 gap-6 flex flex-col">
          <img
            src={`${brandbook?.attributes?.Potencia?.Imagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Potencia?.Imagem?.data?.attributes?.name}`}
          />
          {IS_CELLPHONE && (
            <img
              src={`${brandbook?.attributes?.Potencia?.MidiaMobile?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Potencia?.MidiaMobile?.data?.attributes?.name}`}
            />
          )}
          {IS_TABLET_PORT && (
            <img
              src={`${brandbook?.attributes?.Potencia?.MidiaTablet?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Potencia?.MidiaTablet?.data?.attributes?.name}`}
            />
          )}
          {IS_DESKTOP && (
            <img
              src={`${brandbook?.attributes?.Potencia?.MidiaDesktop?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Potencia?.MidiaDesktop?.data?.attributes?.name}`}
            />
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Arejamento?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-14 md:mt-5 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] font-tonCondensedVF-font sm:h-[4.5rem] sm:-mt-2 sm:w-full sm:tracking-wide text-hover_ton h-24 font-light">
          {brandbook?.attributes?.Arejamento?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-5 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Arejamento?.Descricao}
        </p>
        <div className="md:gap-3 sm:gap-3 gap-6 flex flex-col">
          {brandbook?.attributes?.Arejamento?.Imagens?.data.map(
            (imagem: any, index: number) => (
              <img
                key={index}
                className="w-full h-full"
                src={`${imagem?.attributes?.url}`}
                alt={`${imagem?.attributes?.name}`}
              />
            ),
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Reducoes?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-16 md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:h-[4.5rem] sm:-mt-2 sm:w-full sm:tracking-wide text-hover_ton h-24 font-light">
          {brandbook?.attributes?.Reducoes?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Reducoes?.Descricao}
        </p>
        <div className="md:flex-col md:gap-16 md:mb-0 sm:mb-0 sm:flex-col sm:gap-2 flex flex-row gap-4">
          {brandbook?.attributes?.Reducoes?.reducaos?.data[0]?.attributes?.Exemplos?.map(
            (exemplos: any, index: number) => (
              <div key={index} className="sm:mb-8 flex flex-col gap-6">
                <span className="text-[clamp(20px,1.57vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(20px,5.55vw,22px)] sm:leading-[clamp(30px,11.7vw,50px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {exemplos?.Titulo}
                </span>
                <img
                  key={index}
                  src={`${exemplos?.Imagem?.data?.attributes?.url}`}
                  alt={`${exemplos?.Imagem?.data?.attributes?.name}`}
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] font-inter font-medium text-hover_ton">
                  {exemplos?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="sm:gap-11 flex flex-col gap-14">
          <div className="md:mb-4 md:mt-4 sm:mb-0 flex flex-row items-center gap-2">
            <TfiArrowTopRight color="#003C00" size={!IS_CELLPHONE ? 30 : 20} />
            <span className="text-[clamp(30px,1.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
              {
                brandbook?.attributes?.Reducoes?.reducaos?.data[0]?.attributes
                  ?.ImpressoDigital[0]?.Titulo
              }
            </span>
          </div>
          <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] font-inter font-medium text-hover_ton">
            {
              brandbook?.attributes?.Reducoes?.reducaos?.data[0]?.attributes
                ?.ImpressoDigital[0]?.Descricao
            }
          </p>
          {brandbook?.attributes?.Reducoes?.reducaos?.data[0]?.attributes?.ImpressoDigital[0]?.TituloImagens?.map(
            (tituloImagem: any, index: number) => (
              <div
                key={index}
                className="md:items-center md:gap-22 sm:gap-22 sm:items-center flex flex-col gap-14"
              >
                <span className="md:text-[clamp(14px,4.19vw,26px)] text-[clamp(30px,1.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:my-5 sm:my-5 text-hover_ton font-inter font-medium">
                  {tituloImagem?.Titulo}
                </span>
                <div className="flex md:w-fit md:flex-col md:gap-17 sm:gap-17 sm:w-fit sm:flex-col flex-row w-full justify-between items-start">
                  {tituloImagem?.Imagens?.data?.map(
                    (imagens: any, indexes: number) => (
                      <>
                        <img
                          className="md:w-full sm:w-full"
                          src={`${imagens?.attributes?.url}`}
                          alt={`${imagens?.attributes?.name}`}
                        />
                        {indexes < 2 && (
                          <div
                            id="separator"
                            className="md:h-[2px] sm:h-[2px] md:w-[192px] sm:w-[192px] border-0 h-48 w-[0.1rem] bg-stone self-end"
                          />
                        )}
                      </>
                    ),
                  )}
                </div>
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Quandousar?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] md:h-14 md:mt-5 md:mb-0 sm:h-[4.5rem] sm:-mt-2 font-tonCondensedVF-font sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton h-24 font-light">
          {brandbook?.attributes?.Quandousar?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Quandousar?.Descricao}
        </p>
        <div className="md:mt-4 md:flex-col md:gap-10 sm:mt-4 sm:gap-2 sm:flex-col flex flex-row gap-4">
          {brandbook?.attributes?.Quandousar?.quando_usars?.data[0]?.attributes?.QuandoUsar?.map(
            (item: any, index: number) => (
              <div key={index} className="sm:mb-8 flex flex-col gap-6">
                <span className="text-[clamp(20px,1.57vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(20px,5.55vw,22px)] sm:leading-[clamp(30px,11.7vw,50px)] md:text-[clamp(18px,5.55vw,24px)] md::leading-[clamp(30px,11.7vw,50px)] md:font-bold sm:font-bold font-inter font-medium text-hover_ton">
                  {item?.Titulo}
                </span>
                <img
                  key={index}
                  src={`${item?.Imagem?.data?.attributes?.url}`}
                  alt={`${item?.Imagem?.data?.attributes?.name}`}
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] font-inter font-medium text-hover_ton">
                  {item?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="sm:gap-2 flex flex-col gap-4">
          {brandbook?.attributes?.Quandousar?.Imagens?.data.map(
            (img: any, index: number) => (
              <img
                key={index}
                src={`${img?.attributes?.url}`}
                alt={`${img?.attributes?.name}`}
              />
            ),
          )}
        </div>
      </section>
    </>
  );
};

export default Ton;
