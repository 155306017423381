import React, { useEffect, useState } from 'react';
import Hamburger from '../Hamburger';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { CiSearch } from 'react-icons/ci';
import Cookies from 'js-cookie';
import { unsetToken } from '../../lib/auth';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-hooks-web';
import { useHeader } from '../../hooks/useHeader';
import SkeletonHeader from '../Skeleton/Header';
import './styles.css';

const searchClient = algoliasearch(
  `${process.env.REACT_APP_ALGOLIA_APP_ID}`,
  `${process.env.REACT_APP_ALGOLIA_APP_KEY}`,
);

const Header = (props: any) => {
  const jwt = Cookies.get('jwt');
  const { brand } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const { header, isLoading } = useHeader();
  const [isTablet, setIsTablet] = useState(
    window.matchMedia('(min-width: 768px)').matches,
  );

  useEffect(() => {
    setIsTablet(window.matchMedia('(min-width: 768px)').matches);
    window.addEventListener('resize', () => {
      setIsTablet(window.matchMedia('(min-width: 768px)').matches);
    });
    window.document.documentElement.scrollTo({ top: 0 });
  }, []);

  function Hit({ hit }: any) {
    return (
      <div className={`flex flex-col relative z-[49] outline-none`}>
        {hit?._highlightResult?.Titulo?.matchLevel === 'full' && (
          <div
            className={`border-b-0 cursor-pointer ${
              brand === 'stone' ? 'border-hover_stone' : 'border-hover_ton'
            }  w-64 px-7 pb-4 outline-none`}
          >
            <a href={hit?.Url}>
              <div className="border-b-stone border-b-1 flex flex-col gap-2">
                <div className="flex flex-row w-full gap-8">
                  <span className="text-small text-stone">{hit?.Seção}</span>
                  <span className="text-small text-stone_secondary">
                    {hit?.Subseção}
                  </span>
                </div>
                {brand === 'stone' ? (
                  <p className={`truncate mb-1 font-medium text-stone`}>
                    {hit?.Titulo}
                  </p>
                ) : (
                  <p className={`truncate mb-1 font-medium text-hover_ton`}>
                    {hit?.Titulo}
                  </p>
                )}
              </div>
            </a>
          </div>
        )}
        {hit?._highlightResult?.Descricao?.matchLevel === 'full' && (
          <div
            className={`border-b-0 cursor-pointer${
              brand === 'stone' ? 'border-hover_stone' : 'border-hover_ton'
            }  w-64 px-7 pb-4 outline-none`}
          >
            <a href={hit?.Url}>
              <div className="border-b-stone border-b-1 flex flex-col gap-2">
                <div className="flex flex-row w-full gap-8">
                  <span className="text-small text-stone">{hit?.Seção}</span>
                  <span className="text-small text-stone_secondary">
                    {hit?.Subseção}
                  </span>
                </div>
                {brand === 'stone' ? (
                  <p className={`truncate mb-1 font-medium text-stone`}>
                    {hit?.Descricao}
                  </p>
                ) : (
                  <p className={`truncate mb-1 font-medium text-hover_ton`}>
                    {hit?.Descricao}
                  </p>
                )}
              </div>
            </a>
          </div>
        )}
      </div>
    );
  }

  if (isLoading) return <SkeletonHeader />;

  return (
    <header className="md:w-full h-9 pl-72 md:pr-20 md:pl-20 sm:w-full sm:pr-9 sm:pl-9 pr-36 mt-11 flex flex-row justify-between sm:justify-between sm:h-16 sm:items-center">
      <div className="flex flex-row gap-6 sm:gap-3 sm:w-max md:w-full md:items-center sm:flex-shrink-0">
        <Hamburger open={props.open} setOpen={props.setOpen} />
        {brand === 'stone' ? (
          <img
            src={`${header?.attributes?.Logo?.data[0]?.attributes?.url}`}
            alt={`${header?.attributes?.Logo?.data[0]?.attributes?.name}`}
            className="sm:w-[132px] sm:aspect-[132/36] md:block hidden"
          />
        ) : (
          <img
            src={`${header?.attributes?.Logo?.data[1]?.attributes?.url}`}
            alt={`${header?.attributes?.Logo?.data[1]?.attributes?.name}`}
            className="sm:w-20 sm:aspect-[76/52] md:block hidden"
          />
        )}

        {isTablet && (
          <InstantSearch
            searchClient={searchClient}
            indexName={brand === 'stone' ? 'stone-brandbook' : 'ton-brandbook'}
          >
            <div className="flex flex-row gap-2 items-end w-full justify-start">
              <SearchBox
                spellCheck={false}
                placeholder={`${
                  isTablet ? `${header?.attributes?.TextoDeBusca}` : ''
                }`}
                className={`${
                  brand === 'ton' && 'change-icon-color'
                } relative ml-24 md:ml-0 sm:ml-2 ${isTablet && 'border-b-2'}  ${
                  brand === 'stone' ? 'border-b-home_page' : 'border-b-stone'
                } sm:border-b-transparent w-64 flex flex-row items-end gap-1 outline-none`}
                onChangeCapture={({ target }: any) => setQuery(target.value)}
              />
              {query && <Hits hitComponent={Hit} />}
            </div>
          </InstantSearch>
        )}
      </div>
      <div className="flex flex-row items-center gap-14 sm:gap-2">
        <button
          className={`cursor-pointer flex flex-row ${
            brand === 'stone' ? 'bg-main_color' : 'bg-ton_secondary'
          } h-7 rounded-2xl items-center md:hidden`}
          onClick={() => navigate('/')}
        >
          <div className="flex flex-row items-center gap-1 pl-3 pr-3 md:hidden ">
            <AiOutlineArrowLeft color="#004132" />
            <span className="text-stone font-inter font-medium">
              {header?.attributes?.TextoVoltarPagina}
            </span>
          </div>
        </button>
        {jwt ? (
          <>
            {!isTablet && (
              <div
                className="cursor-pointer"
                onClick={() => props.setIsClicked(!props.isClicked)}
              >
                <CiSearch
                  size={30}
                  color={brand === 'stone' ? '#E1FADC' : '#004132'}
                  style={{ opacity: props.isClicked ? '0.5' : '1' }}
                />
              </div>
            )}

            <div
              className={`cursor-pointer border-b-2 ${
                brand === 'stone' ? 'border-b-home_page' : 'border-b-hover_ton'
              }`}
              onClick={() => unsetToken()}
            >
              <p
                className={`${
                  brand === 'stone' ? 'text-home_page' : 'text-hover_ton'
                } font-inter text-base leading-7`}
              >
                {header?.attributes?.TextoSairLogin}
              </p>
            </div>
          </>
        ) : (
          <>
            {!isTablet && (
              <div
                className="cursor-pointer"
                onClick={() => props.setIsClicked(!props.isClicked)}
              >
                <CiSearch
                  size={30}
                  color={brand === 'stone' ? '#E1FADC' : '#004132'}
                  style={{ opacity: props.isClicked ? '0.5' : '1' }}
                />
              </div>
            )}
            <div
              className={`cursor-pointer border-b-2 ${
                brand === 'stone' ? 'border-b-home_page' : 'border-b-hover_ton'
              }`}
              onClick={() =>
                navigate(`/${brand}/login`, {
                  state: { from: location.pathname, id: location.state?.id },
                })
              }
            >
              <p
                className={`${
                  brand === 'stone' ? 'text-home_page' : 'text-hover_ton'
                } font-inter text-sm leading-7`}
              >
                {header?.attributes?.TextoEntrarLogin}
              </p>
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
