import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useBrandBooks } from '../../../hooks/useBrandBooks';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Stone: React.FC = () => {
  const { brandbook, isLoading } = useBrandBooks();
  const [observers, setObservers] = useState<IntersectionObserver[]>([]);
  const videoPlayers = useRef<Array<ReactPlayer | null>>([]);
  const playersContainersRef = useRef<Array<React.RefObject<HTMLDivElement>>>(
    [],
  );

  useEffect(() => {
    videoPlayers.current = Array.from({ length: 3 }, () => null);
    playersContainersRef.current = Array.from({ length: 3 }, () =>
      React.createRef<HTMLDivElement>(),
    );
    const newObservers = playersContainersRef.current.map(
      (_, index) =>
        new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.seekTo(0);
                  videoPlayers.current[index]?.getInternalPlayer().play();
                }
              } else {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.getInternalPlayer().pause();
                  videoPlayers.current[index]?.seekTo(0);
                }
              }
            });
          },
          { threshold: 0.2 },
        ),
    );

    // Set the state with the initialized arrays
    setObservers(newObservers);

    return () => {
      // Clean up observers when component unmounts
      newObservers.forEach(observer => observer.disconnect());
    };
  }, []);

  useEffect(() => {
    // Observe player containers when isLoading changes
    playersContainersRef.current.forEach((ref, index) => {
      if (ref.current) {
        observers[index].observe(ref.current);
      }
    });
  }, [isLoading]);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="md:text-[clamp(2.25rem,8.2vw,4.25rem)] md:leading-[clamp(2rem,8.2vw,4rem)] font-sharonDisplay-font sm:leading-[clamp(2rem,12.5vw,53px)] sm:text-4.5xl-st sm:tracking-normal sm:w-full  text-home_page font-bold leading-[clamp(100px,7.3vw,130px)] text-[clamp(75px,7.3vw,140px)] tracking-[1.4px] w-[104.33%]"
      >
        {brandbook?.attributes?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(20px,1.565vw,30px)] md:w-full sm:w-full md:mt-8 md:mb-8 text-home_page w-full mt-14 mb-14 font-medium whitespace-pre-wrap font-inter">
        {brandbook?.attributes?.Descricao}
      </p>
      <div className="md:w-full sm:gap-1 md:gap-2 sm:w-full flex flex-col gap-6 w-full">
        <img
          src={`${brandbook?.attributes?.PrimeiraImagem?.Imagem?.data?.attributes?.url}`}
          alt={`${brandbook?.attributes?.PrimeiraImagem?.Imagem?.data?.attributes?.name}`}
        />
        <img
          src={`${brandbook?.attributes?.SegundaImagem?.Imagem?.data?.attributes?.url}`}
          alt={`${brandbook?.attributes?.SegundaImagem?.Imagem?.data?.attributes?.name}`}
        />
      </div>
      <div className="sm:mt-1 flex md:justify-between mt-6 w-full md:flex-col flex-row md:mt-2 sm:gap-1 md:gap-2 sm:w-full gap-[2%]">
        {brandbook?.attributes?.BlocoDeImagens?.BlocoDeImagens?.data?.map(
          (image: any, index: number) => (
            <img
              key={index}
              src={`${image?.attributes?.url}`}
              alt={`${image?.attributes?.name}`}
              className="w-2/5.9 md:w-full"
            />
          ),
        )}
      </div>
      <div className="md:w-full sm:w-full w-full">
        <div ref={playersContainersRef.current[0]}>
          <ReactPlayer
            ref={ref => (videoPlayers.current[0] = ref)}
            muted
            className="md:w-full sm:w-full sm:mt-1 md:mt-2 w-full mt-6 react-player-preview"
            url={`${brandbook?.attributes?.PrimeiroVideo?.Video?.data?.attributes?.url}`}
            controls={false}
            height={'100%'}
            width={'100%'}
          />
        </div>
      </div>
      <div className="flex-row sm:flex md:flex-col md:w-full sm:gap-1 md:gap-2 md:mt-2 sm:mt-1 md:mb-2 sm:mb-1 flex gap-[2%] mb-6 mt-6">
        {brandbook?.attributes?.BlocoDeImagens2?.BlocoDeImagens?.data?.map(
          (image: any, index: number) => (
            <img
              key={index}
              src={`${image?.attributes?.url}`}
              alt={`${image?.attributes?.name}`}
              className={`${
                index === 1
                  ? 'md:w-full w-[38%]'
                  : 'w-[60%] md:w-full'
              }`}
            />
          ),
        )}
      </div>
      <div className="md:w-full sm:w-full sm:gap-1 md:gap-2 flex flex-col gap-6 w-full">
        <img
          src={`${brandbook?.attributes?.TerceiraImagem?.Imagem?.data?.attributes?.url}`}
          alt={`${brandbook?.attributes?.TerceiraImagem?.Imagem?.data?.attributes?.name}`}
        />
        <div ref={playersContainersRef.current[1]}>
          <ReactPlayer
            ref={ref => (videoPlayers.current[1] = ref)}
            muted
            className="md:w-full sm:w-full w-full mt-0 md:mt-0 react-player-preview"
            url={`${brandbook?.attributes?.SegundoVideo?.Video?.data?.attributes?.url}`}
            controls={false}
            height={'100%'}
            width={'100%'}
          />
        </div>
        <img
          src={`${brandbook?.attributes?.QuartaImagem?.Imagem?.data?.attributes?.url}`}
          alt={`${brandbook?.attributes?.QuartaImagem?.Imagem?.data?.attributes?.name}`}
        />
        <div ref={playersContainersRef.current[2]}>
          <ReactPlayer
            ref={ref => (videoPlayers.current[2] = ref)}
            muted
            className="md:w-full sm:w-full w-full md:mt-0 mt-0 react-player-preview"
            url={`${brandbook?.attributes?.TerceiroVideo?.Video?.data?.attributes?.url}`}
            controls={false}
            height={'100%'}
            width={'100%'}
          />
        </div>
      </div>
      <img
        id="large"
        src={`${brandbook?.attributes?.GrandeImagem?.GrandeImagem?.data?.attributes?.url}`}
        alt={`${brandbook?.attributes?.GrandeImagem?.GrandeImagem?.data?.attributes?.name}`}
        className="md:w-full sm:w-full sm:mt-1 md:mt-2 w-full mt-6"
      />
    </>
  );
};

export default Stone;
