import React, { useEffect, useState } from 'react';
import { useAuthBrandBooks } from './useAuthBrandBooks';

export const useAudio = () => {
  const { authBrandBook } = useAuthBrandBooks();
  const [isPlaying, setIsPlaying] = useState<boolean[]>([]);
  const [audioIds, setAudioIds] = useState<number[]>([]);
  const [percentageArray, setPercentageArray] = useState<number[]>([]);
  const audioGuideQuantity =
    authBrandBook?.attributes?.SoundGuideline?.Exemplo.length;
  const audioUXQuantity = authBrandBook?.attributes?.SoundUX?.ExemploUX.length;
  const audioNumbers = audioGuideQuantity + audioUXQuantity;
  const [audioPlayers, setAudioPlayers] = useState<
    Array<React.RefObject<HTMLAudioElement>>
  >([]);
  const [progressBars, setProgressBars] = useState<
    Array<React.RefObject<HTMLInputElement>>
  >([]);

  useEffect(() => {
    setAudioPlayers(
      Array.from({ length: audioNumbers }, () => React.createRef()),
    );
    setProgressBars(
      Array.from({ length: audioNumbers }, () => React.createRef()),
    );
    setPercentageArray(Array.from({ length: audioNumbers }, () => 0));
    setIsPlaying(Array.from({ length: audioNumbers }, () => false));
  }, [audioNumbers]);

  const changeRange = (e: any, index: number) => {
    const audio = audioPlayers[index];
    const progress = progressBars[index];
    if (audio.current && progress.current) {
      const newTime = (audio.current.duration * e.target.value) / 100;
      audio.current.currentTime = newTime;
      setPercentageArray(() => {
        const newArray = [] as any;
        newArray[index] = e.target.value;
        return newArray;
      });
    }
  };

  useEffect(() => {
    const whilePlaying = () => {
      audioPlayers.forEach((audio, index) => {
        const progress = progressBars[index];
        if (audio.current && progress.current) {
          const newPercentage =
            (audio.current.currentTime / audio.current.duration) * 100;
          setPercentageArray(prevValue => {
            prevValue[index] = newPercentage;
            return [...prevValue];
          });
        }
        if (
          audio.current &&
          audio.current.currentTime === audio?.current?.duration
        ) {
          audio.current.currentTime = 0;
          setIsPlaying(prevValue => {
            prevValue[index] = false;
            return [...prevValue];
          });
          setPercentageArray(prevValue => {
            prevValue[index] = 0;
            return [...prevValue];
          });
          setAudioIds(prevAudioIds =>
            prevAudioIds.filter(audioId => audioId !== index),
          );
        }
      });
    };
    if (isPlaying.includes(true)) {
      setTimeout(() => {
        whilePlaying();
      }, 100);
    }
  }, [percentageArray, isPlaying]);

  const togglePlayPause = (idx: number) => {
    audioPlayers.forEach((player, index) => {
      if (index === idx) {
        if (isPlaying[idx] && audioIds.includes(idx)) {
          setAudioIds(prevAudioIds =>
            prevAudioIds.filter(audioId => audioId !== idx),
          );
          setIsPlaying(prevValue => {
            prevValue[index] = false;
            return [...prevValue];
          });
          player?.current?.pause();
        } else {
          setAudioIds(prevAudioIds =>
            prevAudioIds.filter(audioId => audioId !== idx),
          );
          setAudioIds(prevAudioIds => [...prevAudioIds, idx]);
          setIsPlaying(prevValue => {
            prevValue[index] = true;
            return [...prevValue];
          });
          player?.current?.play();
        }
      } else {
        setAudioIds(prevAudioIds =>
          prevAudioIds.filter(audioId => audioId === idx),
        );
        setIsPlaying(prevValue => {
          prevValue[index] = false;
          return [...prevValue];
        });
        player?.current?.pause();
      }
    });
  };

  return {
    changeRange,
    togglePlayPause,
    audioPlayers,
    audioIds,
    progressBars,
    percentageArray,
    audioGuideQuantity,
  };
};
