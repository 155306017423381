import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFooter } from '../../hooks/useFooter';
import Layout from '../../components/Layout';
import ScrollToTop from '../../components/ScrollToTop';
import { useBrandBooks } from '../../hooks/useBrandBooks';
import { TfiArrowTopRight } from 'react-icons/tfi';
import SkeletonBranded from '../../components/Skeleton/Branded';

const Personagens: React.FC = () => {
  const { brand } = useParams();
  const { footer } = useFooter();
  const { brandbook, isLoading } = useBrandBooks();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  if (isLoading)
    return (
      <Layout brand={brand} footer={footer} isMobile={isMobile}>
        <SkeletonBranded />
      </Layout>
    );

  return (
    <Layout brand={brand} footer={footer} isMobile={isMobile}>
      <h3
        id="Inicio"
        className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] font-tonCondensedVF-font sm:tracking-wide text-hover_ton font-bold"
      >
        {brandbook?.attributes?.Inicio?.Titulo}
      </h3>
      <p className="text-[clamp(18px,2.25vw,30px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:my-6 md:w-full sm:w-full sm:mt-5 sm:mb-12 sm:text-xl text-hover_ton w-full mt-14 mb-14 font-inter font-medium whitespace-pre-wrap">
        {brandbook?.attributes?.Inicio?.Descricao}
      </p>
      <img
        className="w-full"
        src={`${brandbook?.attributes?.Inicio?.Imagem?.data?.attributes?.url}`}
        alt={`${brandbook?.attributes?.Inicio?.Imagem?.data?.attributes?.name}`}
      />
      <div className="md:mt-8 sm:mt-8 sm:gap-6 flex flex-col gap-5 mt-16">
        <div className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,20px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(14px,2.565vw,33px)] md:w-5/6 md:h-16 md:px-10 sm:px-11 sm:h-36 sm:items-start sm:w-full w-[63%] h-16 flex flex-col items-center justify-center text-hover_ton font-medium font-inter whitespace-pre-wrap bg-ton_secondary rounded-bl-none rounded-2xl">
          {brandbook?.attributes?.Inicio?.AvisoTitulo}
        </div>
        <div className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:py-6 md:w-11/12 md:whitespace-normal sm:whitespace-normal sm:text-xl sm:py-8 sm:px-11 sm:w-full px-10 py-8 flex flex-col items-center justify-center w-[73%] h-fit text-hover_ton font-medium font-inter bg-ton_secondary rounded-tl-none rounded-4xl whitespace-pre-wrap">
          {brandbook?.attributes?.Inicio?.AvisoConteudo}
        </div>
      </div>
      <section
        id="Quando Usar"
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(65px,8vw,130px)] md:h-32 sm:h-20 md:mt-5 sm:mt-4 font-tonCondensedVF-font sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton h-24 font-light">
          {brandbook?.attributes?.QuandoUsar?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full md:mb-0 sm:mt-10 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.QuandoUsar?.Descricao}
        </p>
        <div className="md:gap-16 md:mt-4 md:flex-col sm:mt-4 sm:flex-col flex flex-row gap-4">
          {brandbook?.attributes?.QuandoUsar?.Exemplos?.map(
            (item: any, index: number) => (
              <div key={index} className="sm:mb-8 flex flex-col gap-6">
                <span className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,3.4vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,20px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(20px,1.565vw,30px)] md:font-bold sm:font-bold font-inter font-medium text-hover_ton">
                  {item?.Titulo}
                </span>
                <img
                  key={index}
                  src={`${item?.Imagem?.data?.attributes?.url}`}
                  alt={`${item?.Imagem?.data?.attributes?.name}`}
                />
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] font-inter font-medium text-hover_ton">
                  {item?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Personagens?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-20 md:-mt-3 sm:h-9 sm:mt-4 font-tonCondensedVF-font sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton h-24 font-light">
          {brandbook?.attributes?.Personagens?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full md:mb-0 sm:mt-8 sm:w-full sm:mb-0 sm:text-xl text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Personagens?.Descricao}
        </p>
        <div className="md:mt-8 sm:mt-8 sm:grid-cols-1 sm:gap-12 grid grid-cols-4 gap-20">
          {brandbook?.attributes?.Personagens?.TituloImagem?.map(
            (exemplo: any, index: number) => (
              <div
                key={index}
                className="flex flex-col gap-7 justify-center items-center"
              >
                <img
                  key={index}
                  src={`${exemplo?.Imagem?.data?.attributes?.url}`}
                  alt={`${exemplo?.Imagem?.data?.attributes?.name}`}
                />
                <span className="text-[clamp(18px,2.25vw,30px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,22px)] md:font-bold sm:font-bold font-inter font-medium text-hover_ton">
                  {exemplo?.Titulo}
                </span>
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id="FormasDeAplicacao"
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-20 md:mt-5 sm:h-5 sm:mt-4 font-tonCondensedVF-font sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton h-24 font-light">
          {brandbook?.attributes?.FormasDeAplicacao?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full md:mb-0 sm:mt-10 sm:w-full sm:mb-10 mb-24 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.FormasDeAplicacao?.Descricao}
        </p>
        {brandbook?.attributes?.FormasDeAplicacao?.Exemplos?.map(
          (exemplo: any, index: number) => (
            <div
              key={index}
              className="sm:flex-col sm:mb-8 sm:gap-8 flex flex-row gap-20 mb-52"
            >
              <img
                className="sm:mb-3 sm:self-center w-fit"
                src={`${exemplo?.Imagem?.data?.attributes?.url}`}
                alt={`${exemplo?.Imagem?.data?.attributes?.name}`}
              />
              <div className="sm:gap-8 flex flex-col gap-7">
                <span className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,20px)] md:font-bold sm:font-bold font-inter font-medium text-hover_ton">
                  {exemplo?.Titulo}
                </span>
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full md:mb-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                  {exemplo?.Descricao}
                </p>
              </div>
            </div>
          ),
        )}
        <div className="flex flex-row items-center gap-2">
          <TfiArrowTopRight color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-interV-font font-medium">
            {
              brandbook?.attributes?.FormasDeAplicacao
                ?.TextoAplicacaoNoGrafismos
            }
          </span>
        </div>
        <div
          className={`md:grid-cols-2 sm:grid-cols-1 ${
            brandbook?.attributes?.FormasDeAplicacao
              ?.ExemploAplicacaoNoGrafismos.length === 2 && 'grid-cols-2'
          } ${
            brandbook?.attributes?.FormasDeAplicacao
              ?.ExemploAplicacaoNoGrafismos.length >= 3 && 'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.FormasDeAplicacao
                   ?.ExemploAplicacaoNoGrafismos.length === 1 && 'grid-cols-1'
               } grid md:gap-5 sm:gap-5 gap-9`}
        >
          {brandbook?.attributes?.FormasDeAplicacao?.ExemploAplicacaoNoGrafismos.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:gap-4 md:mt-10 sm:mt-10 sm:gap-8 flex flex-col gap-16`}
              >
                <h6 className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,28px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,28px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(14px,1.565vw,30px)] sm:font-bold font-inter text-hover_ton font-bold">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.FormasDeAplicacao
                      ?.ExemploAplicacaoNoGrafismos.length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.FormasDeAplicacao
                      ?.ExemploAplicacaoNoGrafismos.length !== 2 && 'flex-col'
                  } flex gap-16 md:gap-9 sm:gap-6`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:w-full w-11/12 text-hover_ton font-medium font-inter">
                    {uses?.Descricao}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id="FormasDeAplicacao2"
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-20 md:mt-5 sm:h-9 sm:mt-4 font-tonCondensedVF-font sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton h-24 font-light">
          {brandbook?.attributes?.FormasDeAplicacao2?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full md:mb-0 sm:mt-10 sm:mb-0 sm:text-xl sm:w-full text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.FormasDeAplicacao2?.Descricao}
        </p>
        <div className="flex flex-col gap-3">
          <img
            className="w-full"
            src={`${brandbook?.attributes?.FormasDeAplicacao2?.PrimeiraImagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.FormasDeAplicacao2?.PrimeiraImagem?.data?.attributes?.name}`}
          />
          <div className="md:grid-cols-1 sm:grid-cols-1 sm:gap-2 grid grid-cols-2 gap-2">
            {brandbook?.attributes?.FormasDeAplicacao2?.PrimeiroBlocoDeImagens?.data.map(
              (image: any, index: number) => (
                <img
                  key={index}
                  src={`${image?.attributes?.url}`}
                  alt={`${image?.attributes?.name}`}
                  className={`w-full h-full`}
                />
              ),
            )}
          </div>
          <div className="md:grid-cols-1 sm:grid-cols-1 sm:gap-2 grid grid-cols-3 gap-2">
            {brandbook?.attributes?.FormasDeAplicacao2?.SegundoBlocoDeImagens?.data.map(
              (image: any, index: number) => (
                <img
                  key={index}
                  src={`${image?.attributes?.url}`}
                  alt={`${image?.attributes?.name}`}
                  className="w-full h-auto"
                />
              ),
            )}
          </div>
          <img
            className="w-full"
            src={`${brandbook?.attributes?.FormasDeAplicacao2?.UltimaImagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.FormasDeAplicacao2?.UltimaImagem?.data?.attributes?.name}`}
          />
        </div>
      </section>
      <ScrollToTop />
    </Layout>
  );
};

export default Personagens;
