import React, { useEffect, useState } from 'react';
import { Skeleton as SkeletonBase } from 'antd';
import { useParams } from 'react-router-dom';

const SkeletonHeader: React.FC = () => {
  const { brand } = useParams();
  const [isCellPhone, setIsCellPhone] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsCellPhone(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsCellPhone(window.matchMedia('(max-width: 767px)').matches);
    });
    window.document.documentElement.scrollTo({ top: 0 });
  }, []);

  return (
    <div
      className={`sm:py-2 pl-[22%] sm:pl-9 h-full flex flex-row items-center justify-between ${
        brand === 'stone' ? 'bg-stone' : 'bg-ton'
      } mt-11 gap-10 md:pl-20`}
    >
      <SkeletonBase.Button
        active
        size="large"
        style={{ width: isCellPhone ? '40vw' : '200px', minWidth: '5rem' }}
      />
      <div className="flex gap-3 pr-24 sm:pr-9 md:pr-14 md:w-full sm:w-max md:justify-between">
        <SkeletonBase.Button
          active
          size="large"
          style={{ width: isCellPhone ? '10vw' : '200px', minWidth: '2rem' }}
        />
        <SkeletonBase.Button
          active
          size="large"
          style={{ width: isCellPhone ? '10vw' : '100px', minWidth: '3rem' }}
        />
      </div>
    </div>
  );
};

export default SkeletonHeader;
