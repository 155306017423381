import { useEffect, useState } from 'react';
import api from '../services/api';

interface HomePage {
  attributes: {
    SubTitle: string;
    Title: string;
    TitleBrand: string;
    BrandsImagesUrls: {
      ImagesUrl: any;
    };
  };
}

export const useHomePage = () => {
  const [homePage, setHomePage] = useState<HomePage>();
  const [isLoading, setIsLoading] = useState(false);

  const getHomePage = async () => {
    setIsLoading(true)
    const { data } = await api.get(
      // '/home-page?populate=*',
      'home-page?populate[BrandsImagesUrls][populate]=ImagesUrl.Images,ImagesUrl.Video',
    );
    if (data && data.data) setHomePage(data.data);
    setIsLoading(false)
  };

  useEffect(() => {
    getHomePage();
  }, []);

  return { homePage, isLoading };
};
