import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useFooter } from '../../hooks/useFooter';
import Layout from '../../components/Layout';
import { setToken } from '../../lib/auth';
import toast, { Toaster } from 'react-hot-toast';
import * as yup from 'yup';
import api from '../../services/api';

type FormValues = {
  identifier: string;
  password: string;
};

type FormValuesErrors = {
  identifier: string;
  password: string;
  api: any;
};

const Login: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { brand } = useParams();
  const { footer } = useFooter();
  const [data, setData] = useState<FormValues>({
    identifier: '',
    password: '',
  });
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  const [errors, setErrors] = useState<FormValuesErrors>({
    identifier: '',
    password: '',
    api: '',
  });

  const schema = yup.object().shape({
    identifier: yup.string().required('E-mail é obrigatório'),
    password: yup.string().required('Senha é obrigatória'),
  });

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await schema.validate(data, { abortEarly: false });
      const responseData = await api.post(
        `${process.env.REACT_APP_NEXT_PUBLIC_STRAPI_URL}/auth/local`,
        {
          identifier: data.identifier,
          password: data.password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_NEXT_STRAPI_API_TOKEN}`,
          },
        },
      );

      const to = state?.from ? state.from : `/${brand}/overview`;
      navigate(to, { state: { id: state.id } });
      setToken(responseData.data);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const yupErrors = error.inner.reduce((acc, currentError) => {
          return {
            ...acc,
            [currentError.path as keyof FormValues]: currentError.message,
          };
        }, {});

        setErrors(yupErrors as FormValuesErrors);
      }
    }
  };

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <Layout brand={brand} footer={footer} isMobile={isMobile}>
      <Toaster position="top-center" reverseOrder={false} />
      <h3
        className={`sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] font-sharonDisplay-font sm:w-full sm:tracking-wide text-home_page text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] ${
          brand === 'stone'
            ? 'text-home_page'
            : 'text-hover_ton font-tonCondensedVF-font'
        } font-bold`}
      >
        Login
      </h3>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-22 mt-32 md:mt-16 mb-36 sm:mb-96 overflow-hidden"
      >
        <div className="relative sm:ml-2 md:w-full w-[40.87vw] flex flex-col items-baseline gap-1">
          <input
            type="text"
            name="identifier"
            onChange={handleChange}
            placeholder="E-mail"
            className={`py-4 pl-4 ${
              brand === 'stone' ? 'text-home_page' : 'text-hover_ton'
            } rounded-none border-b-2 ${
              brand === 'stone' ? 'border-b-home_page' : 'border-b-hover_ton'
            } font-inter block w-full pr-3 pb-4 ${
              brand === 'stone' ? 'bg-stone' : 'bg-ton'
            } ${
              brand === 'stone'
                ? 'placeholder-home_page focus:placeholder-home_page focus:border-blue-300 focus:ring-blue-300 focus:text-home_page'
                : 'bg-hover_ton placeholder-hover_ton focus:border-hover_ton focus:placeholder-bg-hover_ton'
            }  focus:outline-none md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.56vw,30px)]`}
          />
          {errors.identifier && (
            <p
              className={`${
                brand === 'stone' ? 'text-home_page' : 'text-hover_ton'
              }`}
            >
              {errors.identifier}
            </p>
          )}
        </div>
        <div className="relative sm:ml-2 md:w-full w-[40.87vw] flex flex-col items-baseline gap-1">
          <input
            type="password"
            name="password"
            onChange={handleChange}
            placeholder="Senha"
            className={`py-4 pl-4 rounded-none border-b-2  font-inter block w-full pr-3 pb-4 ${
              brand === 'stone'
                ? 'text-home_page border-b-home_page bg-stone placeholder-home_page focus:placeholder-home_page focus:border-blue-300 focus:ring-blue-300 focus:text-home_page'
                : 'text-hover_ton bg-ton border-b-hover_ton placeholder-hover_ton focus:border-hover_ton focus:placeholder-bg-hover_ton'
            } outline-none md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.56vw,30px)]`}
          />
          {errors.password && (
            <p
              className={`${
                brand === 'stone' ? 'text-home_page' : 'text-hover_ton'
              }`}
            >
              {errors.password}
            </p>
          )}
        </div>
        <button
          className={`rounded-3xl py-2 font-medium font-inter ${
            brand === 'stone'
              ? 'bg-main_color text-stone'
              : 'bg-hover_ton text-ton'
          } p-2 w-28 h-12 `}
          type="submit"
        >
          LOG IN
        </button>
      </form>
    </Layout>
  );
};

export default Login;
