import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages';
import Overview from './pages/Overview';
import Posicionamento from './pages/Posicionamento';
import TomDeVoz from './pages/TomDeVoz';
import Login from './pages/Login';
import Logo from './pages/Logo';
import Grafismo from './pages/Grafismo';
import Personagens from './pages/Personagens';
import Tipografia from './pages/Tipografia';
import Cores from './pages/Cores';
import Fotografia from './pages/Fotografia';
import Ilustracoes from './pages/Ilustracoes';
import Motion from './pages/Motion';
import Som from './pages/Som';
import Aplicacoes from './pages/Aplicacoes';
import Submarcas from './pages/Submarcas';
import Campanhas from './pages/Campanhas';
import RendaExtra from './pages/RendaExtra';
import Downloads from './pages/Downloads';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:brand/overview" element={<Overview />} />
      <Route path="/:brand/logo" element={<Logo />} />
      <Route path="/:brand/grafismo" element={<Grafismo />} />
      <Route path="/:brand/personagens" element={<Personagens />} />
      <Route path="/:brand/tipografia" element={<Tipografia />} />
      <Route path="/:brand/cores" element={<Cores />} />
      <Route path="/:brand/login" element={<Login />} />
      <Route path="/:brand/posicionamento" element={<Posicionamento />} />
      <Route path="/:brand/tomdevoz" element={<TomDeVoz />} />
      <Route path="/:brand/fotografia" element={<Fotografia />} />
      <Route path="/:brand/ilustracoes" element={<Ilustracoes />} />
      <Route path="/:brand/motion" element={<Motion />} />
      <Route path="/:brand/som" element={<Som />} />
      <Route path="/:brand/aplicacoes" element={<Aplicacoes />} />
      <Route path="/:brand/submarcas" element={<Submarcas />} />
      <Route path="/:brand/campanhas" element={<Campanhas />} />
      <Route path="/:brand/rendaextra" element={<RendaExtra />} />
      <Route path="/:brand/downloads" element={<Downloads />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
