import React, { useEffect, useState } from 'react';
import { useBrandBooks } from '../../../hooks/useBrandBooks';
import { TfiArrowTopRight } from 'react-icons/tfi';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Ton: React.FC = () => {
  const { brandbook, isLoading } = useBrandBooks();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] font-tonCondensedVF-font sm:w-full sm:tracking-wide text-hover_ton font-bold"
      >
        {brandbook?.attributes?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(20px,1.565vw,30px)] md:w-full sm:mt-5 sm:w-full text-hover_ton w-full mt-14 mb-14 font-inter whitespace-pre-wrap font-medium">
        {brandbook?.attributes?.Descricao}
      </p>
      <img
        src={`${brandbook?.attributes?.Midia.data?.attributes?.url}`}
        alt={`${brandbook?.attributes?.Midia.data?.attributes?.name}`}
      />
      <section className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14">
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-14 md:mt-5 sm:h-10 font-tonCondensedVF-font sm:mt-5 sm:w-full sm:tracking-wide text-hover_ton h-24 font-light">
          {brandbook?.attributes?.Secao?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mb-10 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Secao?.Descricao}
        </p>
        <img
          src={`${brandbook?.attributes?.Secao?.Imagem.data?.attributes?.url}`}
          alt={`${brandbook?.attributes?.Secao?.Imagem.data?.attributes?.name}`}
        />
        <div className="sm:mt-12 sm:items-start flex flex-row items-center gap-5 mt-10">
          <TfiArrowTopRight color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-inter">
            {brandbook?.attributes?.Secao?.TituloExemplo}
          </span>
        </div>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full  sm:w-full text-hover_ton font-inter whitespace-pre-wrap font-medium">
          {brandbook?.attributes?.Secao?.DescricaoExemplo}
        </p>
        <div className="grid grid-cols-1">
          {brandbook?.attributes?.Secao?.ImagemDescricaoExemplo.map(
            (imagemDesc: any, index: number) => (
              <div key={index} className="flex flex-col gap-12">
                <img
                  key={index}
                  src={`${imagemDesc?.Imagem.data?.attributes?.url}`}
                  alt={`${imagemDesc?.Imagem.data?.attributes?.name}`}
                />
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full sm:w-full  text-hover_ton font-inter whitespace-pre-wrap font-medium">
                  {imagemDesc?.DescricaoImagem}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="sm:items-start flex flex-row items-center gap-5 mt-10">
          <TfiArrowTopRight color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-inter">
            {brandbook?.attributes?.Secao?.TituloExemplo2}
          </span>
        </div>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full  sm:w-full text-hover_ton font-inter whitespace-pre-wrap font-medium">
          {brandbook?.attributes?.Secao?.DescricaoExemplo2}
        </p>
        <div className="sm:gap-8 md:grid-cols-1 sm:grid-cols-1 grid grid-cols-2 gap-2">
          {brandbook?.attributes?.Secao?.ImagemDescricaoExemplo2.map(
            (imagemDesc: any, index: number) => (
              <div key={index} className="sm:gap-8 flex flex-col gap-12">
                <img
                  key={index}
                  src={`${imagemDesc?.Imagem.data?.attributes?.url}`}
                  alt={`${imagemDesc?.Imagem.data?.attributes?.name}`}
                />
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full sm:w-full  text-hover_ton font-inter whitespace-pre-wrap font-medium">
                  {imagemDesc?.DescricaoImagem}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="sm:mt-12 sm:items-start flex flex-row items-center gap-5 mt-10">
          <TfiArrowTopRight color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-inter">
            {brandbook?.attributes?.Secao?.TituloExemplo3}
          </span>
        </div>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full  sm:w-full text-hover_ton font-inter whitespace-pre-wrap font-medium">
          {brandbook?.attributes?.Secao?.DescricaoExemplo3}
        </p>
        <div className="sm:gap-8 md:grid-cols-1 sm:grid-cols-1 grid grid-cols-3 gap-22">
          {brandbook?.attributes?.Secao?.ImagemDescricaoExemplo3.map(
            (imagemDesc: any, index: number) => (
              <div key={index} className="sm:gap-8 flex flex-col gap-12">
                <img
                  key={index}
                  src={`${imagemDesc?.Imagem.data?.attributes?.url}`}
                  alt={`${imagemDesc?.Imagem.data?.attributes?.name}`}
                />
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full sm:w-full  text-hover_ton font-inter whitespace-pre-wrap font-medium">
                  {imagemDesc?.DescricaoImagem}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="sm:mt-12 sm:items-start flex flex-row items-center gap-5 mt-10">
          <TfiArrowTopRight color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-inter">
            {brandbook?.attributes?.Secao?.TituloExemplo4}
          </span>
        </div>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full  sm:w-full text-hover_ton font-inter whitespace-pre-wrap font-medium">
          {brandbook?.attributes?.Secao?.DescricaoExemplo4}
        </p>
        <div className="sm:gap-8 md:grid-cols-1 sm:grid-cols-1 grid grid-cols-3 gap-22">
          {brandbook?.attributes?.Secao?.ImagemDescricaoExemplo4.map(
            (imagemDesc: any, index: number) => (
              <div key={index} className="sm:gap-8 flex flex-col gap-12">
                <img
                  key={index}
                  src={`${imagemDesc?.Imagem.data?.attributes?.url}`}
                  alt={`${imagemDesc?.Imagem.data?.attributes?.name}`}
                />
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full sm:w-full  text-hover_ton font-inter whitespace-pre-wrap font-medium">
                  {imagemDesc?.DescricaoImagem}
                </p>
              </div>
            ),
          )}
        </div>
      </section>
      <section className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14">
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-14 md:mt-5 sm:h-10 font-tonCondensedVF-font sm:mt-5 sm:w-full sm:tracking-wide text-hover_ton h-24 font-light">
          {brandbook?.attributes?.Secao2?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mb-10 sm:font-medium font-inter text-hover_ton font-normal whitespace-pre-wrap">
          {brandbook?.attributes?.Secao2?.Descricao}
        </p>
        <div className="flex flex-col gap-3">
          <img
            className="w-full"
            src={`${brandbook?.attributes?.Secao2?.PrimeiraImagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Secao2?.PrimeiraImagem?.data?.attributes?.name}`}
          />
          <div className="md:grid-cols-1 sm:grid-cols-1 sm:gap-2 grid grid-cols-2 gap-2">
            {brandbook?.attributes?.Secao2?.PrimeiroBlocoDeImagens?.data.map(
              (image: any, index: number) => (
                <img
                  key={index}
                  src={`${image?.attributes?.url}`}
                  alt={`${image?.attributes?.name}`}
                  className="w-full h-auto"
                />
              ),
            )}
          </div>
          <img
            className="w-full"
            src={`${brandbook?.attributes?.Secao2?.SegundaImagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Secao2?.SegundaImagem?.data?.attributes?.name}`}
          />
          <div className="md:grid-cols-1 sm:grid-cols-1 sm:gap-2 grid grid-cols-3 gap-3">
            {brandbook?.attributes?.Secao2?.SegundoBlocoDeImagens?.data.map(
              (image: any, index: number) => (
                <img
                  key={index}
                  src={`${image?.attributes?.url}`}
                  alt={`${image?.attributes?.name}`}
                  className="w-full h-auto"
                />
              ),
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Ton;
