import React, { useEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';
import { BiUpArrowAlt } from 'react-icons/bi';
import { useParams } from 'react-router-dom';

const ScrollToTop = () => {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisiblity] = useState(false);
  const { brand } = useParams();

  useEffect(() => {
    if (pageYOffset > 400) {
      setVisiblity(true);
    } else {
      setVisiblity(false);
    }
  }, [pageYOffset]);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  if (!visible) {
    return null;
  }

  return (
    <div
      className="md:w-full sm:w-full sm:w-full cursor-pointer w-full h-5 flex flex-row bg-transparent relative mt-60 md:mt-64 sm:mt-44 sm:mt-56"
      onClick={scrollToTop}
    >
      <div className="w-44 h-5 flex flex-row absolute right-0 items-center justify-end">
        <BiUpArrowAlt
          color={`${brand === 'stone' ? '#00AF55' : '#003C00'}`}
          size={22}
        />
        <span
          className={`${
            brand === 'stone' ? 'text-hover_stone' : 'text-hover_ton'
          } font-inter font-medium`}
        >
          VOLTAR AO TOPO
        </span>
      </div>
    </div>
  );
};

export default ScrollToTop;
