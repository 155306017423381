import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getTokenFromLocalCookie } from '../lib/auth';
import api from '../services/api';

interface BrandBook {
  attributes: any;
}

export const useAuthBrandBooks = () => {
  const [authBrandBook, setAuthBrandBook] = useState<BrandBook | undefined>();
  const location = useLocation();
  const jwt = getTokenFromLocalCookie();
  const [isLoading, setIsLoading] = useState(false);

  const getAuthBrandBook = async () => {
    const handleApi = (
      brand: string | undefined,
      variant: string | undefined,
    ) => {
      const Variants =
        brand === 'stone'
          ? {
            posicionamento:
              'posicionamentos/1?populate=Titulo,Descricao,Objetivo.Imagem,Proposito,Arquetipo.Imagem,TituloBeneficios,Beneficios,BrandStatement,BrandIdea,TracosDePersonalidade',
            tomdevoz:
              'tom-de-vozs/1?populate=Inicio,Manifesto,PersonalidadeDeMarca.personalidade_de_marcas.ComoFuncionaExemplos,PersonalidadeDeMarca.personalidade_de_marcas.Exemplos.Imagem,PersonalidadeDeMarca.personalidade_de_marcas.ContraArgumento',
            som: 'soms/1?populate=Inicio.Midia,Diretrizes.Diretriz,SoundLogo.Midia,SoundGuideline.Exemplo.AudioMidia,SoundUX.Midia,SoundUX.ExemploUX.Image,SoundUX.ExemploUX.Audio',
            campanhas: 'campanhas/1?populate=Secao.Video',
            downloads: 'downloads/1?populate=Downloads',
            default: '',
          }
          : {
            posicionamento:
              'posicionamentos/2?populate=Titulo,Descricao,Objetivo.Imagem,Proposito,Arquetipo.Imagem,TituloBeneficios,Beneficios,BrandStatement,BrandIdea,TracosDePersonalidade',
            tomdevoz:
              'tom-de-vozs/2?populate=Inicio.Imagem,MeuTon.DicasExemplo,MeuTon.Exemplos.tom_de_voz_exemplos,Personalidade.personalidades.Frases,Personalidade.personalidades.ExemploDestacado',
            som: 'soms/2?populate=Inicio.Midia,Diretrizes.Diretriz,SoundLogo.Midia,SoundGuideline.Exemplo.AudioMidia,SoundUX.Midia,SoundUX.ExemploUX.Image,SoundUX.ExemploUX.Audio',
            campanhas: 'campanhas/2?populate=Secao.Video',
            downloads: 'downloads/2?populate=Downloads',
            default: '',
          };
      return Variants[variant as keyof typeof Variants] || Variants['default'];
    };

    setIsLoading(true);
    try {
      const { data } = await api.get(
        handleApi(
          location.pathname.split('/')[1],
          location.pathname.split('/')[2],
        ),
        jwt
          ? {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwt}`,
            },
          }
          : undefined,
      );
      if (data) setAuthBrandBook(data.data);
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAuthBrandBook();
  }, []);

  return { authBrandBook, isLoading };
};
