import React, { useEffect, useRef, useState } from 'react';
import { useBrandBooks } from '../../../hooks/useBrandBooks';
import { TfiArrowTopRight } from 'react-icons/tfi';
import ReactPlayer from 'react-player';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Ton: React.FC = () => {
  const { brandbook, isLoading } = useBrandBooks();
  const [observers, setObservers] = useState<IntersectionObserver[]>([]);
  const videoPlayers = useRef<Array<ReactPlayer | null>>([]);
  const playersContainersRef = useRef<Array<React.RefObject<HTMLDivElement>>>(
    [],
  );
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    videoPlayers.current = Array.from({ length: 6 }, () => null);
    playersContainersRef.current = Array.from({ length: 6 }, () =>
      React.createRef<HTMLDivElement>(),
    );
    const newObservers = playersContainersRef.current.map(
      (_, index) =>
        new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.seekTo(0);
                  videoPlayers.current[index]?.getInternalPlayer().play();
                }
              } else {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.getInternalPlayer().pause();
                  videoPlayers.current[index]?.seekTo(0);
                }
              }
            });
          },
          { threshold: 0.2 },
        ),
    );

    // Set the state with the initialized arrays
    setObservers(newObservers);

    return () => {
      // Clean up observers when component unmounts
      newObservers.forEach(observer => observer.disconnect());
    };
  }, []);

  useEffect(() => {
    // Observe player containers when isLoading changes
    playersContainersRef.current.forEach((ref, index) => {
      if (ref.current) {
        observers[index].observe(ref.current);
      }
    });
  }, [isLoading]);

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:w-full sm:tracking-wide text-hover_ton font-bold"
      >
        {brandbook?.attributes?.Inicio?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:w-full sm:mt-5 md:mt-8 text-hover_ton w-full mt-14 mb-14 font-inter whitespace-pre-wrap font-medium">
        {brandbook?.attributes?.Inicio?.Descricao}
      </p>
      <div className="md:mt-8 sm:mt-2 sm:gap-6 flex flex-col gap-8">
        <div className="text-[clamp(28px,1.25vw,40px)] leading-[clamp(30px,2.4vw,50px)] sm:leading-[clamp(16px,11.7vw,50px)] sm:text-[clamp(18px,5.6vw,24px)] md:leading-[clamp(22px,5.9vw,50px)] md:text-[clamp(24px,3.35vw,28px)] md:h-16 sm:px-10 md:px-10 md:w-80 sm:h-16 sm:items-start sm:pl-6 sm:w-full w-96 h-20 flex flex-col items-center justify-center text-hover_ton font-medium font-inter whitespace-pre-wrap bg-ton_secondary rounded-bl-none rounded-2xl">
          {brandbook?.attributes?.Inicio?.AvisoTitulo}
        </div>
        <div className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:px-11 sm:w-full px-10 py-10 flex flex-col items-center justify-center w-2/2.8 h-fit text-hover_ton font-medium font-inter bg-ton_secondary rounded-tl-none rounded-4xl whitespace-pre-wrap">
          {brandbook?.attributes?.Inicio?.AvisoConteudo}
        </div>
      </div>
      <section
        id={brandbook?.attributes?.Overview?.Titulo}
        className="sm:gap-5 flex flex-col gap-12 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.Overview?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-0 md:mb-0 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Overview?.Descricao}
        </p>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[0]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[0] = ref)}
              muted
              className="md:w-full sm:w-full sm:mt-1 w-full mt-6 react-player-preview"
              url={`${brandbook?.attributes?.Overview?.Video?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
        <div
          className={`md:gap-6 md:grid-cols-2 sm:gap-6 sm:grid-cols-1 grid grid-cols-3 gap-7 gap-y-6`}
        >
          {brandbook?.attributes?.Overview?.TonImagens?.data?.map(
            (uses: any, index: number) => (
              <img
                key={index}
                src={`${uses?.attributes?.url}`}
                alt={`${uses?.attributes?.name}`}
                className="w-full"
              />
            ),
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.ConsideracoesGerais?.Titulo}
        className="sm:gap-5 flex flex-col gap-12 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.ConsideracoesGerais?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:w-full md:mb-0 sm:mt-0 sm:mb-0 sm:w-full text-hover_ton w-full my-10 md:mt-0 font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.ConsideracoesGerais?.Descricao}
        </p>
        <div className="sm:mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.ConsideracoesGerais?.TextoFormasIniciais}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] font-inter font-medium text-hover_ton">
          {brandbook?.attributes?.ConsideracoesGerais?.DescricaoFormasIniciais}
        </p>
        <div className="md:flex-col md:gap-16 md:mb-0 sm:mb-0 sm:flex-col sm:gap-2 sm:mt-6 flex flex-row gap-4">
          {brandbook?.attributes?.ConsideracoesGerais?.Exemplos?.map(
            (exemplos: any, index: number) => (
              <div key={index} className="sm:mb-8 flex flex-col gap-6">
                <span className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {exemplos?.Titulo}
                </span>
                <img
                  key={index}
                  src={`${exemplos?.Imagem?.data?.attributes?.url}`}
                  alt={`${exemplos?.Imagem?.data?.attributes?.name}`}
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] font-inter font-medium text-hover_ton">
                  {exemplos?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.ConsideracoesGerais?.TextoRespiroIcone}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] font-inter font-medium text-hover_ton">
          {brandbook?.attributes?.ConsideracoesGerais?.DescricaoRespiroIcone}
        </p>
        <img
          className="w-full"
          src={`${brandbook?.attributes?.ConsideracoesGerais?.ImagemRespiroIcone?.data?.attributes?.url}`}
          alt={`${brandbook?.attributes?.ConsideracoesGerais?.ImagemRespiroIcone?.data?.attributes?.name}`}
        />
      </section>
      <section
        id={brandbook?.attributes?.Expansao?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.Expansao?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:w-full md:mb-0 sm:mt-0 sm:mb-0 sm:w-full text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Expansao?.Descricao}
        </p>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[1]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[1] = ref)}
              muted
              className="md:w-full sm:w-full sm:mt-1 w-full mt-6 react-player-preview"
              url={`${brandbook?.attributes?.Expansao?.Midia?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Expansao?.TextoComoConstruir}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-8 font-inter font-medium text-hover_ton">
          {brandbook?.attributes?.Expansao?.DescricaoComoConstruir}
        </p>
        <div className="flex flex-col sm:gap-20 gap-24">
          {brandbook?.attributes?.Expansao?.grafismo_elementos?.data?.map(
            (element: any, index: number) => (
              <div key={index} className="sm:gap-10 flex flex-col gap-12">
                <span className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold text-hover_ton font-medium font-inter">
                  {element?.attributes?.Titulo}
                </span>
                <div
                  className={`md:grid-cols-2 md:gap-4 md:mb-10 sm:mb-10 sm:gap-12 sm:grid-cols-1 grid grid-cols-3 gap-17`}
                >
                  {element?.attributes?.Elementos?.map(
                    (el: any, index: number) => (
                      <div
                        key={index}
                        className="md:gap-5 sm:gap-5 flex flex-col gap-10"
                      >
                        <img
                          src={`${el?.Imagem?.data?.attributes?.url}`}
                          alt={`${el?.Imagem?.data?.attributes?.name}`}
                          className="md:w-full sm:w-full w-fit"
                        />
                        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] font-inter font-medium text-hover_ton">
                          {el?.Descricao}
                        </p>
                      </div>
                    ),
                  )}
                </div>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-11">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Expansao?.TextoCantosArredondados}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-8 font-inter font-medium text-hover_ton">
          {brandbook?.attributes?.Expansao?.DescricaoCantosArredondados}
        </p>
        <div className="grid-cols-3 grid md:grid md:grid-cols-2 md:flex-col md:mb-0 md:gap-5 sm:mb-12 sm:flex-col sm:flex gap-4">
          {brandbook?.attributes?.Expansao?.Exemplos?.map(
            (exemplos: any, index: number) => (
              <div
                key={index}
                className="md:mt-7 sm:mt-7 sm:mb-8 sm:gap-10 flex flex-col gap-6"
              >
                <span className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {exemplos?.Titulo}
                </span>
                <img
                  key={index}
                  src={`${exemplos?.Imagem?.data?.attributes?.url}`}
                  alt={`${exemplos?.Imagem?.data?.attributes?.name}`}
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] font-inter font-medium text-hover_ton">
                  {exemplos?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-0">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Expansao?.TextoRespiroTitulo}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-8 font-inter font-medium text-hover_ton">
          {brandbook?.attributes?.Expansao?.DescricaoRespiroTitulo}
        </p>
        <div className=" grid-cols-2 md:mt-16 md:grid-cols-2 md:mb-0 md:gap-2 sm:gap-6 sm:mt-0 grid sm:grid-cols-1 sm:mb-14 gap-4">
          {brandbook?.attributes?.Expansao?.ImagensRespiroTitulo?.data?.map(
            (exemplos: any, index: number) => (
              <div
                key={index}
                className={`sm:mb-0 md:mb-4 flex flex-col gap-6 ${
                  index === 1 && 'md:mt-4 sm:mt-0 mt-0'
                }`}
              >
                <img
                  key={index}
                  src={`${exemplos?.attributes?.url}`}
                  alt={`${exemplos?.attributes?.name}`}
                />
              </div>
            ),
          )}
        </div>
        <div className="md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-11">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Expansao?.TextoPossibilidadesDeUsoTon}
          </span>
        </div>
        <div
          className={`md:grid-cols-2 sm:grid-cols-1 ${
            brandbook?.attributes?.Expansao
              ?.PossibilidadesDeUsoVerticalGrandeTon.length === 2 &&
            'grid-cols-2'
          } ${
            brandbook?.attributes?.Expansao
              ?.PossibilidadesDeUsoVerticalGrandeTon.length >= 3 &&
            'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.Expansao
                   ?.PossibilidadesDeUsoVerticalGrandeTon.length === 1 &&
                 'grid-cols-1'
               } grid md:gap-5 sm:gap-5 gap-9`}
        >
          {brandbook?.attributes?.Expansao?.PossibilidadesDeUsoVerticalGrandeTon.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:gap-8 md:mt-10 sm:mt-10 sm:gap-8 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.Expansao
                      ?.PossibilidadesDeUsoVerticalGrandeTon.length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.Expansao
                      ?.PossibilidadesDeUsoVerticalGrandeTon.length !== 2 &&
                    'flex-col'
                  } flex gap-9 sm:gap-6`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-hover_ton font-medium font-inter">
                    {uses?.Descricao}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div
          className={`md:grid-cols-1 md:mt-10 sm:grid-cols-1 sm:mt-8 grid grid-cols-2 gap-9`}
        >
          {brandbook?.attributes?.Expansao?.PossibilidadesDeUsoVerticalMedioTon.map(
            (uses: any, index: number) => (
              <div key={index} className={`flex flex-col gap-9 sm:mt-6`}>
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <img
                  src={`${uses?.Imagem?.data?.attributes?.url}`}
                  alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  className="md:w-full sm:w-full w-fit"
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-hover_ton font-medium font-inter">
                  {uses?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
        <div
          className={`md:grid-cols-2 md:gap-4 sm:gap-4 sm:mt-4 sm:grid-cols-1 ${
            brandbook?.attributes?.Expansao
              ?.PossibilidadeDeUsoVerticalPequenoTon.length === 2 &&
            'grid-cols-2'
          } ${
            brandbook?.attributes?.Expansao
              ?.PossibilidadeDeUsoVerticalPequenoTon.length >= 3 &&
            'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.Expansao
                   ?.PossibilidadeDeUsoVerticalPequenoTon.length === 1 &&
                 'grid-cols-1'
               } grid gap-9`}
        >
          {brandbook?.attributes?.Expansao?.PossibilidadeDeUsoVerticalPequenoTon.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-6 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.Expansao
                      ?.PossibilidadeDeUsoVerticalPequenoTon.length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.Expansao
                      ?.PossibilidadeDeUsoVerticalPequenoTon.length !== 2 &&
                    'flex-col'
                  } flex md:gap-5 sm:gap-5 gap-9`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-hover_ton font-medium font-inter">
                    {uses?.Descricao}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-11">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Expansao?.TextoPossibilidadesDeAplicacao}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-8 font-inter font-medium text-hover_ton">
          {brandbook?.attributes?.Expansao?.DescricaoPossibilidadesDeAplicacao}
        </p>
        <div
          className={`md:grid-cols-2 sm:grid-cols-1 ${
            brandbook?.attributes?.Expansao?.ExemplosPossibilidadesDeAplicacao
              .length === 2 && 'grid-cols-2'
          } ${
            brandbook?.attributes?.Expansao?.ExemplosPossibilidadesDeAplicacao
              .length >= 3 && 'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.Expansao
                   ?.ExemplosPossibilidadesDeAplicacao.length === 1 &&
                 'grid-cols-1'
               } grid md:gap-5 sm:gap-5 gap-9`}
        >
          {brandbook?.attributes?.Expansao?.ExemplosPossibilidadesDeAplicacao.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-4 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.Expansao
                      ?.ExemplosPossibilidadesDeAplicacao.length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.Expansao
                      ?.ExemplosPossibilidadesDeAplicacao.length !== 2 &&
                    'flex-col'
                  } flex  gap-9 sm:gap-6`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-hover_ton font-medium font-inter">
                    {uses?.DescricaoImagem}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div className="md:mt-10 md:gap-8 sm:gap-6 sm:mt-8 flex flex-col gap-12">
          <img
            className="w-full"
            src={`${brandbook?.attributes?.Expansao?.PossibilidadesDeAplicacaoImagemGrade?.Imagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Expansao?.PossibilidadesDeAplicacaoImagemGrade?.Imagem?.data?.attributes?.name}`}
          />
          <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-hover_ton font-medium font-inter">
            {
              brandbook?.attributes?.Expansao
                ?.PossibilidadesDeAplicacaoImagemGrade?.DescricaoImagem
            }
          </p>
        </div>
      </section>
      <section
        id={brandbook?.attributes?.BalaoDeFala?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.BalaoDeFala?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.BalaoDeFala?.Descricao}
        </p>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[2]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[2] = ref)}
              muted
              className="md:w-full sm:w-full sm:mt-1 w-full mt-6 react-player-preview"
              url={`${brandbook?.attributes?.BalaoDeFala?.Midia?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.BalaoDeFala?.TextoCantosArredondados}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-8 font-inter font-medium text-hover_ton">
          {brandbook?.attributes?.BalaoDeFala?.DescricaoCantosArredondados}
        </p>
        <div
          className={`md:grid-cols-2 sm:grid-cols-1 ${
            brandbook?.attributes?.BalaoDeFala?.ExemplosCantosArredondados
              .length === 2 && 'grid-cols-2'
          } ${
            brandbook?.attributes?.BalaoDeFala?.ExemplosCantosArredondados
              .length >= 3 && 'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.BalaoDeFala?.ExemplosCantosArredondados
                   .length === 1 && 'grid-cols-1'
               } grid md:gap-5 sm:gap-5 gap-9`}
        >
          {brandbook?.attributes?.BalaoDeFala?.ExemplosCantosArredondados.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-10 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.BalaoDeFala
                      ?.ExemplosCantosArredondados.length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.BalaoDeFala
                      ?.ExemplosCantosArredondados.length !== 2 && 'flex-col'
                  } flex gap-9`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                    {uses?.Descricao}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-12">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.BalaoDeFala?.TextoFormasDeAplicacaoDeTexto}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] font-inter font-medium text-hover_ton">
          {
            brandbook?.attributes?.BalaoDeFala
              ?.DescricaoFormasDeAplicacaoDeTexto
          }
        </p>
        <div className="md:gap-3 md:grid md:grid-cols-2 sm:gap-3 sm:gap-y-12 sm:mt-8 sm:grid sm:grid-cols-1 flex flex-col gap-24">
          {brandbook?.attributes?.BalaoDeFala?.AplicacaoDeTexto?.map(
            (aplicacao: any, index: number) => (
              <div
                key={index}
                className="md:gap-10 sm:gap-10 flex flex-col gap-12"
              >
                <span className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {aplicacao?.Titulo}
                </span>
                <div
                  className={`md:flex-row md:flex md:gap-3 sm:gap-3 sm:grid sm:grid-cols-1 grid grid-cols-2 gap-5`}
                >
                  {aplicacao?.Imagens?.data?.map((el: any, index: number) => (
                    <img
                      key={index}
                      src={`${el?.attributes?.url}`}
                      alt={`${el?.attributes?.name}`}
                      className="md:w-full sm:w-full w-fit"
                    />
                  ))}
                </div>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.BalaoDeFala?.TextoRespiroTitulo}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] font-inter font-medium text-hover_ton">
          {brandbook?.attributes?.BalaoDeFala?.DescricaoRespiroTitulo}
        </p>
        <div className="md:flex-row md:flex md:gap-3 sm:flex md:mb-0 sm:gap-8 sm:flex-col sm:mt-8 flex flex-row gap-4">
          {brandbook?.attributes?.BalaoDeFala?.RespiraTituloImagens?.data?.map(
            (exemplos: any, index: number) => (
              <div key={index}>
                <img
                  key={index}
                  src={`${exemplos?.attributes?.url}`}
                  alt={`${exemplos?.attributes?.name}`}
                />
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.BalaoDeFala?.TextoInteracaoIconeGrafismo}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] font-inter font-medium text-hover_ton">
          {brandbook?.attributes?.BalaoDeFala?.DescricaoInteracaoIconeGrafismo}
        </p>
        <div className="md:flex-row md:flex md:gap-3 sm:gap-3 sm:flex sm:flex-col flex flex-row gap-4">
          {brandbook?.attributes?.BalaoDeFala?.ExemplosInteracaoIconeGrafismo?.map(
            (exemplos: any, index: number) => (
              <div key={index} className="sm:mb-8 flex flex-col gap-6">
                <img
                  key={index}
                  src={`${exemplos?.Imagem?.data?.attributes?.url}`}
                  alt={`${exemplos?.Imagem?.data?.attributes?.name}`}
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                  {exemplos?.DescricaoImagem}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-0">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.BalaoDeFala?.TextoPossibilidadesDeUsoTon}
          </span>
        </div>
        <div
          className={`md:grid-cols-1 sm:grid-cols-1 ${
            brandbook?.attributes?.BalaoDeFala
              ?.PossibilidadesDeUsoImagensGrandes.length === 2 && 'grid-cols-2'
          } ${
            brandbook?.attributes?.BalaoDeFala
              ?.PossibilidadesDeUsoImagensGrandes.length >= 3 && 'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.BalaoDeFala
                   ?.PossibilidadesDeUsoImagensGrandes.length === 1 &&
                 'grid-cols-1'
               } grid gap-9`}
        >
          {brandbook?.attributes?.BalaoDeFala?.PossibilidadesDeUsoImagensGrandes.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-10 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.BalaoDeFala
                      ?.PossibilidadesDeUsoImagensGrandes.length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.BalaoDeFala
                      ?.PossibilidadesDeUsoImagensGrandes.length !== 2 &&
                    'flex-col'
                  } flex  gap-9`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                    {uses?.Descricao}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div
          className={`md:grid-cols-1 sm:grid-cols-1 grid grid-cols-2 gap-9`}
        >
          {brandbook?.attributes?.BalaoDeFala?.PossibilidadeDeUsoImagensMedias.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-10 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <img
                  src={`${uses?.Imagem?.data?.attributes?.url}`}
                  alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  className="md:w-full sm:w-full w-fit"
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                  {uses?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
        <div
          className={`md:grid-cols-1 sm:grid-cols-1 ${
            brandbook?.attributes?.BalaoDeFala
              ?.PossibilidadeDeUsoImagensPequenas.length === 2 && 'grid-cols-2'
          } ${
            brandbook?.attributes?.BalaoDeFala
              ?.PossibilidadeDeUsoImagensPequenas.length >= 3 && 'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.BalaoDeFala
                   ?.PossibilidadeDeUsoImagensPequenas.length === 1 &&
                 'grid-cols-1'
               } grid gap-9`}
        >
          {brandbook?.attributes?.BalaoDeFala?.PossibilidadeDeUsoImagensPequenas.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-10 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.BalaoDeFala
                      ?.PossibilidadeDeUsoImagensPequenas.length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.BalaoDeFala
                      ?.PossibilidadeDeUsoImagensPequenas.length !== 2 &&
                    'flex-col'
                  } flex  gap-9`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                    {uses?.Descricao}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {
              brandbook?.attributes?.BalaoDeFala
                ?.TextoPossibilidadesAplicacaoTon
            }
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {
            brandbook?.attributes?.BalaoDeFala
              ?.DescricaoPossibilidadesAplicacaoTon
          }
        </p>
        <div
          className={`md:grid-cols-1 sm:grid-cols-1 ${
            brandbook?.attributes?.BalaoDeFala
              ?.ExemplosPossibilidadesDeAplicacao.length === 2 && 'grid-cols-2'
          } ${
            brandbook?.attributes?.BalaoDeFala
              ?.ExemplosPossibilidadesDeAplicacao.length >= 3 && 'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.BalaoDeFala
                   ?.ExemplosPossibilidadesDeAplicacao.length === 1 &&
                 'grid-cols-1'
               } grid gap-9`}
        >
          {brandbook?.attributes?.BalaoDeFala?.ExemplosPossibilidadesDeAplicacao.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-10 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.BalaoDeFala
                      ?.ExemplosPossibilidadesDeAplicacao.length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.BalaoDeFala
                      ?.ExemplosPossibilidadesDeAplicacao.length !== 2 &&
                    'flex-col'
                  } flex  gap-9`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                    {uses?.DescricaoImagem}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div className="md:mt-10 md:gap-8 sm:gap-6 sm:mt-9 flex flex-col gap-12">
          <img
            className="w-full"
            src={`${brandbook?.attributes?.BalaoDeFala?.PossibilidadesDeAplicacaoImagemGrade?.Imagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.BalaoDeFala?.PossibilidadesDeAplicacaoImagemGrade?.Imagem?.data?.attributes?.name}`}
          />
          <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] font-inter font-medium text-hover_ton">
            {
              brandbook?.attributes?.BalaoDeFala
                ?.PossibilidadesDeAplicacaoImagemGrade?.DescricaoImagem
            }
          </p>
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Crescimento?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.Crescimento?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Crescimento?.Descricao}
        </p>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[3]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[3] = ref)}
              muted
              className="md:w-full sm:w-full sm:mt-1 w-full mt-6 react-player-preview"
              url={`${brandbook?.attributes?.Crescimento?.Midia?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Crescimento?.TextoComoConstruir}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Crescimento?.DescricaoComoConstruir}
        </p>
        <div
          className={`md:grid-cols-1 sm:grid-cols-1 ${
            brandbook?.attributes?.Crescimento?.ExemplosComoConstruir.length ===
              2 && 'grid-cols-2'
          } ${
            brandbook?.attributes?.Crescimento?.ExemplosComoConstruir.length >=
              3 && 'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.Crescimento?.ExemplosComoConstruir
                   .length === 1 && 'grid-cols-1'
               } grid md:gap-5 sm:gap-5 gap-9`}
        >
          {brandbook?.attributes?.Crescimento?.ExemplosComoConstruir.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-0 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.Crescimento?.ExemplosComoConstruir
                      .length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.Crescimento?.ExemplosComoConstruir
                      .length !== 2 && 'flex-col'
                  } flex  gap-9`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                    {uses?.Descricao}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Crescimento?.TextoQuantidade}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Crescimento?.DescricaoQuantidade}
        </p>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[4]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[4] = ref)}
              muted
              className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
              url={`${brandbook?.attributes?.Crescimento?.MidiaQuantidade?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-10">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Crescimento?.TextoPossibilidadesDeUso}
          </span>
        </div>
        <div
          className={`md:grid-cols-1 sm:grid-cols-1 ${
            brandbook?.attributes?.Crescimento
              ?.PossibilidadesDeUsoImagensGrandes.length === 2 && 'grid-cols-2'
          } ${
            brandbook?.attributes?.Crescimento
              ?.PossibilidadesDeUsoImagensGrandes.length >= 3 && 'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.Crescimento
                   ?.PossibilidadesDeUsoImagensGrandes.length === 1 &&
                 'grid-cols-1'
               } grid md:gap-5 sm:gap-5 gap-9`}
        >
          {brandbook?.attributes?.Crescimento?.PossibilidadesDeUsoImagensGrandes.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-10 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.Crescimento
                      ?.PossibilidadesDeUsoImagensGrandes.length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.Crescimento
                      ?.PossibilidadesDeUsoImagensGrandes.length !== 2 &&
                    'flex-col'
                  } flex  gap-9`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                    {uses?.Descricao}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div
          className={`md:grid-cols-1 sm:grid-cols-1 grid grid-cols-2 gap-9`}
        >
          {brandbook?.attributes?.Crescimento?.PossibilidadesDeUsoImagensMedias.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-8 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <img
                  src={`${uses?.Imagem?.data?.attributes?.url}`}
                  alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  className="md:w-full sm:w-full w-fit"
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                  {uses?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
        <div
          className={`md:grid-cols-1 sm:grid-cols-1 ${
            brandbook?.attributes?.Crescimento
              ?.PossibilidadesDeUsoImagensPequenas.length === 2 && 'grid-cols-2'
          } ${
            brandbook?.attributes?.Crescimento
              ?.PossibilidadesDeUsoImagensPequenas.length >= 3 && 'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.Crescimento
                   ?.PossibilidadesDeUsoImagensPequenas.length === 1 &&
                 'grid-cols-1'
               } grid gap-9`}
        >
          {brandbook?.attributes?.Crescimento?.PossibilidadesDeUsoImagensPequenas.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-8 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.Crescimento
                      ?.PossibilidadesDeUsoImagensPequenas.length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.Crescimento
                      ?.PossibilidadesDeUsoImagensPequenas.length !== 2 &&
                    'flex-col'
                  } flex  gap-9`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                    {uses?.Descricao}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Crescimento?.TextoPossibilidadesDeAplicacao}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {
            brandbook?.attributes?.Crescimento
              ?.DescricaoPossibilidadesDeAplicacao
          }
        </p>
        <div
          className={`md:grid-cols-1 sm:grid-cols-1 ${
            brandbook?.attributes?.Crescimento
              ?.ExemplosPossibilidadesDeAplicacao.length === 2 && 'grid-cols-2'
          } ${
            brandbook?.attributes?.Crescimento
              ?.ExemplosPossibilidadesDeAplicacao.length >= 3 && 'grid-cols-3'
          }
               ${
                 brandbook?.attributes?.Crescimento
                   ?.ExemplosPossibilidadesDeAplicacao.length === 1 &&
                 'grid-cols-1'
               } grid gap-9`}
        >
          {brandbook?.attributes?.Crescimento?.ExemplosPossibilidadesDeAplicacao.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-10 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.Crescimento
                      ?.ExemplosPossibilidadesDeAplicacao.length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.Crescimento
                      ?.ExemplosPossibilidadesDeAplicacao.length !== 2 &&
                    'flex-col'
                  } flex  gap-9`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                    {uses?.DescricaoImagem}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div className="md:mt-10 md:gap-8 sm:gap-6 sm:mt-9 flex flex-col gap-12">
          <img
            className="w-full"
            src={`${brandbook?.attributes?.Crescimento?.PossibilidadesDeAplicacaoImagemGrade?.Imagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Crescimento?.PossibilidadesDeAplicacaoImagemGrade?.Imagem?.data?.attributes?.name}`}
          />
          <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
            {
              brandbook?.attributes?.Crescimento
                ?.PossibilidadesDeAplicacaoImagemGrade?.DescricaoImagem
            }
          </p>
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Crescimento2?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.Crescimento2?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:w-full md:mb-0 sm:mt-0 sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Crescimento2?.Descricao}
        </p>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[5]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[5] = ref)}
              muted
              className="md:w-full sm:w-full sm:mt-1 w-full mt-6 react-player-preview"
              url={`${brandbook?.attributes?.Crescimento2?.Midia?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
        <div className="mt-9 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Crescimento2?.TextoComoConstruir}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Crescimento2?.DescricaoComoConstruir}
        </p>
        <div
          className={`md:grid-cols-1 sm:grid-cols-1 ${
            brandbook?.attributes?.Crescimento2?.ExemplosComoConstruir
              .length === 2 && 'grid-cols-2'
          } ${
            brandbook?.attributes?.Crescimento2?.ExemplosComoConstruir
              .length === 3 && 'grid-cols-3'
          }
          } ${
            brandbook?.attributes?.Crescimento2?.ExemplosComoConstruir.length >=
              4 && 'grid-cols-4'
          }
               ${
                 brandbook?.attributes?.Crescimento2?.ExemplosComoConstruir
                   .length === 1 && 'grid-cols-1'
               } grid md:gap-5 sm:gap-5 gap-9`}
        >
          {brandbook?.attributes?.Crescimento2?.ExemplosComoConstruir.map(
            (uses: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-10 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {uses?.Titulo}
                </h6>
                <div
                  className={`${
                    brandbook?.attributes?.Crescimento2?.ExemplosComoConstruir
                      .length === 2 &&
                    'md:flex-col sm:flex-col flex-row'
                  } 
                  ${
                    brandbook?.attributes?.Crescimento2?.ExemplosComoConstruir
                      .length !== 2 && 'flex-col'
                  } flex  gap-9`}
                >
                  <img
                    src={`${uses?.Imagem?.data?.attributes?.url}`}
                    alt={`${uses?.Imagem?.data?.attributes?.name}`}
                  />
                  <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                    {uses?.DescricaoImagem}
                  </p>
                </div>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Crescimento2?.TextoOqueFazer}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Crescimento2?.DescricaoOqueFazer}
        </p>
        <div
          className={`md:grid-cols-1 sm:grid-cols-1 grid grid-cols-2 gap-9`}
        >
          {brandbook?.attributes?.Crescimento2?.ExemplosOqueFazer.map(
            (ex: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-10 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {ex?.Titulo}
                </h6>
                <img
                  src={`${ex?.Imagem?.data?.attributes?.url}`}
                  alt={`${ex?.Imagem?.data?.attributes?.name}`}
                  className="md:w-full sm:w-full w-fit"
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                  {ex?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Crescimento2?.TextoEspessuras}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Crescimento2?.DescricaoEspessuras}
        </p>
        <div className="flex flex-col gap-12">
          {brandbook?.attributes?.Crescimento2?.ExemplosEspessuras?.map(
            (ex: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-10 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {ex?.Titulo}
                </h6>
                <img
                  src={`${ex?.Imagem?.data?.attributes?.url}`}
                  alt={`${ex?.Imagem?.data?.attributes?.name}`}
                  className="md:w-full sm:w-full w-fit"
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                  {ex?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="mt-8 md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 sm:mt-16">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Crescimento2?.TextoPossibilidadesDeUso}
          </span>
        </div>
        <div
          className={`md:grid-cols-1 sm:gap-10 sm:grid-cols-1 grid grid-cols-3 gap-17`}
        >
          {brandbook?.attributes?.Crescimento2?.ExemplosPossibilidadesDeUso?.map(
            (ex: any, index: number) => (
              <div
                key={index}
                className={`md:mt-10 sm:mt-10 flex flex-col gap-9`}
              >
                <h6 className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] sm:font-bold font-inter font-medium text-hover_ton">
                  {ex?.Titulo}
                </h6>
                <img
                  src={`${ex?.Imagem?.data?.attributes?.url}`}
                  alt={`${ex?.Imagem?.data?.attributes?.name}`}
                  className="md:w-full sm:w-full w-fit"
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:text-xl md:w-full md:mb-0 sm:mt-0 sm:w-full sm:mb-0 text-hover_ton w-full font-inter font-medium whitespace-pre-wrap">
                  {ex?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
      </section>
    </>
  );
};

export default Ton;
