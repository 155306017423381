import React, { useEffect, useState } from 'react';
import { TfiArrowTopRight } from 'react-icons/tfi';
import { useAuthBrandBooks } from '../../../hooks/useAuthBrandBooks';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Ton: React.FC = () => {
  const { authBrandBook, isLoading } = useAuthBrandBooks();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <section id="Inicio" className="sm:gap-10 gap-14 flex flex-col">
        <h3 className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(60px,8.9vw,74px)] font-tonCondensedVF-font sm:w-full sm:tracking-wide text-hover_ton  font-bold">
          {authBrandBook?.attributes?.Inicio?.Titulo}
        </h3>
        <p className="leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.565vw,30px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-hover_ton whitespace-pre-wrap font-inter font-medium">
          {authBrandBook?.attributes?.Inicio?.Introducao}
        </p>
        <img
          className="w-fit"
          src={`${authBrandBook?.attributes?.Inicio?.Imagem?.data?.attributes?.url}`}
          alt={`${authBrandBook?.attributes?.Inicio?.Imagem?.data?.attributes?.name}`}
        />
        <p className="text-[clamp(75px,4.45vw,85px)] leading-[clamp(75px,4.45vw,85px)] md:leading-[clamp(60px,10vw,75px)] md:text-[clamp(30px,8.7vw,72px)] sm:leading-[clamp(50px,16.28vw,70px)] sm:text-[clamp(50px,14.45vw,62px)] w-full text-hover_ton font-tonCondensedVF-font whitespace-pre-wrap font-medium">
          {authBrandBook?.attributes?.Inicio?.Descricao}
        </p>
        <div className="sm:items-baseline flex flex-col w-full items-end">
          <div className="sm:w-11/12 md:h-72 md:px-8 md:py-4 md:w-500 sm:px-9 sm:h-fit sm:p-8 bg-ton_secondary w-[clamp(704px,49.5vw,950px)] h-44 flex flex-col items-center justify-center pl-8 rounded-tl-none rounded-4xl">
            <span className="text-[clamp(45px,3vw,57px)] leading-[clamp(48px,3.18vw,61px)] md:leading-[clamp(50px,7.2vw,59px)] md:text-[clamp(30px,5.4vw,45px)] sm:leading-[clamp(50px,14.2vw,61px)] sm:text-[clamp(30px,9.5vw,40px)] font-tonCondensedVF-font font-normal text-hover_ton">
              {authBrandBook?.attributes?.Inicio?.Frase}
            </span>
          </div>
        </div>
      </section>
      <section
        id={authBrandBook?.attributes?.Personalidade?.Titulo}
        className="md:gap-24 sm:gap-10 flex flex-col gap-22 border-t-2 border-t-hover_ton mt-20"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:h-14 sm:w-full sm:tracking-wide text-hover_ton font-tonCondensedVF-font h-24">
          {authBrandBook?.attributes?.Personalidade?.Titulo}
        </h4>
        <div className="flex flex-col gap-28 md:gap-20">
          {authBrandBook?.attributes?.Personalidade?.personalidades?.data?.map(
            (persona: any, index: number) => (
              <div key={index} className="flex flex-col gap-14 md:gap-6">
                <h3 className="sm:text-[clamp(50px,17vw,73px)] sm:leading-[clamp(70px,21vw,89px)] md:text-[clamp(50px,11vw,90px)] md:leading-[clamp(70px,12vw,95px)] text-[clamp(110px,7.5vw,140px)] leading-[clamp(130px,8vw,153px)] font-tonCondensedVF-font sm:w-full hyphens-auto sm:tracking-wide text-hover_ton font-bold">
                  {persona?.attributes?.Titulo}
                </h3>
                <p className="sm:text-[clamp(36px,10.25vw,44px)] sm:leading-[clamp(40px,13vw,52px)] md:text-[clamp(42px,6.25vw,52px)] md:leading-[clamp(48px,7.4vw,61px)] text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,3.65vw,70px)] font-tonCondensedVF-font text-hover_ton">
                  {persona?.attributes?.TextoParenteses}
                </p>
                {persona?.attributes?.Frases?.map(
                  (frase: any, index: number) => (
                    <div key={index} className="flex flex-col gap-7">
                      <span className="md:text-[clamp(20px,5.76vw,26px)] sm:text-[clamp(16px,5.2vw,22px)] sm:leading-[clamp(22px,7vw,30px)] md:leading-[clamp(50px,16vw,65px)] text-[clamp(30px,3.65vw,41px)] leading-[clamp(41px,3.65vw,50px)] font-inter font-bold text-hover_ton">
                        {frase?.Titulo}
                      </span>
                      <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] font-inter font-medium text-hover_ton whitespace-pre-wrap">
                        {frase?.Frases}
                      </p>
                    </div>
                  ),
                )}
                <div className="sm:gap-6 flex flex-col gap-8">
                  <div className="text-[clamp(28px,1.25vw,40px)] leading-[clamp(30px,2.4vw,50px)] sm:leading-[clamp(16px,11.7vw,50px)] sm:text-[clamp(18px,5.6vw,24px)] md:leading-[clamp(22px,5.9vw,50px)] md:text-[clamp(24px,3.9vw,32px)] sm:items-start sm:pl-6 sm:w-full w-96 h-20 flex flex-col items-center justify-center text-hover_ton font-medium font-inter whitespace-pre-wrap bg-ton_secondary rounded-bl-none rounded-2xl">
                    {persona?.attributes?.ExemploDestacado?.Titulo}
                  </div>
                  <div className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:px-7 px-14 py-10 flex flex-col items-center justify-center w-fit h-fit text-hover_ton font-medium font-inter bg-ton_secondary rounded-tl-none rounded-4xl whitespace-pre-wrap">
                    {persona?.attributes?.ExemploDestacado?.Exemplo}
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id={authBrandBook?.attributes?.MeuTon?.Titulo}
        className="md:gap-8 sm:gap-14 sm:gap-10 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-20"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:h-14 sm:w-full sm:tracking-wide text-hover_ton font-tonCondensedVF-font h-24">
          {authBrandBook?.attributes?.MeuTon?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] font-medium font-inter text-hover_ton whitespace-pre-wrap md:mt-14 sm:mt-6">
          {authBrandBook?.attributes?.MeuTon?.Frases}
        </p>

        <div className="md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2">
          <TfiArrowTopRight className="md:mt-[0.6rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {authBrandBook?.attributes?.MeuTon?.DicasParaEscreverTexto}
          </span>
        </div>
        <div className="md:grid-cols-1 sm:mb-0 sm:gap-14 sm:grid-cols-1 grid grid-cols-3 gap-20">
          {authBrandBook?.attributes?.MeuTon?.DicasExemplo?.map(
            (dicas: any, index: number) => (
              <div key={index} className="sm:gap-5 flex flex-col gap-8">
                <span className="text-[clamp(26px,1.85vw,35px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,22px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-inter font-medium w-full">
                  {dicas?.Titulo}
                </span>
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] text-hover_ton font-inter font-medium">
                  {dicas?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>

        <div className="md:mb-6 sm:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2">
          <TfiArrowTopRight className="md:mt-[0.6rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {authBrandBook?.attributes?.MeuTon?.ExemplosTexto}
          </span>
        </div>
        <div className="md:gap-16 sm:gap-10 flex flex-col gap-14 ">
          {authBrandBook?.attributes?.MeuTon?.Exemplos?.map(
            (exemplo: any, index: number) => (
              <div
                key={index}
                className="border-t-2 border-t-hover_ton flex flex-col gap-12 sm:gap-20"
              >
                <h5 className="md:mt-2 sm:mt-2 text-hover_ton font-inter font-medium text-[clamp(30px,1.57vw,35px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,22px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)]">
                  {exemplo?.Titulo}
                </h5>
                <div className="md:grid-cols-1 sm:grid-cols-1 grid grid-cols-2 gap-12 sm:gap-16">
                  {exemplo?.tom_de_voz_exemplos?.data?.map(
                    (ex: any, idx: number) => (
                      <div key={idx} className="sm:gap-7 flex flex-col gap-3">
                        <span className="text-[clamp(30px,2.65vw,50px)] leading-[clamp(30px,3.7vw,70px)] sm:text-[clamp(18px,9.1vw,39px)] sm:leading-[clamp(14px,13vw,55px)] md:leading-[clamp(22px,6.8vw,56px)] md:text-[clamp(14px,4.8vw,40px)] text-hover_ton font-tonCondensedVF-font font-bold">
                          {ex?.attributes?.Titulo}
                        </span>
                        <p className="md:text-[clamp(37px,10.95vw,47px)] md:leading-[clamp(41px,12.8vw,55px)] text-[clamp(30px,2.65vw,60px)] leading-[clamp(30px,3.7vw,70px)] text-hover_ton font-tonCondensedVF-font">
                          {ex?.attributes?.Conteudo}
                        </p>
                        <span className="text-[clamp(26px,1.85vw,35px)] leading-[clamp(30px,2.7vw,50px)] sm:text-[clamp(18px,6.3vw,27px)] sm:leading-[clamp(20px,9.1vw,39px)] md:leading-[clamp(22px,4.6vw,38px)] md:text-[clamp(14px,3.2vw,26px)] text-hover_ton font-inter font-medium md:max-w-[450px] sm:mt-6 md:mt-9 mt-6">
                          {ex?.attributes?.Subconteudo}
                        </span>
                      </div>
                    ),
                  )}
                </div>
              </div>
            ),
          )}
        </div>
      </section>
    </>
  );
};

export default Ton;
