import React, { useEffect, useRef, useState } from 'react';
import { useBrandBooks } from '../../../hooks/useBrandBooks';
import { TfiArrowTopRight } from 'react-icons/tfi';
import ReactPlayer from 'react-player';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Stone: React.FC = () => {
  const { brandbook, isLoading } = useBrandBooks();
  const [observers, setObservers] = useState<IntersectionObserver[]>([]);
  const videoPlayers = useRef<Array<ReactPlayer | null>>([]);
  const playersContainersRef = useRef<Array<React.RefObject<HTMLDivElement>>>(
    [],
  );
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  useEffect(() => {
    videoPlayers.current = Array.from({ length: 30 }, () => null);
    playersContainersRef.current = Array.from({ length: 30 }, () =>
      React.createRef<HTMLDivElement>(),
    );
    const newObservers = playersContainersRef.current.map(
      (_, index) =>
        new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.seekTo(0);
                  videoPlayers.current[index]?.getInternalPlayer().play();
                }
              } else {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.getInternalPlayer().pause();
                  videoPlayers.current[index]?.seekTo(0);
                }
              }
            });
          },
          { threshold: 0.2 },
        ),
    );

    // Set the state with the initialized arrays
    setObservers(newObservers);

    return () => {
      // Clean up observers when component unmounts
      newObservers.forEach(observer => observer.disconnect());
    };
  }, []);

  useEffect(() => {
    // Observe player containers when isLoading changes
    playersContainersRef.current.forEach((ref, index) => {
      if (ref.current) {
        observers[index].observe(ref.current);
      }
    });
  }, [isLoading]);

  function handleButtonClick(url: any) {
    fetch(url, { mode: 'no-cors' })
      .then(response => response.blob())
      .then(blob => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split('/').pop();
        const anchor = document.createElement('a');
        anchor.href = blobURL;
        anchor.setAttribute('download', fileName);
        document.body.appendChild(anchor);
        anchor.click();
        anchor.remove();
      })
      .catch(error => console.log(error));
  }

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] font-sharonDisplay-font sm:mb-6 sm:w-full sm:tracking-wide text-home_page font-bold"
      >
        {brandbook?.attributes?.Inicio?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.56vw,30px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.65vw,20px)] md:w-full md:mt-4 text-home_page w-full mt-14 mb-16 font-inter whitespace-pre-wrap font-medium">
        {brandbook?.attributes?.Inicio?.Descricao}
      </p>
      <div className="md:w-full sm:w-full w-full">
        <div ref={playersContainersRef.current[0]}>
          <ReactPlayer
            ref={ref => (videoPlayers.current[0] = ref)}
            muted
            className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
            url={`${brandbook?.attributes?.Inicio?.Midia?.data?.attributes?.url}`}
            controls={false}
            height={'100%'}
            width={'100%'}
          />
        </div>
      </div>
      <section
        id={brandbook?.attributes?.DiretrizesdeAnimacao?.Titulo}
        className="sm:gap-8 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,33px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-[4.5rem] md:-mt-3 sm:h-12 font-sharonDisplay-font sm:mt-5 sm:w-full sm:tracking-wide text-home_page h-20 font-light">
          {brandbook?.attributes?.DiretrizesdeAnimacao?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mb-0 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
          {brandbook?.attributes?.DiretrizesdeAnimacao?.Descricao}
        </p>
        <div
          className={`md:gap-12 sm:flex sm:flex-col sm:gap-9 sm:mb-0 grid mb-12 gap-20 ${brandbook?.attributes?.DiretrizesdeAnimacao?.Diretriz?.length ===
            3 && 'grid-cols-3 md:grid-cols-1 md:grid'
            }`}
        >
          {brandbook?.attributes?.DiretrizesdeAnimacao?.Diretriz.map(
            (diretriz: any, index: number) => (
              <div
                key={index}
                className="flex flex-col gap-8 md:gap-0 sm:gap-4"
              >
                <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-home_page font-bold">
                  {diretriz?.Titulo}
                </span>
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] text-home_page">
                  {diretriz?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mb-0 font-inter text-home_page whitespace-pre-wrap sm:mt-2 md:mt-6">
          {brandbook?.attributes?.DiretrizesdeAnimacao?.Observacao}
        </p>
      </section>
      <section
        id={brandbook?.attributes?.Logo?.Titulo}
        className="sm:gap-8 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-14 md:-mt-3 sm:h-18 font-sharonDisplay-font sm:mt-5 sm:h-20 sm:w-full sm:tracking-wide text-home_page h-20 font-light">
          {brandbook?.attributes?.Logo?.Titulo}
        </h4>
        {brandbook?.attributes?.Logo?.Exemplo?.map(
          (logo: any, index: number) => (
            <div key={index} className="sm:gap-4 flex flex-col gap-8 md:gap-0">
              <div className="sm:mt-0 sm:items-end flex flex-row items-center gap-5 mt-10 sm:gap-2 md:gap-2">
                <TfiArrowTopRight color="#00AF55" size={!isMobile ? 30 : 20} />
                <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-white font-inter">
                  {logo?.Titulo}
                </span>
              </div>
              <div className="md:w-full sm:w-full w-full">
                <div ref={playersContainersRef.current[1 + index]}>
                  <ReactPlayer
                    ref={ref => (videoPlayers.current[1 + index] = ref)}
                    muted
                    className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
                    url={`${logo?.Midia?.data?.attributes?.url}`}
                    controls={false}
                    height={'100%'}
                    width={'100%'}
                  />
                </div>
              </div>
              <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:mt-7 sm:mt-3 sm:mb-0 font-inter text-home_page whitespace-pre-wrap">
                {logo?.Descricao}
              </p>
            </div>
          ),
        )}
      </section>
      <section
        id={brandbook?.attributes?.Potencia?.Titulo}
        className="sm:gap-8 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-[5rem] md:mt-5 font-sharonDisplay-font sm:mt-5 sm:h-20 sm:w-full sm:tracking-wide text-home_page h-20 font-light">
          {brandbook?.attributes?.Potencia?.Titulo}
        </h4>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[3]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[3] = ref)}
              muted
              className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
              url={`${brandbook?.attributes?.Potencia?.Midia?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mt-5 font-inter text-home_page whitespace-pre-wrap">
          {brandbook?.attributes?.Potencia?.Descricao}
        </p>
      </section>
      <section
        id={brandbook?.attributes?.Tipografia?.Titulo}
        className="sm:mb-4 sm:gap-8 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-[5rem] md:mt-5 font-sharonDisplay-font sm:mt-5 sm:h-20 sm:w-full sm:tracking-wide text-home_page h-20 font-light">
          {brandbook?.attributes?.Tipografia?.Titulo}
        </h4>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[4]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[4] = ref)}
              muted
              className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
              url={`${brandbook?.attributes?.Tipografia?.Midia?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mt-5 sm:mb-0 font-inter text-home_page whitespace-pre-wrap">
          {brandbook?.attributes?.Tipografia?.Descricao}
        </p>
        {brandbook?.attributes?.Tipografia?.Exemplo.map(
          (exemplo: any, index: number) => (
            <div key={index} className="flex flex-col gap-8 md:gap-0">
              <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
                <TfiArrowTopRight className="md:mt-[0.5rem]" color="#00AF55" size={!isMobile ? 30 : 20} />
                <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-home_page font-medium font-inter">
                  {exemplo?.Titulo}
                </span>
              </div>
              <div className="md:w-full sm:w-full w-full">
                <div ref={playersContainersRef.current[5 + index]}>
                  <ReactPlayer
                    ref={ref => (videoPlayers.current[5 + index] = ref)}
                    muted
                    className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
                    url={`${exemplo?.Midia?.data?.attributes?.url}`}
                    controls={false}
                    height={'100%'}
                    width={'100%'}
                  />
                </div>
              </div>
              <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] mb-6 font-inter text-home_page whitespace-pre-wrap sm:mt-2 md:mt-6">
                {exemplo?.Descricao}
              </p>
              <button
                className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-44 sm:w-44 w-52 h-12 bg-hover_stone text-stone rounded-3xl font-inter font-medium"
                onClick={() => handleButtonClick(exemplo?.DownloadLink)}
              >
                {exemplo?.DownloadTexto}
              </button>
            </div>
          ),
        )}
      </section>
      <section
        id={brandbook?.attributes?.Grafismos?.Titulo}
        className="sm:gap-0 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-14 md:mt-5 sm:h-12 font-sharonDisplay-font sm:mt-5 sm:w-full sm:tracking-wide text-home_page h-20 font-light">
          {brandbook?.attributes?.Grafismos?.Titulo}
        </h4>
        {brandbook?.attributes?.Grafismos?.Exemplo.map(
          (exemplo: any, index: number) => (
            <div key={index} className="sm:gap-4 flex flex-col gap-8 md:gap-0">
              <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
                <TfiArrowTopRight className="md:mt-[0.5rem]" color="#00AF55" size={!isMobile ? 30 : 20} />
                <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-home_page font-medium font-inter">
                  {exemplo?.Titulo}
                </span>
              </div>
              <div className="md:w-full sm:w-full w-full">
                <div ref={playersContainersRef.current[9 + index]}>
                  <ReactPlayer
                    ref={ref => (videoPlayers.current[9 + index] = ref)}
                    muted
                    className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
                    url={`${exemplo?.Midia?.data?.attributes?.url}`}
                    controls={false}
                    height={'100%'}
                    width={'100%'}
                  />
                </div>
              </div>
              <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mb-0 font-inter text-home_page whitespace-pre-wrap sm:mt-2 md:mt-6">
                {exemplo?.Descricao}
              </p>
            </div>
          ),
        )}
      </section>
      <section
        id={brandbook?.attributes?.Transicoes?.Titulo}
        className="sm:gap-8 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-14 md:mt-5 font-sharonDisplay-font sm:h-8 sm:mt-5 sm:w-full sm:tracking-wide text-home_page h-20 font-light">
          {brandbook?.attributes?.Transicoes?.Titulo}
        </h4>
        {brandbook?.attributes?.Transicoes?.Exemplo.map(
          (exemplo: any, index: number) => (
            <div key={index} className="flex flex-col gap-8 md:gap-0">
              <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
                <TfiArrowTopRight className="md:mt-[0.5rem]" color="#00AF55" size={!isMobile ? 30 : 20} />
                <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-home_page font-medium font-inter">
                  {exemplo?.Titulo}
                </span>
              </div>
              <div className="md:w-full sm:w-full w-full">
                <div ref={playersContainersRef.current[13 + index]}>
                  <ReactPlayer
                    ref={ref => (videoPlayers.current[13 + index] = ref)}
                    muted
                    className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
                    url={`${exemplo?.Midia?.data?.attributes?.url}`}
                    controls={false}
                    height={'100%'}
                    width={'100%'}
                  />
                </div>
              </div>
              <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] font-inter text-home_page whitespace-pre-wrap sm:mt-2 md:mt-6">
                {exemplo?.Descricao}
              </p>
            </div>
          ),
        )}
      </section>
      <section
        id={brandbook?.attributes?.HierarquiadeConstrucao?.Titulo}
        className="md:gap-0 sm:gap-9 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-24 md:mt-5 sm:h-20 font-sharonDisplay-font sm:mt-5 sm:w-full sm:tracking-wide text-home_page h-20 font-light">
          {brandbook?.attributes?.HierarquiadeConstrucao?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full sm:mt-4 sm:mb-0 sm:w-full text-home_page mt-14 font-inter whitespace-pre-wrap">
          {brandbook?.attributes?.HierarquiadeConstrucao?.Descricao}
        </p>
        {brandbook?.attributes?.HierarquiadeConstrucao?.Exemplo.map(
          (exemplo: any, index: number) => (
            <div key={index} className="flex flex-col gap-8 md:gap-0">
              <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
                <TfiArrowTopRight className="md:mt-[0.5rem]" color="#00AF55" size={!isMobile ? 30 : 20} />
                <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-home_page font-medium font-inter">
                  {exemplo?.Titulo}
                </span>
              </div>
              <div className="md:w-full sm:w-full w-full">
                <div ref={playersContainersRef.current[15 + index]}>
                  <ReactPlayer
                    ref={ref => (videoPlayers.current[15 + index] = ref)}
                    muted
                    className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
                    url={`${exemplo?.Midia?.data?.attributes?.url}`}
                    controls={false}
                    height={'100%'}
                    width={'100%'}
                  />
                </div>
              </div>
              <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mb-0 sm:mt-2 md:mt-6 font-inter text-home_page whitespace-pre-wrap">
                {exemplo?.Descricao}
              </p>
            </div>
          ),
        )}
      </section>
      <section
        id="donts"
        className="sm:gap-8 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-8 md:mt-5 sm:h-[2rem] font-sharonDisplay-font sm:mt-5 sm:w-full sm:tracking-wide text-home_page h-20 font-light">
          {brandbook?.attributes?.DontsTitulo}
        </h4>
        {brandbook?.attributes?.DontsExemplos?.map(
          (exemplo: any, index: number) => (
            <div
              key={index}
              className="md:gap-10 sm:gap-8 flex flex-col gap-22"
            >
              <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
                <TfiArrowTopRight className="md:mt-[0.5rem]" color="#00AF55" size={!isMobile ? 30 : 20} />
                <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-home_page font-medium font-inter">
                  {exemplo?.Titulo}
                </span>
              </div>
              <div className="md:flex md:flex-col sm:flex sm:flex-col sm:gap-8 flex flex-row gap-4">
                {exemplo?.TituloMidia?.map((TMidia: any, indexes: number) => (
                  <div
                    key={indexes}
                    className="sm:gap-7 flex flex-col gap-8 md:gap-0"
                  >
                    <span className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,22px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(20px,1.565vw,30px)] text-home_page font-bold">
                      {TMidia?.Titulo}
                    </span>
                    <div key={indexes} className="md:w-full sm:w-full w-full">
                      <div
                        ref={
                          playersContainersRef.current[20 + indexes + 2 * index]
                        }
                      >
                        <ReactPlayer
                          ref={ref =>
                          (videoPlayers.current[20 + indexes + 2 * index] =
                            ref)
                          }
                          muted
                          className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
                          url={`${TMidia?.Midia?.data?.attributes?.url}`}
                          controls={false}
                          height={'100%'}
                          width={'100%'}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:font-medium font-inter text-home_page font-medium leading- -mt-5 whitespace-pre-wrap">
                {exemplo?.Descricao}
              </p>
            </div>
          ),
        )}
      </section>
      <section
        id={brandbook?.attributes?.GuiaDeInstalacao?.Titulo}
        className="md:gap-10 sm:gap-8 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(38px,16vw,52px)] md:h-14 md:mt-5 font-sharonDisplay-font sm:leading-1 sm:h-28 sm:mt-5 sm:w-full sm:tracking-wide text-home_page font-light">
          {brandbook?.attributes?.GuiaDeInstalacao?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] font-inter text-home_page whitespace-pre-wrap sm:mt-2 md:mt-6">
          {brandbook?.attributes?.GuiaDeInstalacao?.Descricao}
        </p>
        <div className="md:flex md:flex-col sm:flex sm:flex-col flex flex-row gap-8">
          {brandbook?.attributes?.GuiaDeInstalacao?.TituloDescricao?.map(
            (TituloDesc: any, index: number) => (
              <div key={index} className="flex flex-col gap-8 md:gap-7">
                <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
                <TfiArrowTopRight className="md:mt-[0.5rem]" color="#00AF55" size={!isMobile ? 30 : 20} />
                <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-home_page font-medium font-inter">
                    {TituloDesc?.Titulo}
                  </span>
                </div>
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] font-inter text-home_page whitespace-pre-wrap sm:mt-2 md:mt-6">
                  {TituloDesc?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mb-0 sm:font-medium font-inter text-home_page font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.GuiaDeInstalacao?.Observacao}
        </p>
      </section>
    </>
  );
};

export default Stone;
