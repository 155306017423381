import React from 'react';
import { TfiArrowTopRight } from 'react-icons/tfi';
import { useBrandBooks } from '../../../hooks/useBrandBooks';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Ton: React.FC = () => {
  const { brandbook, isLoading } = useBrandBooks();
  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  React.useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] font-tonCondensedVF-font sm:w-full sm:tracking-wide text-hover_ton font-medium"
      >
        {brandbook?.attributes?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(20px,1.565vw,30px)] md:w-full sm:w-full sm:mt-5 sm:mb-0 text-hover_ton w-full mt-14 font-medium whitespace-pre-wrap font-inter">
        {brandbook?.attributes?.Descricao}
      </p>
      <section
        id={brandbook?.attributes?.Grid?.Titulo}
        className="sm:gap-8 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,33px)] sm:leading-[clamp(45px,8vw,59px)] md:h-14 md:mt-5 md:leading-12 sm:h-12 font-tonCondensedVF-font sm:mt-5 sm:w-full sm:tracking-wide text-hover_ton h-20 font-light">
          {brandbook?.attributes?.Grid?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mb-10 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Grid?.Descricao}
        </p>
        <div
          className={`md:gap-12 sm:flex sm:flex-col sm:gap-9 sm:mb-0 mb-12 gap-20 flex flex-col`}
        >
          {brandbook?.attributes?.Grid?.Exemplo.map(
            (exemplo: any, index: number) => (
              <div key={index} className="flex flex-col gap-7">
                <div className="sm:gap-3 flex flex-row items-end gap-5">
                  <TfiArrowTopRight
                    color="#003C00"
                    size={!isMobile ? 30 : 20}
                  />
                  <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] font-medium text-hover_ton font-inter">
                    {exemplo?.Titulo}
                  </span>
                </div>
                <img
                  src={`${exemplo?.Midia?.data?.attributes?.url}`}
                  alt={`${exemplo?.Midia?.data?.attributes?.name}`}
                />
                {exemplo?.Descricao && (
                  <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:mt-0 sm:-mt-1 sm:w-full w-11/12 text-hover_ton font-medium font-inter">
                    {exemplo?.Descricao}
                  </p>
                )}
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.PrincipiosDesign?.Titulo}
        className="sm:gap-8 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(45px,8vw,59px)] md:leading-[clamp(90px,16vw,130px)] md:h-14 md:-mt-3 font-tonCondensedVF-font sm:mt-5 sm:h-8 sm:w-full sm:tracking-wide text-hover_ton h-20 font-light">
          {brandbook?.attributes?.PrincipiosDesign?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mb-10 sm:font-medium font-medium font-inter text-hover_ton whitespace-pre-wrap">
          {brandbook?.attributes?.PrincipiosDesign?.Descricao}
        </p>
        <div
          className={`md:gap-12 md:grid-cols-1 md:grid sm:grid sm:grid-cols-1 sm:gap-9 sm:mb-0 grid mb-12 gap-13 grid-cols-4`}
        >
          {brandbook?.attributes?.PrincipiosDesign?.BlocoPrincipiosDesign.map(
            (principio: any, index: number) => (
              <div key={index} className="flex flex-col gap-8">
                <span className="md:text-2lxl sm:text-2lxl sm:text-xl text-hover_ton font-inter font-bold text-2.5xl">
                  {principio?.Titulo}
                </span>
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] font-inter font-medium text-hover_ton">
                  {principio?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Layouts?.Titulo}
        className="sm:gap-0 flex flex-col gap-6 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,33px)] sm:leading-[clamp(45px,8vw,59px)] md:h-14 md:mt-5 sm:h-12 font-tonCondensedVF-font sm:mt-5 sm:w-full sm:tracking-wide text-hover_ton h-24 font-light mb-14">
          {brandbook?.attributes?.Layouts?.Titulo}
        </h4>
        <div className="md:w-full sm:w-full w-full">
          <img
            className="w-full"
            src={`${brandbook?.attributes?.Layouts?.PrimeiraUnicaImagem.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Layouts?.PrimeiraUnicaImagem.data?.attributes?.name}`}
          />
        </div>
        <div className="md:flex-row sm:flex sm:flex-col sm:w-full sm:gap-4 sm:mt-4 flex flex-row gap-[2%] md:my-0">
          {brandbook?.attributes?.Layouts?.BlocoFileiraImagens?.data?.map(
            (image: any, index: number) => (
              <img
                key={index}
                src={`${image?.attributes?.url}`}
                alt={`${image?.attributes?.name}`}
                className={`${
                  index === 1
                    ? 'sm:w-full w-[38%] sm:mt-0'
                    : 'w-[60%] sm:w-full'
                }`}
              />
            ),
          )}
        </div>
        <div className="md:w-full sm:gap-4 sm:w-full flex flex-col gap-6 w-full sm:mt-4">
          {brandbook?.attributes?.Layouts?.DuplaColunaImagens?.data?.map(
            (image: any, index: number) => (
              <img
                key={index}
                src={`${image?.attributes?.url}`}
                alt={`${image?.attributes?.name}`}
              />
            ),
          )}
        </div>
        <div className="md:flex-row sm:flex sm:gap-4 sm:mt-4 sm:mb-0 sm:flex-col sm:w-full flex flex-row gap-[2%] md:gap-6 md:my-0 sm:flex-wrap">
          {brandbook?.attributes?.Layouts?.BlocoFileiraImagens2?.data?.map(
            (image: any, index: number) => (
              <img
                key={index}
                src={`${image?.attributes?.url}`}
                alt={`${image?.attributes?.name}`}
                className={`${
                  index === 0
                    ? 'w-[60%] sm:w-full'
                    : 'sm:w-full w-[38%] sm:mt-0'
                }`}
              />
            ),
          )}
        </div>
        <div className="md:flex md:flex-col md:w-full sm:w-full sm:gap-4 sm:flex sm:flex-col flex gap-[2%] md:gap-6 sm:mt-4 justify-between">
          {brandbook?.attributes?.Layouts?.BlocoFileiraTresImagens?.data?.map(
            (aplicacao: any, index: number) => (
              <img
                key={index}
                className="w-[32%] sm:w-full md:w-full md:mt-0"
                src={`${aplicacao?.attributes?.url}`}
                alt={`${aplicacao?.attributes?.name}`}
              />
            ),
          )}
        </div>
        <div className="md:w-full md:my-0 sm:mt-4 sm:w-full w-full">
          <img
            className="w-full"
            src={`${brandbook?.attributes?.Layouts?.SegundaUnicaImagem.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Layouts?.SegundaUnicaImagem.data?.attributes?.name}`}
          />
        </div>
        <div className="md:flex md:flex-col md:w-full sm:w-full sm:gap-4 sm:mt-4 sm:flex sm:flex-col md:gap-6 flex gap-[2%] justify-between">
          {brandbook?.attributes?.Layouts?.BlocoFileiraTresImagens2?.data?.map(
            (aplicacao: any, index: number) => (
              <img
                className="w-[32%] sm:w-full md:w-full"
                key={index}
                src={`${aplicacao?.attributes?.url}`}
                alt={`${aplicacao?.attributes?.name}`}
              />
            ),
          )}
        </div>
        <div className="md:w-full sm:mt-4 sm:w-full w-full">
          <img
            className="w-full"
            src={`${brandbook?.attributes?.Layouts?.TerceiraUnicaImagem.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Layouts?.TerceiraUnicaImagem.data?.attributes?.name}`}
          />
        </div>
        <div className="md:flex-row sm:flex sm:gap-4 sm:mt-4 sm:flex-col sm:w-full flex md:gap-6 flex-row gap-[2%] md:my-0">
          {brandbook?.attributes?.Layouts?.BlocoFileiraImagens3?.data?.map(
            (image: any, index: number) => (
              <img
                key={index}
                src={`${image?.attributes?.url}`}
                alt={`${image?.attributes?.name}`}
                className={`${
                  index === 1
                    ? 'sm:w-full w-[38%]'
                    : 'w-[60%] sm:w-full'
                }`}
              />
            ),
          )}
        </div>
        <div className="md:w-full sm:w-full w-full sm:mt-4">
          <img
            className="w-full"
            src={`${brandbook?.attributes?.Layouts?.QuartaUnicaImagem.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Layouts?.QuartaUnicaImagem.data?.attributes?.name}`}
          />
        </div>
      </section>
    </>
  );
};

export default Ton;
