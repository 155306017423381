import React from 'react';
import { Skeleton as SkeletonBase } from 'antd';

const SkeletonFooter: React.FC = () => (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <SkeletonBase active paragraph={{rows: 2}} />
      <div className="mt-4 flex w-full justify-end">
        <SkeletonBase.Avatar active style={{ width: "50px", height: "auto", aspectRatio: 1 }}/>
      </div>
    </div>
  );

export default SkeletonFooter;