import React from 'react';
import { TfiArrowTopRight } from 'react-icons/tfi';
import { useAuthBrandBooks } from '../../../hooks/useAuthBrandBooks';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Stone: React.FC = () => {
  const { authBrandBook, isLoading } = useAuthBrandBooks();
  const [isMobile, setIsMobile] = React.useState(
    window.matchMedia('(min-width: 0px) and (max-width: 767px)').matches,
  );
  function handleButtonClick(url: any) {
    fetch(url, { mode: 'no-cors' })
      .then(response => response.blob())
      .then(blob => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split('/').pop();
        const anchor = document.createElement('a');
        anchor.href = blobURL;
        anchor.setAttribute('download', fileName);
        document.body.appendChild(anchor);
        anchor.click();
        anchor.remove();
      })
      .catch(error => console.log(error));
  }

  React.useEffect(() => {
    setIsMobile(
      window.matchMedia('(min-width: 0px) and (max-width: 767px)').matches,
    );
    window.addEventListener('resize', () => {
      setIsMobile(
        window.matchMedia('(min-width: 0px) and (max-width: 767px)').matches,
      );
    });
  }, []);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] font-sharonDisplay-font sm:w-full sm:tracking-wide text-home_page text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] font-bold"
      >
        {authBrandBook?.attributes?.Inicio?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-5 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mt-8 text-home_page w-full mt-14 mb-14 font-inter leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.565vw,30px)] whitespace-pre-wrap">
        {authBrandBook?.attributes?.Inicio?.Descricao}
      </p>
      <button
        className="md:w-44 md:text-xl sm:w-44 sm:text-basexl w-52 h-12 bg-hover_stone text-stone rounded-3xl text-1xl font-inter font-medium"
        onClick={() =>
          handleButtonClick(authBrandBook?.attributes?.Inicio?.DownloadLink)
        }
      >
        {authBrandBook?.attributes?.Inicio?.TextoDownload}
      </button>
      <section
        id={authBrandBook?.attributes?.Manifesto?.Titulo}
        className="sm:gap-5 md:gap-0 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.8vw,74px)] font-sharonDisplay-font sm:mt-1 md:mt-1 sm:w-full sm:tracking-wide text-home_page text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light sm:mb-0 md:mb-7">
          {authBrandBook?.attributes?.Manifesto?.Titulo}
        </h4>
        <div className="md:gap-6 md:flex-col sm:gap-6 flex flex-row gap-36">
          <p className="md:text-xl sm:text-[clamp(14px,4.67vw,20px)] sm:leading-[clamp(18px,7vw,30px)] md:text-[clamp(14px,2.7vw,22px)] md:leading-[clamp(18px,3.6vw,30px)] whitespace-pre-wrap text-home_page text-[clamp(18px,1.25vw,24px)] leading-[clamp(22px,1.57vw,30px)]">
            {authBrandBook?.attributes?.Manifesto?.PrimeiroTexto}
          </p>
          <p className="md:text-xl sm:text-[clamp(14px,4.67vw,20px)] sm:leading-[clamp(18px,7vw,30px)] whitespace-pre-wrap text-home_page text-[clamp(18px,1.25vw,24px)] leading-[clamp(22px,1.57vw,30px)]">
            {authBrandBook?.attributes?.Manifesto?.SegundoTexto}
          </p>
        </div>
      </section>
      <section
        id={authBrandBook?.attributes?.PersonalidadeDeMarca?.Titulo}
        className="sm:gap-5 md:gap-0 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(30px,10vw,46px)] md:leading-[clamp(60px,8.8vw,74px)] font-sharonDisplay-font sm:mt-1 sm:mb-8 md:mt-1 sm:w-full sm:tracking-wide text-home_page text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light md:mb-12">
          {authBrandBook?.attributes?.PersonalidadeDeMarca?.Titulo}
        </h4>
        {authBrandBook?.attributes?.PersonalidadeDeMarca?.personalidade_de_marcas?.data
          .sort((a: any, b: any) => a.id - b.id)
          .map((persomarcas: any, index: number) => (
            <div key={index}>
              <h3 className="sm:text-[clamp(36px,14.45vw,62px)] sm:leading-[clamp(36px,14.45vw,62px)] md:text-[clamp(70px,10.8vw,90px)] md:leading-[clamp(70px,10.79vw,90px)] text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] font-sharonDisplay-font sm:w-full sm:tracking-normal text-home_page font-bold whitespace-pre-wrap">
                {persomarcas?.attributes?.Titulo}
              </h3>
              <p className="sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] sm:mt-10 sm:w-full text-home_page mt-14 mb-14 font-inter whitespace-pre-wrap">
                {persomarcas?.attributes?.Descricao}
              </p>
              <div className="md:mb-6 sm:mb-9 flex flex-row items-center gap-4 mb-16">
                <TfiArrowTopRight className="md:mt-[0.6rem]" color="#00AF55" size={isMobile ? 20 : 30} />
                <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(14px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_stone font-medium font-inter">
                  {persomarcas?.attributes?.ComofuncionaTexto}
                </span>
              </div>
              <div
                className={`md:gap-12 sm:flex sm:flex-col sm:gap-9 grid mb-12 gap-20 ${
                  (persomarcas?.attributes?.ComoFuncionaExemplos?.length ===
                    1 &&
                    'grid-cols-1 md:grid-cols-1 md:grid') ||
                  (persomarcas?.attributes?.ComoFuncionaExemplos?.length ===
                    2 &&
                    'grid-cols-2 md:grid-cols-1 md:grid') ||
                  (persomarcas?.attributes?.ComoFuncionaExemplos?.length ===
                    3 &&
                    'grid-cols-3 md:grid-cols-1 md:grid') ||
                  (persomarcas?.attributes?.ComoFuncionaExemplos?.length ===
                    4 &&
                    'grid-cols-4 md:grid-cols-1 md:grid') ||
                  (persomarcas?.attributes?.ComoFuncionaExemplos?.length > 4 &&
                    'grid-cols-3 md:grid-cols-1 md:grid')
                }`}
              >
                {persomarcas?.attributes?.ComoFuncionaExemplos?.map(
                  (exemplos: any, index: number) => (
                    <div key={index} className="flex flex-col gap-8">
                      <span className="md:text-[clamp(22px,2.9vw,24px)] sm:text-[clamp(18px,5.2vw,22px)] text-home_page font-bold text-[clamp(22px,1.56vw,30px)]">
                        {exemplos?.NumeroDoPassoaPasso}
                      </span>
                      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-home_page text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)]">
                        {exemplos?.Descricao}
                      </p>
                    </div>
                  ),
                )}
              </div>
              <div className="md:mb-12 sm:mb-9 sm:gap-4 flex flex-row items-center gap-4 mb-16">
                <TfiArrowTopRight className="md:mt-[0.6rem]" color="#00AF55" size={isMobile ? 20 : 30} />
                <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(14px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_stone font-medium font-inter">
                  {persomarcas?.attributes?.ExemplosTexto}
                </span>
              </div>
              <div className="md:flex md:flex-col md:gap-16 sm:flex sm:flex-col sm:gap-10 grid mb-14 gap-4 grid-cols-2">
                {persomarcas?.attributes?.Exemplos?.map(
                  (exemplos: any, index: number) => (
                    <div key={index} className="md:gap-12 flex flex-col gap-4">
                      <span className="md:text-[clamp(22px,1.5vw,28px)] sm:text-[clamp(18px,5.2vw,22px)] text-home_page font-bold text-[clamp(22px,1.56vw,30px)]">
                        {exemplos?.Titulo}
                      </span>
                      <img
                        src={`${exemplos?.Imagem?.data?.attributes?.url}`}
                        alt={`${exemplos?.Imagem?.data?.attributes?.name}`}
                      />
                      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-home_page text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)]">
                        {exemplos?.Descricao}
                      </p>
                    </div>
                  ),
                )}
              </div>
              {persomarcas?.attributes?.ContraArgumento?.length > 0 && (
                <>
                  <div className="flex flex-row items-center gap-5 mb-14">
                    <TfiArrowTopRight
                      color="#00AF55"
                      size={isMobile ? 20 : 30}
                    />
                    <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(14px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_stone font-inter">
                      {persomarcas?.attributes?.ContraArgumentoTexto}
                    </span>
                  </div>
                  <div
                    className={`md:flex md:flex-col md:gap-14 sm:gap-8 sm:flex sm:flex-col grid mb-14 gap-20 ${
                      (persomarcas?.attributes?.ContraArgumento?.length === 1 &&
                        'grid-cols-1 md:grid-cols-1 md:grid') ||
                      (persomarcas?.attributes?.ContraArgumento?.length === 2 &&
                        'grid-cols-2 md:grid-cols-1 md:grid') ||
                      (persomarcas?.attributes?.ContraArgumento?.length === 3 &&
                        'grid-cols-3 md:grid-cols-1 md:grid') ||
                      (persomarcas?.attributes?.ContraArgumento?.length === 4 &&
                        'grid-cols-4 md:grid-cols-1 md:grid') ||
                      (persomarcas?.attributes?.ContraArgumento?.length > 4 &&
                        'grid-cols-3 md:grid-cols-1 md:grid')
                    }`}
                  >
                    {persomarcas?.attributes?.ContraArgumento?.map(
                      (exemplos: any, index: number) => (
                        <div key={index} className="flex flex-col gap-8">
                          <span className="md:text-[clamp(22px,2.9vw,24px)] sm:text-[clamp(18px,5.2vw,22px)] text-home_page font-bold text-[clamp(22px,1.56vw,30px)]">
                            {exemplos?.NumeroContraArgumento}
                          </span>
                          <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-home_page font-inter font-medium text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)]">
                            {exemplos?.Descricao}
                          </p>
                        </div>
                      ),
                    )}
                  </div>
                </>
              )}
            </div>
          ))}
      </section>
    </>
  );
};

export default Stone;
