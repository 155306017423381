import React from 'react';
import { useAuthBrandBooks } from '../../../hooks/useAuthBrandBooks';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Ton: React.FC = () => {
  const { authBrandBook, isLoading } = useAuthBrandBooks();

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:w-full sm:tracking-wide text-hover_ton text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] font-bold"
      >
        {authBrandBook?.attributes?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-5 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mt-8 text-hover_ton w-full mt-14 mb-14 font-inter leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.565vw,30px)]">
        {authBrandBook?.attributes?.Descricao}
      </p>
      <section
        id={authBrandBook?.attributes?.Objetivo?.Titulo}
        className="md:gap-6 sm:gap-0 flex flex-col gap-14 border-t-2 border-t-hover_ton"
      >
        <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:mt-1 sm:mb-8 md:mt-1 sm:w-full sm:tracking-wide text-hover_ton text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light">
          {authBrandBook?.attributes?.Objetivo?.Titulo}
        </h4>
        <h3 className="md:leading-[clamp(60px,10vw,78px)] md:text-[clamp(30px,8.28vw,69px)] sm:leading-[clamp(50px,14.45vw,62px)] sm:text-[clamp(50px,14.45vw,62px)] font-tonCondensedVF-font text-hover_ton text-[clamp(102px,5.9vw,112px)] leading-[clamp(92px,5.69vw,108.88px)] font-bold">
          {authBrandBook?.attributes?.Objetivo?.Descricao}
        </h3>
      </section>
      <section
        id={authBrandBook?.attributes?.TituloBeneficios}
        className="flex sm:mt-11 sm:gap-0 flex-col gap-14 border-t-2 border-t-hover_ton mt-20"
      >
        <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:mt-1 sm:mb-8 md:mt-1 sm:w-full sm:tracking-wide text-hover_ton text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light">
          {authBrandBook?.attributes?.TituloBeneficios}
        </h4>
        <div className="md:flex-col md:gap-12 sm:flex-col sm:gap-9 flex flex-row gap-32">
          {authBrandBook?.attributes?.Beneficios?.map(
            (beneficio: any, index: number) => (
              <div
                key={index}
                className="md:w-full sm:w-full sm:w-full flex flex-col gap-8 w-5/12"
              >
                <h6 className="md:text-3.5xl sm:text-[clamp(20px,5.55vw,22px)] sm:leading-[clamp(30px,11.7vw,50px)] font-inter text-hover_ton text-[clamp(20px,1.57vw,30px)] leading-[clamp(30px,2.75vw,50px)] font-bold">
                  {beneficio?.Titulo}
                  {/* {beneficio?.Titulo.split(/\/|-/)[1]} */}
                </h6>
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] font-inter text-hover_ton text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)]">
                  {beneficio?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id={authBrandBook?.attributes?.Arquetipo?.TituloPrincipal}
        className="md:gap-8 sm:gap-0 sm:mt-11 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-20"
      >
        <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:mt-1 sm:mb-8 md:mt-1 sm:w-full sm:tracking-wide text-hover_ton text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light">
          {authBrandBook?.attributes?.Arquetipo?.TituloPrincipal}
        </h4>
        <h3 className="md:leading-[clamp(60px,10vw,78px)] md:text-[clamp(30px,8.28vw,69px)] sm:leading-[clamp(50px,14.45vw,62px)] sm:text-[clamp(50px,14.45vw,62px)] font-tonCondensedVF-font text-hover_ton text-[clamp(102px,5.9vw,112px)] leading-[clamp(92px,5.69vw,108.88px)] font-bold mb-6">
          {authBrandBook?.attributes?.Arquetipo?.Titulo}
        </h3>
        <h2 className="md:leading-[clamp(30px,5.2vw,42px)] md:text-[clamp(22px,2.9vw,24px)] sm:leading-[clamp(22px,9.35vw,40px)] sm:mb-4 sm:text-[clamp(18px,6.55vw,26px)] sm:w-11/12 font-inter text-hover_ton text-3xl font-bold leading-10">
          {authBrandBook?.attributes?.Arquetipo?.SubTitulo}
        </h2>
        <p className="sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:whitespace-normal md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:whitespace-normal sm:text-xl font-inter text-hover_ton font-normal whitespace-pre-wrap text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)]">
          {authBrandBook?.attributes?.Arquetipo?.Descricao}
        </p>
        <img
          className="sm:mt-0 mt-10"
          src={`${authBrandBook?.attributes?.Arquetipo?.Imagem?.data?.attributes?.url}`}
          alt={`${authBrandBook?.attributes?.Objetivo?.Imagem?.data?.attributes?.name}`}
        />
      </section>

      <section
        id={authBrandBook?.attributes?.TracosDePersonalidadeTitulo}
        className="flex flex-col border-t-2 border-t-hover_ton mt-20"
      >
        <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(30px,10.25vw,44px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:mt-8 sm:mb-8 md:mt-4 sm:w-full sm:tracking-wide text-hover_ton text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light">
          {authBrandBook?.attributes?.TracosDePersonalidadeTitulo}
        </h4>
        <div className="md:flex-col sm:mt-9 flex flex-row mt-20 gap-14">
          {authBrandBook?.attributes?.TracosDePersonalidade.slice(
            0,
            authBrandBook?.attributes?.TracosDePersonalidade.length - 1,
          ).map((Tracos: any, index: number) => (
            <div key={index} className="flex flex-col gap-9 sm:gap-4">
              <h6 className="md:text-3.5xl sm:text-[clamp(20px,5.55vw,22px)] sm:leading-[clamp(30px,11.7vw,50px)] font-inter text-hover_ton text-[clamp(20px,1.57vw,30px)] leading-[clamp(30px,2.75vw,50px)] font-bold">
                {Tracos?.Titulo}
              </h6>
              <p className="sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] font-inter text-hover_ton text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)]">
                {Tracos?.Descricao}
              </p>
            </div>
          ))}
        </div>
        <div className="md:mt-0 sm:mt-4 flex flex-col items-center mt-8">
          <div className="w-full h-12 border-hover_ton border-b-2 border-r-2 border-l-2 rounded-tl-sm rounded-tr-sm rounded-br-lg rounded-bl-lg" />
          <div className="h-11 w-0.5 bg-hover_ton" />

          <div className="sm:gap-0 flex flex-col items-center mt-8">
            <h6 className="sm:text-[clamp(20px,5.55vw,22px)] sm:leading-[clamp(30px,11.7vw,50px)] md:text-3.5xl font-inter text-hover_ton text-[clamp(20px,1.57vw,30px)] leading-[clamp(30px,2.75vw,50px)] font-bold">
              {
                authBrandBook?.attributes?.TracosDePersonalidade[
                  authBrandBook?.attributes?.TracosDePersonalidade.length - 1
                ]?.Titulo
              }
            </h6>
            <p className="sm:-mt-5 font-inter text-hover_ton text-center">
              {authBrandBook?.attributes?.TracosDePersonalidade[
                authBrandBook?.attributes?.TracosDePersonalidade.length - 1
              ]?.Descricao.split('.').map((line: any, index: number) => {
                const trimmedLine = line.trim();
                if (trimmedLine !== '') {
                  return (
                    <span
                      key={index}
                      className="md:text-xl sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] font-inter text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)]"
                    >
                      <br />
                      {trimmedLine}.
                    </span>
                  );
                }
                return null;
              })}
            </p>
          </div>
        </div>
      </section>

      <section
        id={authBrandBook?.attributes?.BrandIdea?.Titulo}
        className="sm:gap-9 flex flex-col gap-14 border-t-2 border-t-hover_ton mt-20"
      >
        <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:mt-1 sm:mb-0 md:mt-1 sm:w-full sm:tracking-wide text-hover_ton text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light">
          {authBrandBook?.attributes?.BrandIdea?.Titulo}
        </h4>
        <h3 className="md:leading-[clamp(60px,10vw,78px)] md:text-[clamp(30px,8.28vw,69px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(40px,5vw,58px)] text-[clamp(102px,5.9vw,112px)] leading-[clamp(92px,5.69vw,108.88px)] font-tonCondensedVF-font text-hover_ton whitespace-pre-wrap font-bold">
          {authBrandBook?.attributes?.BrandIdea?.Subtitulo}
        </h3>
        <div className="md:whitespace-normal sm:leading-8 font-inter text-hover_ton text-1xl leading-10.5 whitespace-pre-wrap">
          <span className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,3.4vw,28px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:block sm:font-bold text-3xl">
            {authBrandBook?.attributes?.BrandIdea?.Descricao?.split('\n')[0]}
          </span>
          <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] w-11.45 text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)]">
            {authBrandBook?.attributes?.BrandIdea?.Descricao.split('\n')[1]}
          </p>
        </div>
      </section>
    </>
  );
};

export default Ton;
