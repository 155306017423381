import { useEffect, useState } from 'react';
import api from '../services/api';

interface Footer {
  attributes: {
    CompanyName: string;
    CNPJ: string;
    Description: string;
    Logo: {
      data: any;
    };
  };
}

export const useFooter = () => {
  const [footer, setFooter] = useState<Footer>();
  const [isLoading, setIsLoading] = useState(false);

  const getFooter = async () => {
    setIsLoading(true);
    const { data } = await api.get('footer?populate=*');
    if (data && data.data) setFooter(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getFooter();
  }, []);

  return { footer, isLoading };
};
