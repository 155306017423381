import React from 'react';
import { useSearchBox, Hits } from 'react-instantsearch-hooks-web';

type SearchBoxProps = {
  brand: string;
  placeholder: string;
  className: string;
};

const SearchBox: React.FC<SearchBoxProps> = ({
  brand,
  placeholder,
  className,
}) => {
  const { query, refine } = useSearchBox({});

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    refine(event.target.value);
  };

  function Hit({ hit }: any) {
    return (
      <div className={`flex flex-col relative z-[49] outline-none`}>
        {hit?._highlightResult?.Titulo?.matchLevel === 'full' && (
          <div
            className={`border-b-0 ${
              brand === 'stone' ? 'border-hover_stone' : 'border-hover_ton'
            }  w-64 px-7 pb-4 sm:w-full outline-none`}
          >
            <div className="border-b-stone border-b-1 flex flex-col gap-2">
              <div className="flex flex-row w-full gap-8">
                <span className="text-small text-stone">{hit?.Seção}</span>
                <span className="text-small text-stone_secondary">
                  {hit?.Subseção}
                </span>
              </div>
              <a href={hit?.Url}>
                {brand === 'stone' ? (
                  <p className={`truncate mb-1 font-medium text-stone`}>
                    {hit?.Titulo}
                  </p>
                ) : (
                  <p className={`truncate mb-1 font-medium text-hover_ton`}>
                    {hit?.Titulo}
                  </p>
                )}
              </a>
            </div>
          </div>
        )}
        {hit?._highlightResult?.Descricao?.matchLevel === 'full' && (
          <div
            className={`border-b-0 ${
              brand === 'stone' ? 'border-hover_stone' : 'border-hover_ton'
            }  w-64 px-7 pb-4 sm:w-full outline-none`}
          >
            <div className="border-b-stone border-b-1 flex flex-col gap-2">
              <div className="flex flex-row w-full gap-8">
                <span className="text-small text-stone">{hit?.Seção}</span>
                <span className="text-small text-stone_secondary">
                  {hit?.Subseção}
                </span>
              </div>
              <a href={hit?.Url}>
                {brand === 'stone' ? (
                  <p className={`truncate mb-1 font-medium text-stone`}>
                    {hit?.Descricao}
                  </p>
                ) : (
                  <p className={`truncate mb-1 font-medium text-hover_ton`}>
                    {hit?.Descricao}
                  </p>
                )}
              </a>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <input
        type="text"
        placeholder={placeholder}
        className={className}
        value={query}
        onChange={handleInputChange}
      />
      {query && <Hits hitComponent={Hit} />}
    </>
  );
};

export default SearchBox;
