import { useEffect, useState } from 'react';
import api from '../services/api';

interface Header {
  attributes: {
    TextoVoltarPagina: string;
    TextoDeBusca: string;
    TextoEntrarLogin: string;
    TextoSairLogin: string;
    Logo: any;
  };
}

export const useHeader = () => {
  const [header, setHeader] = useState<Header | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const getHeader = async () => {
    setIsLoading(true);
    const { data } = await api.get('header?populate=*');
    if (data && data.data) setHeader(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getHeader();
  }, []);

  return { header, isLoading };
};
