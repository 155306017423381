import { useEffect, useState } from 'react';
import api from '../services/api';
import { useLocation } from 'react-router-dom';

interface BrandBook {
  attributes: {
    [x: string]: any;
    Brand: any;
  };
}

export const useBrandBooks = () => {
  const [brandbook, setBrandBook] = useState<BrandBook | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const getBrandBook = async () => {
    const handleApi = (
      brand: string | undefined,
      variant: string | undefined,
    ) => {
      setIsLoading(true);
      const Variants =
        brand === 'stone'
          ? {
              overview:
                'overviews/1?populate=PrimeiraImagem.Imagem,SegundaImagem.Imagem,BlocoDeImagens.BlocoDeImagens ,PrimeiroVideo.Video.,BlocoDeImagens2.BlocoDeImagens ,TerceiraImagem.Imagem,SegundoVideo.Video,QuartaImagem.Imagem,TerceiroVideo.Video,GrandeImagem.GrandeImagem',
              logo: 'logos/1?populate=Potencia.Imagem,Potencia.MidiaDesktop,Potencia.MidiaMobile,Potencia.MidiaTablet,Arejamento.Imagens,Reducoes.reducaos.Reducoes.Imagem,Quandousar.Imagens,Quandousar.quando_usars.QuandoUsar.Imagem,Logotipo.Imagem,Logotipo.MidiaDesktop,Logotipo.MidiaMobile,Logotipo.MidiaTablet',
              grafismo:
                'grafismos/1?populate=Inicio,Overview.Video,Overview.TituloImagem.Imagem,grafismo_secaos.Midias,grafismo_secaos.FormasDeUsoImagensVerticalGrande.Imagem,grafismo_secaos.PossibilidadesDeAplicacaoImagensVertical.Imagem,grafismo_secaos.FormasDeUsoImagensHorizontal.Imagem,grafismo_secaos.FormasDeUsoImagensHorizontalPequena.Imagem,grafismo_secaos.PossibilidadesDeAplicacaoImagemGrande.Imagem,grafismo_secaos.PossibilidadesDeAplicacaoImagensHorizontal.Imagem',
              tipografia:
                'tipografias/1?populate=Overview.Midias,Tipografias.MidiaDesktop,Tipografias.MidiaMobile,Tipografias.MidiaTablet,Tipografias.Pesos.MidiaDesktop,Tipografias.Pesos.MidiaMobile,Tipografias.Pesos.MidiaTablet,Alinhamentos.AlinhamentoTitulosGrafismoImagemHorizontal.Imagem,Alinhamentos.AlinhamentoTitulosGrafismoImagemVertical.Imagem,Alinhamentos.AlinhamentosDeTitulo.Imagem,Alinhamentos.AlinhamentoTitulosGrafismoImagemHorizontal,AlinhamentosAlinhamentoTitulosGrafismoImagemVertical,Alinhamentos.AlinhamentoDeParagrafoExemplo1s.Imagem,Espacamentos.Espacamento.MidiaDesktop,Espacamentos.Espacamento.MidiaMobile,Espacamentos.Espacamento.MidiaTablet,TipografiasEspecificas.Exemplos.Imagem,Usos.MidiaDesktop,Usos.MidiaMobile,Usos.MidiaTablet,Alinhamentos.AlinhamentoDeParagrafoExemplos.Imagem',
              cores:
                'cores/1?populate=SecaoTiposCores.MidiaDesktop,SecaoTiposCores.MidiaMobile,SecaoTiposCores.MidiaTabletPort,Pesodeuso.MidiaDesktop,Pesodeuso.MidiaMobile,Pesodeuso.MidiaTabletPort,Exemplos.Midias,VerdeEndorfina.MidiaDesktop,VerdeEndorfina.MidiaMobile,VerdeEndorfina.MidiaTabletPort,VerdeEndorfina.Video',
              fotografia:
                'fotografias/1?populate=Midia,Secao.Imagem,Secao.ImagemDescricaoExemplo.Imagem,Secao.ImagemDescricaoExemplo2.Imagem,Secao.ImagemDescricaoExemplo3.Imagem,Secao.ImagemDescricaoExemplo4.Imagem,Secao2.PrimeiraImagem,Secao2.PrimeiroBlocoDeImagens,Secao2.SegundaImagem,Secao2.SegundoBlocoDeImagens',
              ilustracoes:
                'ilustracaos/1?populate=Inicio.Midia,PersonagensObjetos.Imagem,PersonagensObjetos.ImagemDescricaoExemplo.Imagem,PersonagensObjetos.ImagemDescricaoExemplo2.Imagem,PersonagensObjetos.ImagemDescricaoExemplo3.Imagem,PersonagensObjetos.ImagemDescricaoExemplo4.Imagem,GuiaDeCor.Midia,Cenarios.Imagem,Cenarios.ImagemDescricaoExemplo.Imagem,Cenarios.ImagemDescricaoExemplo2.Imagem,Cenarios.ImagemDescricaoExemplo3.Imagem,Cenarios.ImagemDescricaoExemplo4.Imagem,Ilustracoes.Midia,Ilustracoes.Ilustracao.PrimeiraMidia,Ilustracoes.Ilustracao.PrimeiroBlocoMidias,Ilustracoes.Ilustracao.SegundaMidia,Ilustracoes.Ilustracao.SegundoBlocoMidias',
              motion:
                'motions/1?populate=Inicio.Midia,DiretrizesdeAnimacao.Diretriz,Logo.Exemplo.Midia,Potencia.Midia,Tipografia.Midia,Tipografia.Exemplo.Midia,GrafismosExemplo.Midia,TransicoesExemplo.Midia,HierarquiadeConstrucao.Exemplo.Midia,DontsExemplos.TituloMidia.Midia,GuiaDeInstalacao.TituloDescricao,Grafismos.Exemplo.Midia,Transicoes.Exemplo.Midia',
              aplicacoes:
                'aplicacaos/1?populate=Grid.Exemplo.Midia,Layouts.PrimeiraUnicaImagem,Layouts.BlocoFileiraImagens,Layouts.DuplaColunaImagens,Layouts.BlocoFileiraImagens2,Layouts.BlocoFileiraTresImagens,Layouts.SegundaUnicaImagem,Layouts.BlocoFileiraTresImagens2,Layouts.TerceiraUnicaImagem,Layouts.BlocoFileiraImagens3,Layouts.QuartaUnicaImagem',
              submarcas:
                'submarcas/1?populate=PrimeiraImagem.Imagem,SegundaImagem.Imagem,BlocoDeImagens.BlocoDeImagens ,PrimeiroVideo.Video.,BlocoDeImagens2.BlocoDeImagens ,TerceiraImagem.Imagem,SegundoVideo.Video,QuartaImagem.Imagem,TerceiroVideo.Video,GrandeImagem.GrandeImagem',
              default: '',
            }
          : {
              overview:
                'overviews/2?populate=PrimeiraImagem.Imagem,SegundaImagem.Imagem,BlocoDeImagens.BlocoDeImagens ,PrimeiroVideo.Video.,BlocoDeImagens2.BlocoDeImagens ,TerceiraImagem.Imagem,SegundoVideo.Video,QuartaImagem.Imagem,TerceiroVideo.Video,GrandeImagem.GrandeImagem',
              logo: 'logos/2?populate=Potencia.Imagem,Potencia.MidiaDesktop,Potencia.MidiaMobile,Potencia.MidiaTablet,Arejamento.Imagens,Reducoes.reducaos.Exemplos.Imagem,Reducoes.reducaos.ImpressoDigital.TituloImagens.Imagens,Quandousar.Imagens,Quandousar.quando_usars.QuandoUsar.Imagem,Logotipo.Imagem,Logotipo.MidiaDesktop,Logotipo.MidiaMobile,Logotipo.MidiaTablet',
              grafismo:
                'grafismos/2?populate=Inicio,Overview.Video,Overview.TonImagens,ConsideracoesGerais.Exemplos.Imagem,ConsideracoesGerais.ImagemRespiroIcone,Expansao.Midia,Expansao.grafismo_elementos.Elementos.Imagem,Expansao.Exemplos.Imagem,Expansao.ImagensRespiroTitulo,Expansao.PossibilidadesDeUsoVerticalGrandeTon.Imagem,Expansao.PossibilidadesDeUsoVerticalMedioTon.Imagem,Expansao.ExemplosPossibilidadesDeAplicacao.Imagem,Expansao.PossibilidadeDeUsoVerticalPequenoTon.Imagem,Expansao.PossibilidadesDeAplicacaoImagemGrade.Imagem,BalaoDeFala.Midia,BalaoDeFala.ExemplosCantosArredondados.Imagem,BalaoDeFala.AplicacaoDeTexto.Imagens,BalaoDeFala.RespiraTituloImagens,BalaoDeFala.ExemplosInteracaoIconeGrafismo.Imagem,BalaoDeFala.PossibilidadesDeUsoImagensGrandes.Imagem,BalaoDeFala.ExemplosPossibilidadesDeAplicacao.Imagem,BalaoDeFala.PossibilidadeDeUsoImagensMedias.Imagem,BalaoDeFala.PossibilidadeDeUsoImagensPequenas.Imagem,BalaoDeFala.PossibilidadesDeAplicacaoImagemGrade.Imagem,Crescimento.Midia,Crescimento.ExemplosComoConstruir.Imagem,Crescimento.MidiaQuantidade,Crescimento.PossibilidadesDeUsoImagensGrandes.Imagem,Crescimento.PossibilidadesDeUsoImagensMedias.Imagem,Crescimento.PossibilidadesDeUsoImagensPequenas.Imagem,Crescimento.ExemplosPossibilidadesDeAplicacao.Imagem,Crescimento.PossibilidadesDeAplicacaoImagemGrade.Imagem,Crescimento2.Midia,Crescimento2.ExemplosComoConstruir.Imagem,Crescimento2.ExemplosOqueFazer.Imagem,Crescimento2.ExemplosEspessuras.Imagem,Crescimento2.ExemplosPossibilidadesDeUso.Imagem',
              personagens:
                'personagems/1?populate=Inicio.Imagem,QuandoUsar.Exemplos.Imagem,Personagens.TituloImagem.Imagem,FormasDeAplicacao.Exemplos.Imagem,FormasDeAplicacao.ExemploAplicacaoNoGrafismos.Imagem,FormasDeAplicacao2.PrimeiraImagem,FormasDeAplicacao2.PrimeiroBlocoDeImagens,FormasDeAplicacao2.SegundoBlocoDeImagens,FormasDeAplicacao2.UltimaImagem',
              tipografia:
                'tipografias/2?populate=Overview.Midias,Tipografias.MidiaDesktop,Tipografias.MidiaMobile,Tipografias.MidiaTablet,Tipografias.Pesos.MidiaDesktop,Tipografias.Pesos.MidiaMobile,Tipografias.Pesos.MidiaTablet,Alinhamentos.AlinhamentosDeTitulo.Imagem,Espacamentos.Espacamento.MidiaDesktop,Espacamentos.Espacamento.MidiaMobile,Espacamentos.Espacamento.MidiaTablet,Usos.MidiaDesktop,Usos.MidiaMobile,Usos.MidiaTablet,Alinhamentos.AlinhamentoDeParagrafoExemplos.Imagem',
              cores:
                'cores/2?populate=SecaoTiposCores.MidiaDesktop,SecaoTiposCores.MidiaMobile,SecaoTiposCores.MidiaTabletPort,Pesodeuso.MidiaDesktop,Pesodeuso.MidiaMobile,Pesodeuso.MidiaTabletPort,Exemplos.Midias',
              fotografia:
                'fotografias/2?populate=Midia,Secao.Imagem,Secao.ImagemDescricaoExemplo.Imagem,Secao.ImagemDescricaoExemplo2.Imagem,Secao.ImagemDescricaoExemplo3.Imagem,Secao.ImagemDescricaoExemplo4.Imagem,Secao2.PrimeiraImagem,Secao2.PrimeiroBlocoDeImagens,Secao2.SegundaImagem,Secao2.SegundoBlocoDeImagens',
              ilustracoes:
                'ilustracaos/2?populate=Inicio.Midia,PersonagensObjetos.Imagem,PersonagensObjetos.ImagemDescricaoExemplo.Imagem,PersonagensObjetos.ImagemDescricaoExemplo2.Imagem,PersonagensObjetos.ImagemDescricaoExemplo3.Imagem,PersonagensObjetos.ImagemDescricaoExemplo4.Imagem,GuiaDeCor.Midia,Cenarios.Imagem,Cenarios.ImagemDescricaoExemplo.Imagem,Cenarios.ImagemDescricaoExemplo2.Imagem,Cenarios.ImagemDescricaoExemplo3.Imagem,Cenarios.ImagemDescricaoExemplo4.Imagem,Ilustracoes.Midia,Ilustracoes.Ilustracao.PrimeiraMidia,Ilustracoes.Ilustracao.PrimeiroBlocoMidias,Ilustracoes.Ilustracao.SegundaMidia,Ilustracoes.Ilustracao.SegundoBlocoMidias',
              motion:
                'motions/2?populate=Inicio.Midia,DiretrizesdeAnimacao.Diretriz,Logo.Exemplo.Midia,Potencia.Midia,Tipografia.Midia,Tipografia.Exemplo.Midia,GrafismosExemplo.Midia,TransicoesExemplo.Midia,HierarquiadeConstrucao.Exemplo.Midia,DontsExemplos.TituloMidia.Midia,GuiaDeInstalacao.TituloDescricao,Grafismos.Exemplo.Midia,Transicoes.Exemplo.Midia',
              aplicacoes:
                'aplicacaos/2?populate=PrincipiosDesign.BlocoPrincipiosDesign,Grid.Exemplo.Midia,Layouts.PrimeiraUnicaImagem,Layouts.BlocoFileiraImagens,Layouts.DuplaColunaImagens,Layouts.BlocoFileiraImagens2,Layouts.BlocoFileiraTresImagens,Layouts.SegundaUnicaImagem,Layouts.BlocoFileiraTresImagens2,Layouts.TerceiraUnicaImagem,Layouts.BlocoFileiraImagens3,Layouts.QuartaUnicaImagem',
              submarcas:
                'submarcas/2?populate=PrimeiraImagem.Imagem,SegundaImagem.Imagem,BlocoDeImagens.BlocoDeImagens ,PrimeiroVideo.Video.,BlocoDeImagens2.BlocoDeImagens ,TerceiraImagem.Imagem,SegundoVideo.Video,QuartaImagem.Imagem,TerceiroVideo.Video,GrandeImagem.GrandeImagem',
              rendaextra:
                'renda-extras/1?populate=Assinaturas.PrimeiraImagem,Assinaturas.BlocoDeImagens,Cores.Midia,Grafismos.PrimeiraImagem,Grafismos.BlocoDeImagens,Grafismos.UltimaImagem,Aplicacoes.Midias',
              default: '',
            };

      return Variants[variant as keyof typeof Variants] || Variants['default'];
    };
    const { data } = await api.get(
      handleApi(
        location.pathname.split('/')[1],
        location.pathname.split('/')[2],
      ),
    );
    if (data && data.data) setBrandBook(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getBrandBook();
  }, []);

  return { brandbook, isLoading };
};
