import React, { useEffect, useRef, useState } from 'react';
import { useBrandBooks } from '../../../hooks/useBrandBooks';
import { TfiArrowTopRight } from 'react-icons/tfi';
import ReactPlayer from 'react-player';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Ton: React.FC = () => {
  const { brandbook, isLoading } = useBrandBooks();
  const [observers, setObservers] = useState<IntersectionObserver[]>([]);
  const videoPlayers = useRef<Array<ReactPlayer | null>>([]);
  const playersContainersRef = useRef<Array<React.RefObject<HTMLDivElement>>>(
    [],
  );
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  useEffect(() => {
    videoPlayers.current = Array.from({ length: 1 }, () => null);
    playersContainersRef.current = Array.from({ length: 1 }, () =>
      React.createRef<HTMLDivElement>(),
    );
    const newObservers = playersContainersRef.current.map(
      (_, index) =>
        new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.seekTo(0);
                  videoPlayers.current[index]?.getInternalPlayer().play();
                }
              } else {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.getInternalPlayer().pause();
                  videoPlayers.current[index]?.seekTo(0);
                }
              }
            });
          },
          { threshold: 0.2 },
        ),
    );

    // Set the state with the initialized arrays
    setObservers(newObservers);

    return () => {
      // Clean up observers when component unmounts
      newObservers.forEach(observer => observer.disconnect());
    };
  }, []);

  useEffect(() => {
    // Observe player containers when isLoading changes
    playersContainersRef.current.forEach((ref, index) => {
      if (ref.current) {
        observers[index].observe(ref.current);
      }
    });
  }, [isLoading]);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <section id="Inicio">
        <h3 className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:w-full sm:tracking-wide text-hover_ton font-bold">
          {brandbook?.attributes?.Inicio?.Titulo}
        </h3>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.56vw,30px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.65vw,20px)] md:w-full md:mt-4 text-hover_ton w-full mt-14 mb-16 font-inter whitespace-pre-wrap font-medium">
          {brandbook?.attributes?.Inicio?.Descricao}
        </p>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[0]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[0] = ref)}
              muted
              className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
              url={`${brandbook?.attributes?.Inicio?.Midia?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
      </section>
      <section
        id={brandbook?.attributes?.PersonagensObjetos?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.PersonagensObjetos?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-8 md:mt-4 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap mb-4">
          {brandbook?.attributes?.PersonagensObjetos?.Descricao}
        </p>
        <img
          src={`${brandbook?.attributes?.PersonagensObjetos?.Imagem.data?.attributes?.url}`}
          alt={`${brandbook?.attributes?.PersonagensObjetos?.Imagem.data?.attributes?.name}`}
        />
        <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.PersonagensObjetos?.TituloExemplo}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-4 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.PersonagensObjetos?.DescricaoExemplo}
        </p>
        <div className="grid grid-cols-1">
          {brandbook?.attributes?.PersonagensObjetos?.ImagemDescricaoExemplo.map(
            (imagemDesc: any, index: number) => (
              <div key={index} className="sm:gap-8 flex flex-col gap-12">
                <img
                  key={index}
                  src={`${imagemDesc?.Imagem.data?.attributes?.url}`}
                  alt={`${imagemDesc?.Imagem.data?.attributes?.name}`}
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-4 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
                  {imagemDesc?.DescricaoImagem}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.PersonagensObjetos?.TituloExemplo2}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-4 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.PersonagensObjetos?.DescricaoExemplo2}
        </p>
        <div className="md:gap-12 sm:gap-8 md:grid-cols-1 sm:grid-cols-1 grid grid-cols-2 gap-2">
          {brandbook?.attributes?.PersonagensObjetos?.ImagemDescricaoExemplo2.map(
            (imagemDesc: any, index: number) => (
              <div
                key={index}
                className="md:gap-8 flex flex-col gap-12"
              >
                <img
                  key={index}
                  src={`${imagemDesc?.Imagem.data?.attributes?.url}`}
                  alt={`${imagemDesc?.Imagem.data?.attributes?.name}`}
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-0 md:mb-0 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
                  {imagemDesc?.DescricaoImagem}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.PersonagensObjetos?.TituloExemplo3}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-4 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.PersonagensObjetos?.DescricaoExemplo3}
        </p>
        <div className="md:gap-12 sm:gap-8 md:grid-cols-1 grid grid-cols-3 gap-8">
          {brandbook?.attributes?.PersonagensObjetos?.ImagemDescricaoExemplo3.map(
            (imagemDesc: any, index: number) => (
              <div
                key={index}
                className="md:gap-8 flex flex-col gap-12"
              >
                <img
                  key={index}
                  src={`${imagemDesc?.Imagem.data?.attributes?.url}`}
                  alt={`${imagemDesc?.Imagem.data?.attributes?.name}`}
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-0 md:mb-0 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
                  {imagemDesc?.DescricaoImagem}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.PersonagensObjetos?.TituloExemplo4}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-4 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.PersonagensObjetos?.DescricaoExemplo4}
        </p>
        <div className="md:gap-12 sm:gap-8 md:grid-cols-1 grid grid-cols-3 gap-8">
          {brandbook?.attributes?.PersonagensObjetos?.ImagemDescricaoExemplo4.map(
            (imagemDesc: any, index: number) => (
              <div
                key={index}
                className="md:gap-8 flex flex-col gap-12"
              >
                <img
                  key={index}
                  src={`${imagemDesc?.Imagem.data?.attributes?.url}`}
                  alt={`${imagemDesc?.Imagem.data?.attributes?.name}`}
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-0 md:mb-0 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
                  {imagemDesc?.DescricaoImagem}
                </p>
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.GuiaDeCor?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.GuiaDeCor?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-8 md:mt-4 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap mb-4">
          {brandbook?.attributes?.GuiaDeCor?.Descricao}
        </p>
        <img
          src={`${brandbook?.attributes?.GuiaDeCor?.Midia.data?.attributes?.url}`}
          alt={`${brandbook?.attributes?.GuiaDeCor?.Midia.data?.attributes?.name}`}
        />
      </section>
      <section
        id={brandbook?.attributes?.Cenarios?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.Cenarios?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-8 md:mt-4 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap mb-4">
          {brandbook?.attributes?.Cenarios?.Descricao}
        </p>
        <img
          src={`${brandbook?.attributes?.Cenarios?.Imagem.data?.attributes?.url}`}
          alt={`${brandbook?.attributes?.Cenarios?.Imagem.data?.attributes?.name}`}
        />
        <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Cenarios?.TituloExemplo}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-4 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Cenarios?.DescricaoExemplo}
        </p>
        <div className="grid grid-cols-1">
          {brandbook?.attributes?.Cenarios?.ImagemDescricaoExemplo.map(
            (imagemDesc: any, index: number) => (
              <div key={index} className="md:gap-8 flex flex-col gap-12">
                <img
                  key={index}
                  src={`${imagemDesc?.Imagem.data?.attributes?.url}`}
                  alt={`${imagemDesc?.Imagem.data?.attributes?.name}`}
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-4 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
                  {imagemDesc?.DescricaoImagem}
                </p>
              </div>
            ),
          )}
        </div>
        <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
          <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
          <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
            {brandbook?.attributes?.Cenarios?.TituloExemplo2}
          </span>
        </div>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-4 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Cenarios?.DescricaoExemplo2}
        </p>
        <div className="md:gap-12 sm:gap-8 md:grid-cols-1 sm:grid-cols-1 grid grid-cols-2 gap-2">
          {brandbook?.attributes?.Cenarios?.ImagemDescricaoExemplo2.map(
            (imagemDesc: any, index: number) => (
              <div
                key={index}
                className="md:gap-8 flex flex-col gap-12"
              >
                <img
                  key={index}
                  src={`${imagemDesc?.Imagem.data?.attributes?.url}`}
                  alt={`${imagemDesc?.Imagem.data?.attributes?.name}`}
                />
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-4 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
                  {imagemDesc?.DescricaoImagem}
                </p>
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Ilustracoes?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {brandbook?.attributes?.Ilustracoes?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-8 md:mt-4 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Ilustracoes?.Descricao}
        </p>
        <img
          src={`${brandbook?.attributes?.Ilustracoes?.Midia.data?.attributes?.url}`}
          alt={`${brandbook?.attributes?.Ilustracoes?.Midia.data?.attributes?.name}`}
        />
        {brandbook?.attributes?.Ilustracoes?.Ilustracao.map(
          (ilustracao: any, index: number) => (
            <div
              key={index}
              className="md:gap-8 flex flex-col gap-14"
            >
              <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
                <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
                <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
                  {ilustracao?.Titulo}
                </span>
              </div>
              <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-4 sm:mt-0 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap">
                {ilustracao?.Descricao}
              </p>
              <div className="flex flex-col sm:gap-2 gap-3">
                <img
                  className="w-full"
                  src={`${ilustracao?.PrimeiraMidia?.data?.attributes?.url}`}
                  alt={`${ilustracao?.PrimeiraMidia?.data?.attributes?.name}`}
                />
                <div className="md:grid-cols-1 sm:grid-cols-1 sm:gap-2 grid grid-cols-2 gap-3">
                  {ilustracao?.PrimeiroBlocoMidias?.data.map(
                    (image: any, index: number) => (
                      <img
                        key={index}
                        src={`${image?.attributes?.url}`}
                        alt={`${image?.attributes?.name}`}
                        className="w-full h-auto"
                      />
                    ),
                  )}
                </div>
                <img
                  className="w-full"
                  src={`${ilustracao?.SegundaMidia?.data?.attributes?.url}`}
                  alt={`${ilustracao?.SegundaMidia?.data?.attributes?.name}`}
                />
                {ilustracao?.SegundoBlocoMidias?.data && (
                  <div className="md:grid-cols-1 sm:grid-cols-1 sm:gap-2 grid grid-cols-2 gap-3">
                    {ilustracao?.SegundoBlocoMidias?.data.map(
                      (image: any, index: number) => (
                        <img
                          key={index}
                          src={`${image?.attributes?.url}`}
                          alt={`${image?.attributes?.name}`}
                          className="w-full h-auto"
                        />
                      ),
                    )}
                  </div>
                )}
              </div>
            </div>
          ),
        )}
      </section>
    </>
  );
};

export default Ton;
