import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuthBrandBooks } from '../../../hooks/useAuthBrandBooks';
import { TfiArrowTopRight } from 'react-icons/tfi';
import { FaPause } from 'react-icons/fa';
import { IoMdPlay } from 'react-icons/io';
import { useAudio } from '../../../hooks/useAudio';
import ReactPlayer from 'react-player';
import styles from '../index.module.css';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Ton: React.FC = () => {
  const { brand } = useParams();
  const location = useLocation();
  const { authBrandBook, isLoading } = useAuthBrandBooks();
  const {
    audioIds,
    changeRange,
    audioPlayers,
    percentageArray,
    progressBars,
    togglePlayPause,
    audioGuideQuantity,
  } = useAudio();
  const navigate = useNavigate();
  const [observers, setObservers] = useState<IntersectionObserver[]>([]);
  const videoPlayers = useRef<Array<ReactPlayer | null>>([]);
  const playersContainersRef = useRef<Array<React.RefObject<HTMLDivElement>>>(
    [],
  );
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  useEffect(() => {
    videoPlayers.current = Array.from({ length: 4 }, () => null);
    playersContainersRef.current = Array.from({ length: 4 }, () =>
      React.createRef<HTMLDivElement>(),
    );
    const newObservers = playersContainersRef.current.map(
      (_, index) =>
        new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.seekTo(0);
                  videoPlayers.current[index]?.getInternalPlayer().play();
                }
              } else {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.getInternalPlayer().pause();
                  videoPlayers.current[index]?.seekTo(0);
                }
              }
            });
          },
          { threshold: 0.2 },
        ),
    );

    // Set the state with the initialized arrays
    setObservers(newObservers);

    return () => {
      // Clean up observers when component unmounts
      newObservers.forEach(observer => observer.disconnect());
    };
  }, []);

  useEffect(() => {
    // Observe player containers when isLoading changes
    playersContainersRef.current.forEach((ref, index) => {
      if (ref.current) {
        observers[index].observe(ref.current);
      }
    });
  }, [isLoading]);

  if (authBrandBook === undefined && !isLoading)
    navigate(`/${brand}/login`, {
      state: { from: location.pathname, id: location.state?.id },
    });

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] font-tonCondensedVF-font sm:w-full sm:tracking-wide text-hover_ton font-bold"
      >
        {authBrandBook?.attributes?.Inicio?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.56vw,30px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.65vw,20px)] md:w-full md:mt-4 text-hover_ton w-full mt-14 mb-16 font-inter whitespace-pre-wrap font-medium">
        {authBrandBook?.attributes?.Inicio?.Descricao}
      </p>
      <div className="md:w-full sm:w-full w-full">
        <div ref={playersContainersRef.current[0]}>
          <ReactPlayer
            ref={ref => (videoPlayers.current[0] = ref)}
            muted
            className="md:w-full sm:mt-1 w-full mt-6 react-player-preview"
            url={`${authBrandBook?.attributes?.Inicio?.Midia?.data?.attributes?.url}`}
            controls={false}
            height={'100%'}
            width={'100%'}
          />
        </div>
      </div>
      <section
        id={authBrandBook?.attributes?.Diretrizes?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {authBrandBook?.attributes?.Diretrizes?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-8 md:mt-4 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap mb-4">
          {authBrandBook?.attributes?.Diretrizes?.Descricao}
        </p>
        <div
          className={`md:gap-12 md:grid-cols-1 md:grid sm:grid-cols-1 sm:grid sm:gap-9 sm:mb-0 grid mb-12 gap-12 ${authBrandBook?.attributes?.SoundUX?.ExemploUX?.length % 3 === 0 &&
            'grid-cols-3 '
            } ${authBrandBook?.attributes?.SoundUX?.ExemploUX?.length % 2 === 0 &&
            'grid-cols-2 '
            }
                ${authBrandBook?.attributes?.SoundUX?.ExemploUX?.length === 1 &&
            'grid-cols-1'
            }
                `}
        >
          {authBrandBook?.attributes?.Diretrizes?.Diretriz.map(
            (diretriz: any, index: number) => (
              <div key={index} className="flex flex-col gap-8">
                <span className="text-[clamp(24px,1.56vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.11vw,22px)] sm:leading-[clamp(12px,5.11vw,22px)] md:leading-[clamp(22px,6vw,50px)] md:text-[clamp(14px,2.88vw,24px)] font-inter text-hover_ton font-bold">
                  {diretriz?.Titulo}
                </span>
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:mb-4 md:mt-0 sm:mb-6 sm:font-medium font-inter text-hover_ton font-normal">
                  {diretriz?.Descricao}
                </p>
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id={authBrandBook?.attributes?.SoundLogo?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {authBrandBook?.attributes?.SoundLogo?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-8 md:mt-4 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap mb-4">
          {authBrandBook?.attributes?.SoundLogo?.Descricao}
        </p>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[1]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[1] = ref)}
              muted
              className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
              url={`${authBrandBook?.attributes?.SoundLogo?.Midia?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
      </section>
      <section
        id={authBrandBook?.attributes?.SoundGuideline?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {authBrandBook?.attributes?.SoundGuideline?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-8 md:mt-4 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap mb-4">
          {authBrandBook?.attributes?.SoundGuideline?.Descricao}
        </p>

        <div
          className={`md:gap-12 sm:flex sm:flex-col sm:gap-9 sm:mb-0 grid mb-12 gap-8 grid-cols-2 md:grid-cols-1 md:grid`}
        >
          {authBrandBook?.attributes?.SoundGuideline?.Exemplo.map(
            (diretriz: any, index: number) => (
              <div key={index} className="flex flex-col gap-8">
                <div className="sm:mt-0 md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
                  <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
                  <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
                    {diretriz?.Titulo}
                  </span>
                </div>
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:mb-4 md:mt-0 sm:mb-6 sm:font-medium font-inter text-hover_ton font-normal">
                  {diretriz?.Descricao}
                </p>
                <div className="flex flex-row gap-4 items-center">
                  <audio
                    ref={audioPlayers[index]}
                    src={`${diretriz?.AudioMidia?.data?.attributes?.url}`}
                  />
                  <button
                    name="audio"
                    className="sm:w-[clamp(45px,17.2vw,72px)] sm:h-[clamp(45px,17.2vw,72px)] md:w-20 md:h-20 w-[clamp(60px,4vw,78px)] h-[clamp(60px,4vw,78px)] min-w-[clamp(60px,4vw,78px)] min-h-[clamp(60px,4vw,78px)] sm:min-w-[clamp(45px,17.2vw,80px)] sm:min-h-[clamp(45px,17.2vw,72px)] bg-hover_stone rounded-full flex items-center justify-center outline-none"
                    onClick={() => togglePlayPause(index + audioGuideQuantity)}
                  >
                    {audioIds.includes(index + audioGuideQuantity) ? (
                      <FaPause className="absolute" color="#fff" size={!isMobile ? 46 : 46} />
                    ) : (
                      <IoMdPlay className="absolute ml-2" color="#fff" size={!isMobile ? 56 : 56} />
                    )}
                  </button>
                  <input
                    ref={progressBars[index]}
                    className={`md:w-[49.5vw] sm:w-full w-[12vw] h-0.5 appearance-none accent-hover_stone [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[25px] [&::-webkit-slider-thumb]:w-[25px] [&::-webkit-slider-thumb]:rounded-full ${styles.rangeInput}`}
                    type="range"
                    max={100}
                    name=""
                    id=""
                    value={percentageArray[index]}
                    onChange={e => changeRange(e, index)}
                  />
                </div>
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id={authBrandBook?.attributes?.SoundUX?.Titulo}
        className="md:gap-4 sm:gap-4 flex flex-col gap-10 border-t-2 border-t-hover_ton mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(60px,8.9vw,74px)] md:mt-5 font-tonCondensedVF-font sm:mt-0 sm:mb-0 sm:w-full sm:tracking-wide text-hover_ton font-light">
          {authBrandBook?.attributes?.SoundUX?.Titulo}
        </h4>
        <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(18px,1.25vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mb-8 md:mt-4 sm:font-medium font-inter text-hover_ton font-medium whitespace-pre-wrap mb-4">
          {authBrandBook?.attributes?.SoundUX?.Descricao}
        </p>
        <div className="md:w-full sm:w-full w-full">
          <div ref={playersContainersRef.current[2]}>
            <ReactPlayer
              ref={ref => (videoPlayers.current[2] = ref)}
              muted
              className="md:w-full sm:mt-1 sm:w-full w-full mt-6 react-player-preview"
              url={`${authBrandBook?.attributes?.SoundUX?.Midia?.data?.attributes?.url}`}
              controls={false}
              height={'100%'}
              width={'100%'}
            />
          </div>
        </div>
        <div
          className={`md:gap-12 md:grid-cols-1 md:grid sm:grid-cols-1 sm:grid sm:gap-9 sm:mb-0 grid mb-12 gap-8 ${authBrandBook?.attributes?.SoundUX?.ExemploUX?.length % 3 === 0 &&
            'grid-cols-3 '
            } ${authBrandBook?.attributes?.SoundUX?.ExemploUX?.length % 2 === 0 &&
            'grid-cols-2 '
            }
                ${authBrandBook?.attributes?.SoundUX?.ExemploUX?.length === 1 &&
            'grid-cols-1'
            }
                `}
        >
          {authBrandBook?.attributes?.SoundUX?.ExemploUX.map(
            (ux: any, index: number) => (
              <div key={index} className="flex flex-col gap-8">
                <div className="md:mt-8 md:mb-3 sm:gap-4 flex flex-row items-center gap-4 mb-2 mt-14">
                  <TfiArrowTopRight className="md:mt-[0.5rem]" color="#003C00" size={!isMobile ? 30 : 20} />
                  <span className="text-[clamp(30px,2.15vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_ton font-medium font-inter">
                    {ux?.Titulo}
                  </span>
                </div>
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:mb-4 md:mt-0 sm:mb-6 sm:font-medium font-inter text-hover_ton font-normal whitespace-pre-wrap">
                  {ux?.Descricao}
                </p>
                <img
                  className="md:w-full sm:w-full w-full"
                  src={`${ux?.Image?.data?.attributes?.url}`}
                  alt={`${ux?.Image?.data?.attributes?.name}`}
                />
                <div className="flex flex-row gap-2 items-center mt-8">
                  <audio
                    ref={audioPlayers[index + audioGuideQuantity]}
                    src={`${ux?.Audio?.data?.attributes?.url}`}
                  />
                  <button
                    name="audio"
                    className="sm:w-[clamp(45px,17.2vw,72px)] sm:h-[clamp(45px,17.2vw,72px)] md:w-20 md:h-20 w-[clamp(60px,4vw,78px)] h-[clamp(60px,4vw,78px)] min-w-[clamp(60px,4vw,78px)] min-h-[clamp(60px,4vw,78px)] sm:min-w-[clamp(45px,17.2vw,80px)] sm:min-h-[clamp(45px,17.2vw,72px)] bg-hover_stone rounded-full flex items-center justify-center outline-none"
                    onClick={() => togglePlayPause(index + audioGuideQuantity)}
                  >
                    {audioIds.includes(index + audioGuideQuantity) ? (
                      <FaPause className="absolute" color="#fff" size={!isMobile ? 46 : 46} />
                    ) : (
                      <IoMdPlay className="absolute ml-2" color="#fff" size={!isMobile ? 56 : 56} />
                    )}
                  </button>
                  <input
                    ref={progressBars[index + audioGuideQuantity]}
                    className={`md:w-[49.5vw] sm:w-full h-0.5 appearance-none accent-hover_stone [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[25px] [&::-webkit-slider-thumb]:w-[25px] [&::-webkit-slider-thumb]:rounded-full ${styles.rangeInput} w-[12vw]`}
                    type="range"
                    name=""
                    id=""
                    value={percentageArray[index + audioGuideQuantity]}
                    onChange={e => changeRange(e, index + audioGuideQuantity)}
                  />
                </div>
              </div>
            ),
          )}
        </div>
      </section>
    </>
  );
};

export default Ton;
