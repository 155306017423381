import React, { useEffect, useRef, useState } from 'react';
import { useBrandBooks } from '../../../hooks/useBrandBooks';
import ReactPlayer from 'react-player';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Stone: React.FC = () => {
  const { brandbook, isLoading } = useBrandBooks();
  const [observers, setObservers] = useState<IntersectionObserver[]>([]);
  const videoPlayers = useRef<Array<ReactPlayer | null>>([]);
  const playersContainersRef = useRef<Array<React.RefObject<HTMLDivElement>>>(
    [],
  );
  const [IS_CELLPHONE, setIsCellphone] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );
  const [IS_TABLET_PORT, setIsTabletPort] = useState(
    window.matchMedia('(min-width: 431px) and (max-width: 767px)').matches,
  );
  const [IS_DESKTOP, setIsDesktop] = useState(
    window.matchMedia('(min-width: 1195px)').matches,
  );

  useEffect(() => {
    setIsCellphone(window.matchMedia('(max-width: 767px)').matches);
    setIsTabletPort(
      window.matchMedia('(min-width: 431px) and (max-width: 1194px)').matches,
    );
    setIsDesktop(window.matchMedia('(min-width: 1195px)').matches);
    window.addEventListener('resize', () => {
      setIsCellphone(window.matchMedia('(max-width: 767px)').matches);
      setIsTabletPort(
        window.matchMedia('(min-width: 431px) and (max-width: 1194px)').matches,
      );
      setIsDesktop(window.matchMedia('(min-width: 1195px)').matches);
    });
  }, []);

  useEffect(() => {
    videoPlayers.current = Array.from({ length: 1 }, () => null);
    playersContainersRef.current = Array.from({ length: 1 }, () =>
      React.createRef<HTMLDivElement>(),
    );
    const newObservers = playersContainersRef.current.map(
      (_, index) =>
        new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.seekTo(0);
                  videoPlayers.current[index]?.getInternalPlayer().play();
                }
              } else {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.getInternalPlayer().pause();
                  videoPlayers.current[index]?.seekTo(0);
                }
              }
            });
          },
          { threshold: 0.2 },
        ),
    );

    // Set the state with the initialized arrays
    setObservers(newObservers);

    return () => {
      // Clean up observers when component unmounts
      newObservers.forEach(observer => observer.disconnect());
    };
  }, []);

  useEffect(() => {
    // Observe player containers when isLoading changes
    playersContainersRef.current.forEach((ref, index) => {
      if (ref.current) {
        observers[index].observe(ref.current);
      }
    });
  }, [isLoading]);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      {brandbook?.attributes && (
        <>
          <section id="Inicio">
            <h3 className="sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] font-sharonDisplay-font sm:w-full sm:tracking-wide text-home_page text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] font-bold">
              {brandbook?.attributes?.Titulo}
            </h3>
            <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-6 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:mt-4 md:mb-0 text-home_page w-full mt-16 mb-14 sm:mb-0 font-inter leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.56vw,30px)] whitespace-pre-wrap">
              {brandbook?.attributes?.Descricao}
            </p>
          </section>
          {brandbook?.attributes?.SecaoTiposCores?.map(
            (cores: any, index: number) => (
              <section
                key={index}
                id={cores?.Titulo}
                className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
              >
                <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(40px,10vw,130px)] md:leading-[clamp(40px,8.87vw,74px)] font-sharonDisplay-font sm:mb-0 md:mt-4 sm:w-full sm:tracking-wide text-home_page text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light">
                  {cores?.Titulo}
                </h4>
                <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:my-0 text-home_page w-full my-0 sm:mb-8 font-inter whitespace-pre-wrap">
                  {cores?.Descricao}
                </p>
                {IS_DESKTOP && (
                  <img
                    src={`${cores?.MidiaDesktop?.data?.attributes?.url}`}
                    alt={`${cores?.MidiaDesktop?.data?.attributes?.name}`}
                  />
                )}
                {IS_CELLPHONE && (
                  <img
                    src={`${cores?.MidiaMobile?.data?.attributes?.url}`}
                    alt={`${cores?.MidiaMobile?.data?.attributes?.name}`}
                  />
                )}
                {IS_TABLET_PORT && (
                  <img
                    src={`${cores?.MidiaTabletPort?.data?.attributes?.url}`}
                    alt={`${cores?.MidiaTabletPort?.data?.attributes?.name}`}
                  />
                )}
              </section>
            ),
          )}
          <section
            id={brandbook?.attributes?.Pesodeuso?.Titulo}
            className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
          >
            <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(40px,8.87vw,74px)] font-sharonDisplay-font sm:mt-1 sm:mb-0 md:mt-1 sm:w-full sm:tracking-wide text-home_page text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light">
              {brandbook?.attributes?.Pesodeuso?.Titulo}
            </h4>
            <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:my-0 text-home_page w-full my-0 sm:mb-8 font-inter whitespace-pre-wrap">
              {brandbook?.attributes?.Pesodeuso?.Descricao}
            </p>
            {IS_CELLPHONE && (
              <img
                src={`${brandbook?.attributes?.Pesodeuso?.MidiaMobile?.data?.attributes?.url}`}
                alt={`${brandbook?.attributes?.Pesodeuso?.MidiaMobile?.data?.attributes?.name}`}
              />
            )}
            {IS_TABLET_PORT && (
              <img
                src={`${brandbook?.attributes?.Pesodeuso?.MidiaTabletPort?.data?.attributes?.url}`}
                alt={`${brandbook?.attributes?.Pesodeuso?.MidiaTabletPort?.data?.attributes?.name}`}
              />
            )}
            {IS_DESKTOP && (
              <img
                src={`${brandbook?.attributes?.Pesodeuso?.MidiaDesktop?.data?.attributes?.url}`}
                alt={`${brandbook?.attributes?.Pesodeuso?.MidiaDesktop?.data?.attributes?.name}`}
              />
            )}
          </section>
          <section
            id={brandbook?.attributes?.Exemplos?.Titulo}
            className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
          >
            <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(40px,8.87vw,74px)] font-sharonDisplay-font sm:mt-1 sm:mb-0 md:mt-1 sm:w-full sm:tracking-wide text-home_page text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light">
              {brandbook?.attributes?.Exemplos?.Titulo}
            </h4>
            <div className="flex flex-col gap-4">
              {brandbook?.attributes?.Exemplos?.Midias?.data?.map(
                (midia: any, index: number) => (
                  <img
                    key={index}
                    className="md:w-full sm:w-full w-full"
                    src={`${midia?.attributes?.url}`}
                    alt={`${midia?.attributes?.name}`}
                  />
                ),
              )}
            </div>
          </section>
          <section
            id={brandbook?.attributes?.VerdeEndorfina?.Titulo}
            className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
          >
            <h4 className="md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(40px,8.87vw,74px)] font-sharonDisplay-font sm:mt-1 sm:mb-0 md:mt-1 sm:w-full sm:tracking-wide text-home_page text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] font-light">
              {brandbook?.attributes?.VerdeEndorfina?.Titulo}
            </h4>
            <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] md:w-full sm:w-full sm:mt-2 sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.25vw,24px)] md:my-0 text-home_page w-full my-0 sm:mb-8 font-inter whitespace-pre-wrap">
              {brandbook?.attributes?.VerdeEndorfina?.Descricao}
            </p>
            <div className="flex flex-col gap-6 sm:gap-4 md:gap-6">
              {IS_CELLPHONE && (
                <img
                  src={`${brandbook?.attributes?.VerdeEndorfina?.MidiaMobile?.data?.attributes?.url}`}
                  alt={`${brandbook?.attributes?.VerdeEndorfina?.MidiaMobile?.data?.attributes?.name}`}
                />
              )}
              {IS_TABLET_PORT && (
                <img
                  src={`${brandbook?.attributes?.VerdeEndorfina?.MidiaTabletPort?.data?.attributes?.url}`}
                  alt={`${brandbook?.attributes?.VerdeEndorfina?.MidiaTabletPort?.data?.attributes?.name}`}
                />
              )}
              {IS_DESKTOP && (
                <img
                  src={`${brandbook?.attributes?.VerdeEndorfina?.MidiaDesktop?.data?.attributes?.url}`}
                  alt={`${brandbook?.attributes?.VerdeEndorfina?.MidiaDesktop?.data?.attributes?.name}`}
                />
              )}
              <div className="md:w-full sm:w-full w-full">
                <div ref={playersContainersRef.current[0]}>
                  <ReactPlayer
                    ref={ref => (videoPlayers.current[0] = ref)}
                    muted
                    className="md:w-full sm:w-full w-full mt-0 react-player-preview"
                    url={`${brandbook?.attributes?.VerdeEndorfina?.Video?.data?.attributes?.url}`}
                    controls={false}
                    height={'100%'}
                    width={'100%'}
                  />
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Stone;
