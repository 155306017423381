import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMenu } from '../../hooks/useMenu';
import { TfiArrowTopRight } from 'react-icons/tfi';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useHeader } from '../../hooks/useHeader';
import Hamburger from '../Hamburger';

const SidebarMenu = ({
  isOpen,
  brand,
  setOpen,
  isHovered,
  setIsHovered,
}: any) => {
  const { menu } = useMenu();
  const location = useLocation();
  const [locationItem, setLocationItem] = useState<string | null>(null);
  const navigate = useNavigate();
  const { header } = useHeader();
  const [isCellPhone, setIsCellPhone] = useState(
    window.matchMedia('(max-width: 1279px)').matches,
  );

  useEffect(() => {
    setIsCellPhone(window.matchMedia('(max-width: 1279px)').matches);
    window.addEventListener('resize', () => {
      setIsCellPhone(window.matchMedia('(max-width: 1279px)').matches);
    });
  }, []);


  useEffect(() => {
    location && setLocationItem(location.pathname.split('/')[2] as any);
  }, []);

  return (
    <div
      className={`z-50 md:flex md:flex-col gap-8 md:absolute bottom-0 md:left-0 md:w-[100vw] top-0 overflow-y-scroll ${brand === 'stone' ? 'md:bg-stone' : 'md:bg-ton'
        } md:pt-12 md:pl-20 sm:pt-14 sm:pl-8 md:text-white md:z-50 md:ease-in-out md:duration-300
      md:flex md:flex-col md:left-0 md:absolute md:w-[100vw] ${brand === 'stone' ? 'md:bg-stone' : 'md:bg-ton'
        } md:pt-12 md:pl-20 sm:pt-14 sm:pl-8 md:text-white md:z-50 md:ease-in-out md:duration-300 ${isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
    >
      <Hamburger open={isOpen} setOpen={setOpen} />
      {isCellPhone && (
        <div>
          <button
            className={`cursor-pointer flex flex-row ${brand === 'stone' ? 'bg-main_color' : 'bg-ton_secondary'
              } h-7 rounded-2xl items-center w-fit mb-7 sm:w-48`}
            onClick={() => navigate('/')}
          >
            <div className="flex flex-row items-center gap-1 pl-3 pr-3">
              <AiOutlineArrowLeft color="#004132" />
              <span className="text-stone font-inter font-medium">
                {header?.attributes?.TextoVoltarPagina}
              </span>
            </div>
          </button>
          <aside
            id="sidemenu"
            className={`flex-col flex-shrink-0 flex gap-5 w-fit pt-6 border-t-2 pb-[50vh] ${brand === 'stone' ? 'border-t-hover_stone' : 'border-t-hover_ton'
              }`}
          >
            {menu?.attributes?.Itens.map((item: any, index: number) => (
              <div key={index}>
                {brand === 'stone' ? (
                  <div
                    key={index}
                    className={`hover:bg-hover_stone cursor-pointer flex flex-row w-fit justify-center items-center border-hover_stone  border-2 rounded-2xl px-2 gap-1 ${locationItem === item?.Url.substring(1)
                        ? 'bg-hover_stone'
                        : 'bg-stone'
                      }`}
                    onClick={() => {
                      navigate(`/${brand}${item?.Url}`, {
                        state: { id: item?.id },
                      });
                    }}
                    onMouseEnter={() => setIsHovered(item?.id)}
                    onMouseLeave={() => setIsHovered('')}
                  >
                    <TfiArrowTopRight
                      color={
                        locationItem === item?.Url.substring(1)
                          ? '#004132'
                          : isHovered === item?.id
                            ? '#004132'
                            : '#00AF55'
                      }
                    />
                    <span
                      className={`font-inter cursor-pointer font-medium ${locationItem === item?.Url.substring(1)
                          ? 'text-stone'
                          : isHovered === item?.id
                            ? 'text-stone'
                            : 'text-hover_stone'
                        }`}
                    >
                      {item?.Name}
                    </span>
                  </div>
                ) : (
                  <div
                    key={index}
                    className={`hover:bg-hover_ton border-hover_ton cursor-pointer flex flex-row w-fit justify-center items-center border-2 rounded-2xl px-2 gap-1 ${locationItem === item?.Url.substring(1)
                        ? 'bg-hover_ton'
                        : 'bg-ton'
                      }`}
                    onClick={() => {
                      navigate(`/${brand}${item?.Url}`, {
                        state: { id: item?.id },
                      });
                    }}
                    onMouseEnter={() => setIsHovered(item?.id)}
                    onMouseLeave={() => setIsHovered('')}
                  >
                    <TfiArrowTopRight
                      color={
                        locationItem === item?.Url.substring(1)
                          ? '#C8FFC8'
                          : isHovered === item?.id
                            ? '#C8FFC8'
                            : '#003C00'
                      }
                    />
                    <span
                      className={`font-inter cursor-pointer font-medium ${locationItem === item?.Url.substring(1)
                          ? 'text-ton'
                          : isHovered === item?.id
                            ? 'text-ton'
                            : 'text-hover_ton'
                        }`}
                    >
                      {item?.Name}
                    </span>
                  </div>
                )}
                {/* {item?.Submenu.map((submenu: any, index: number) => (
                  <div
                    key={`${item?.id}-${index}`}
                    className={`${
                      selectedMenuItemId === item?.id &&
                      location.pathname.split('/')[2] !== 'login'
                        ? 'block'
                        : 'hidden'
                    } cursor-pointer py-1
                    `}
                    onMouseEnter={() => setIsHoveredSubmenu(submenu?.id)}
                    onMouseLeave={() => setIsHoveredSubmenu('')}
                  >
                    <Link to={submenu.Url} smooth duration={1000}>
                      <span
                        className={`font-inter cursor-pointer font-medium ${
                          brand === 'stone'
                            ? 'text-hover_stone'
                            : 'text-hover_ton'
                        } ml-4
                    `}
                      >
                        {submenu.Name}
                      </span>
                    </Link>
                  </div>
                ))} */}
              </div>
            ))}
          </aside>
        </div>
      )}
    </div>
  );
};

export default SidebarMenu;
