import axios from 'axios';

const local = 'http://localhost:1337/api';
const production = process.env.REACT_APP_NEXT_PUBLIC_STRAPI_URL;
let api_url = production;

if (document.location.href.indexOf('localhost') > -1) {
  api_url = local;
} else if (document.location.href.indexOf('127.0.0.1') > -1) {
  api_url = production;
}

const api = axios.create({
  baseURL: api_url,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_NEXT_STRAPI_API_TOKEN}`,
  },
});

export default api;
