import React, { useState, MouseEvent, useEffect } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { TfiArrowTopRight } from 'react-icons/tfi';
import classNames from 'classnames';
import { useHomePage } from '../hooks/useHomePage';
import { useFooter } from '../hooks/useFooter';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import SkeletonHome from '../components/Skeleton/Home';
import '../styles/homepage.css';

function Home() {
  const navigate = useNavigate();
  const { homePage, isLoading } = useHomePage();
  const { footer } = useFooter();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(min-width: 0px) and (max-width: 1279px)').matches,
  );
  const [imageSTONE, setImageSTONE] = useState('');
  const [imageTON, setImageTON] = useState('');
  const [isHoveredSTONE, setIsHoveredStone] = useState(false);
  const [isHoveredTON, setIsHoveredTon] = useState(false);
  const [isHovered, setIsHovered] = useState<boolean[]>([]);
  const [isPlaying, setIsPlaying] = useState<boolean[]>([]);
  const [isReady, setIsReady] = useState<boolean[]>([]);
  const [videoPlayers, setVideoPlayers] = useState<
    Array<React.RefObject<ReactPlayer>>
  >([]);

  useEffect(() => {
    setVideoPlayers(Array.from({ length: 2 }, () => React.createRef()));
  }, []);

  useEffect(() => {
    setIsMobile(
      window.matchMedia('(min-width: 0px) and (max-width: 1279px)').matches,
    );
    if (isMobile) {
      setIsHoveredStone(true);
      setIsHoveredTon(true);
    }
    if (!isMobile) {
      setIsHoveredStone(false);
      setIsHoveredTon(false);
    }
    window.addEventListener('resize', () => {
      setIsMobile(
        window.matchMedia('(min-width: 0px) and (max-width: 1279px)').matches,
      );
    });
  }, []);

  useEffect(() => {
    if (homePage && isHoveredSTONE) {
      setImageSTONE(
        homePage?.attributes?.BrandsImagesUrls?.ImagesUrl.find(
          (imageObject: any) => {
            return imageObject.BrandName === 'STONE';
          },
        ).Images.data[1].attributes.url,
      );
    } else {
      setImageSTONE(
        homePage?.attributes?.BrandsImagesUrls?.ImagesUrl.find(
          (imageObject: any) => {
            return imageObject.BrandName === 'STONE';
          },
        ).Images.data[0].attributes.url,
      );
    }
    if (homePage && isHoveredTON) {
      setImageTON(
        homePage?.attributes?.BrandsImagesUrls?.ImagesUrl.find(
          (imageObject: any) => {
            return imageObject.BrandName === 'TON';
          },
        ).Images.data[1].attributes.url,
      );
    } else {
      setImageTON(
        homePage?.attributes?.BrandsImagesUrls?.ImagesUrl.find(
          (imageObject: any) => {
            return imageObject.BrandName === 'TON';
          },
        ).Images.data[0].attributes.url,
      );
    }
  }, [homePage, isHoveredSTONE, isHoveredTON]);

  const handleMouseEnter = (
    event: MouseEvent<HTMLDivElement>,
    index: number,
  ) => {
    if (videoPlayers[index].current) {
      event.currentTarget.id === 'STONE'
        ? setIsHoveredStone(true)
        : setIsHoveredTon(true);
      if (isReady[index])
        videoPlayers[index].current?.getInternalPlayer()?.play();
      setIsHovered(prevValue => {
        prevValue[index] = true;
        return [...prevValue];
      });
      setIsReady(prevValue => {
        prevValue[index] = false;
        return [...prevValue];
      });
    }
  };

  const handleMouseLeave = (
    event: MouseEvent<HTMLDivElement>,
    index: number,
  ) => {
    if (videoPlayers[index].current) {
      event.currentTarget.id === 'STONE'
        ? setIsHoveredStone(false)
        : setIsHoveredTon(false);
      videoPlayers[index].current?.getInternalPlayer()?.play();
      setIsHovered(prevValue => {
        prevValue[index] = false;
        return [...prevValue];
      });
      setIsReady(prevValue => {
        prevValue[index] = false;
        return [...prevValue];
      });
      setIsPlaying(prevValue => {
        prevValue[index] = false;
        return [...prevValue];
      });
    }
  };

  const regularDivClass = 'border-2 rounded-3xl flex justify-center';
  const hoveredStoneDivClass =
    'md:pt-5 md:py-0 md:h-3/5 bg-hover_stone border-2 border-green-500 rounded-3xl flex justify-center';
  const hoveredTonDivClass =
    'md:pt-5 md:py-0 md:h-3/5 bg-ton_secondary border-2 border-green-500 rounded-3xl flex justify-center';
  const hoveredStoneSmallDivClass =
    'sm:py-1 sm:py-1 md:py-2 sm:items-center duration-[1500ms] ease-[cubic-bezier(.50,0,.42,.68)] bg-stone_secondary sm:w-1/3.3 flex border-green-500 border-2 rounded-4xl py-2 gap-2 pl-2 items-end';
  const hoveredTonSmallDivClass =
    'sm:py-1 sm:py-1 md:py-2 sm:items-center duration-[1500ms] ease-[cubic-bezier(.50,0,.42,.68)] transition-colors bg-ton_secondary sm:w-1/3.3 flex border-green-500 border-2 rounded-4xl py-2 gap-2 pl-2 items-end';

  if (isLoading) return <SkeletonHome />;

  return (
    <div className="sm:h-full h-full overflow-hidden w-screen mx-auto">
      <main className="sm:px-9 h-full w-full md:px-20 bg-home_page pt-5 flex px-[8.75rem] flex-col items-center min-h-screen pb-8">
        {homePage?.attributes && (
          <>
            <div className="w-full border-t-2 border-t-home_page border-b-home_page border-b-2 py-6">
              <h3 className="text-home_page font-interV-font text-3xl">
                {homePage?.attributes?.TitleBrand}
              </h3>
            </div>
            <div
              className={`flex sm:gap-0 sm:flex-col sm:items-start md:flex-col md:items-start py-10 w-full ${
                !isMobile && 'items-end'
              }`}
            >
              <p className="md:text-[clamp(108px,18.5vw,128px)] sm:text-8xl md:w-full sm:w-full text-[clamp(81px,8.45vw,162px)] leading-[clamp(85px,8vw,139px)] md:mr-16 w-2/2.9 mr-0 text-home_page font-my-custom-font whitespace-pre-wrap font-light md:leading-[114px] sm:leading-[clamp(78px,20.5vw,88px)] md:tracking-[1.28px] sm:tracking-[1.8px]">
                {homePage?.attributes?.Title}
              </p>
              <div className="md:mt-12 sm:mt-8 sm:w-full  sm:items-start md:items-start flex gap-4 tracking-normal ml-[52px] sm:ml-0 md:ml-0 sm:gap-7">
                <AiOutlineArrowRight
                  color="#E1FADC"
                  className="text-4xl md:text-7xl sm:text-4xl min-w-[2rem] min-h-[2rem]"
                />
                <p className="sm:text-[clamp(14px,3.6vw,18px)] sm:leading-[clamp(22px,3.6vw,26px)] md:max-w-full sm:max-w-full md:text-xl sm:text-xl sm:w-full font-inter text-home_page max-w-md text-xl opacity-50 md:text-[clamp(16px,3.6vw,20px)] md:leading-[clamp(20px,3.6vw,30px)] md:mt-[1.15rem] sm:mt-0">
                  {homePage?.attributes?.SubTitle}
                </p>
              </div>
            </div>
          </>
        )}

        <div className="sm:flex-col sm:gap-14 flex gap-5 mt-3 sm:pb-0 md:pb-8">
          <div className="sm:w-full md:flex-col md:gap-16 sm:flex-col sm:gap-14 flex flex-row w-full gap-4">
            {homePage?.attributes?.BrandsImagesUrls?.ImagesUrl.map(
              (images: any, indexes: number) => (
                <div
                  key={indexes}
                  className="flex flex-col sm:w-full w-2/4 gap-5 sm:gap-0"
                >
                  <div
                    id={images?.BrandName}
                    className={classNames(
                      'div-brand',
                      'duration-[1500ms] ease-[cubic-bezier(.90,-0.03,.67,.82)]',
                      `${
                        indexes === 1
                          ? 'md:w-28 md:gap-3 sm:gap-2 sm:w-[max(84px,25%)]'
                          : 'md:w-32 sm:w-32'
                      }  sm:w-1/3.3 flex border-2 rounded-4xl py-2 gap-2 pl-2 items-center ${
                        images?.BrandName === 'STONE' ? 'gap-2' : 'gap-2'
                      }`,
                      {
                        [hoveredStoneSmallDivClass]:
                          images?.BrandName === 'STONE'
                            ? isHoveredSTONE
                            : false,
                      },
                      {
                        [hoveredTonSmallDivClass]:
                          images?.BrandName === 'TON' ? isHoveredTON : false,
                      },
                      ' cursor-pointer max-w-min px-5',
                    )}
                    onMouseEnter={(e: MouseEvent<HTMLDivElement>) =>
                      handleMouseEnter(e, indexes)
                    }
                    onMouseLeave={(e: MouseEvent<HTMLDivElement>) =>
                      handleMouseLeave(e, indexes)
                    }
                    onClick={() => navigate(images?.Url)}
                  >
                    <TfiArrowTopRight
                      color={
                        images?.BrandName === 'STONE'
                          ? !isHoveredSTONE
                            ? '#E1FADC'
                            : '#000'
                          : !isHoveredTON
                          ? '#E1FADC'
                          : '#000'
                      }
                      className={`sm:min-h-[clamp(7px,4vw,20px)] sm:min-w-[clamp(7px,4vw,20px)] md:mt-[0.25rem] duration-[1500ms] hover:transition-colors ${
                        images?.BrandName === 'STONE'
                          ? !isHoveredSTONE
                            ? 'ease-[cubic-bezier(.90,-0.03,.67,.82)]'
                            : 'ease-[cubic-bezier(.50,0,.42,.68)]'
                          : !isHoveredTON
                          ? 'ease-[cubic-bezier(.90,-0.03,.67,.82)]'
                          : 'ease-[cubic-bezier(.50,0,.42,.68)]'
                      }`}
                      size={isMobile ? 20 : 20}
                    />
                    <p
                      className={
                        images?.BrandName === 'STONE'
                          ? !isHoveredSTONE
                            ? 'text-home_page text-2xl font-inter font-medium md:text-[19px] md:leading-[20px] sm:text-[16px] sm:leading-[18px] duration-[1500ms] ease-[cubic-bezier(.90,-0.03,.67,.82)]'
                            : 'md:ml-1 sm:ml-0 text-black font-inter text-2xl font-medium md:text-[19px] md:leading-[20px] sm:text-[16px] sm:leading-[18px] duration-[1500ms] ease-[cubic-bezier(.50,0,.42,.68)] transition-colors'
                          : !isHoveredTON
                          ? 'text-home_page text-2xl font-inter font-medium md:text-[19px] md:leading-[20px] sm:text-[16px] sm:leading-[18px] duration-[1500ms] ease-[cubic-bezier(.90,-0.03,.67,.82)]'
                          : 'md:ml-1 sm:ml-0 text-black font-inter text-2xl font-medium md:text-[19px] md:leading-[20px] sm:text-[16px] sm:leading-[18px] duration-[1500ms] ease-[cubic-bezier(.50,0,.42,.68)] transition-colors'
                      }
                    >
                      {images?.BrandName}
                    </p>
                  </div>
                  {isMobile ? (
                    <div
                      id={images?.BrandName}
                      className={classNames(
                        'div-image',
                        regularDivClass,
                        {
                          [hoveredStoneDivClass]:
                            images?.BrandName === 'STONE'
                              ? isHoveredSTONE
                              : false,
                        },
                        {
                          [hoveredTonDivClass]:
                            images?.BrandName === 'TON' ? isHoveredTON : false,
                        },
                        'cursor-pointer sm:mt-5 sm:w-[85vw] sm:h-[min(57vw,245px)] sm:max-w-[672px] md:w-[672px] md:max-w-[672px] md:h-[310px]',
                      )}
                      onMouseEnter={(e: MouseEvent<HTMLDivElement>) =>
                        handleMouseEnter(e, indexes)
                      }
                      onMouseLeave={(e: MouseEvent<HTMLDivElement>) =>
                        handleMouseLeave(e, indexes)
                      }
                      onClick={() => navigate(images?.Url)}
                    >
                      <img
                        src={`${
                          images?.BrandName === 'STONE' ? imageSTONE : imageTON
                        }`}
                        className={classNames(
                          images?.BrandName === 'STONE'
                            ? 'image-stone'
                            : 'image-ton',
                          `sm:absolute ${
                            indexes === 1
                              ? 'sm:-mt-[clamp(35px,18.5vw,90px)] md:-mt-[79px] md:-mb-[33px] sm:max-h-[352px] sm:scale-75 scale-[0.8]'
                              : 'sm:-mt-[clamp(115px,39vw,170px)] md:-mt-[154px] scale-[0.6]'
                          }`,
                        )}
                      />
                    </div>
                  ) : (
                    <div
                      id={images?.BrandName}
                      className={classNames(
                        regularDivClass,
                        {
                          [hoveredStoneDivClass]:
                            images?.BrandName === 'STONE'
                              ? isHoveredSTONE
                              : false,
                        },
                        {
                          [hoveredTonDivClass]:
                            images?.BrandName === 'TON' ? isHoveredTON : false,
                        },
                        'cursor-pointer sm:mt-5 overflow-hidden',
                      )}
                      onMouseEnter={(e: MouseEvent<HTMLDivElement>) =>
                        handleMouseEnter(e, indexes)
                      }
                      onMouseLeave={(e: MouseEvent<HTMLDivElement>) =>
                        handleMouseLeave(e, indexes)
                      }
                      onClick={() => navigate(images?.Url)}
                    >
                      <ReactPlayer
                        ref={videoPlayers[indexes]}
                        className="md:w-full sm:w-full sm:mt-1 w-full react-player-preview"
                        url={`${images?.Video?.data?.attributes?.url}`}
                        height={'100%'}
                        width={'100%'}
                        controls={false}
                        progressInterval={100}
                        onReady={() => {
                          setIsReady(prevValue => {
                            prevValue[indexes] = true;
                            return [...prevValue];
                          });
                          setIsPlaying(prevValue => {
                            prevValue[indexes] = true;
                            return [...prevValue];
                          });
                        }}
                        onProgress={({ played }) => {
                          if (
                            played >= 0.5 &&
                            isHovered[indexes] &&
                            isPlaying[indexes]
                          ) {
                            videoPlayers[indexes].current
                              ?.getInternalPlayer()
                              ?.pause();

                            setIsPlaying(prevValue => {
                              prevValue[indexes] = false;
                              return [...prevValue];
                            });

                            setIsReady(prevValue => {
                              prevValue[indexes] = false;
                              return [...prevValue];
                            });
                          }
                        }}
                        onEnded={() => {
                          setIsReady(prevValue => {
                            prevValue[indexes] = true;
                            return [...prevValue];
                          });
                          setIsPlaying(prevValue => {
                            prevValue[indexes] = true;
                            return [...prevValue];
                          });
                        }}
                        config={{
                          file: {
                            attributes: {
                              muted: true,
                            },
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              ),
            )}
          </div>
        </div>
        <Footer footer={footer} isMobile={isMobile} />
      </main>
    </div>
  );
}

export default Home;
