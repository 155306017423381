import React from 'react';
import './hamburguer.css';
import { useParams } from 'react-router-dom';

const Hamburger = (props: any) => {
  const { brand } = useParams();

  return (
    <div
      className={`min-w-[32px] min-h-[32px] cursor-pointer ${props.open ? 'menu-btn open' : 'menu-btn'} ${
        brand === 'ton' && 'ton'
      }`}
      onClick={() => props.setOpen(!props.open)}
    >
      <div className="menu-btn__burger"></div>
    </div>
  );
};

export default Hamburger;
