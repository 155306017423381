import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMenu } from '../../hooks/useMenu';
import { TfiArrowTopRight } from 'react-icons/tfi';
import { Link } from 'react-scroll';
import { useHeader } from '../../hooks/useHeader';
import './index.css';
import SkeletonMenu from '../Skeleton/Menu';

interface iMenu {
  brand: string | undefined;
  isHovered: string | undefined;
  setIsHovered: React.Dispatch<React.SetStateAction<string>>;
  isHoveredSubmenu: string | undefined;
  setIsHoveredSubmenu: React.Dispatch<React.SetStateAction<string>>;
}

const Menu: React.FC<iMenu> = ({
  brand,
  isHovered,
  setIsHovered,
  setIsHoveredSubmenu,
}: any) => {
  const { menu, isLoading } = useMenu();
  const { header } = useHeader();
  const location = useLocation();
  const [locationItem, setLocationItem] = useState<string | null>(null);
  const selectedMenuItemId = location?.state?.id || 1;
  const navigate = useNavigate();

  useEffect(() => {
    location && setLocationItem(location.pathname.split('/')[2] as any);
  }, [selectedMenuItemId]);

  if (isLoading) return <SkeletonMenu />;
  return (
    <aside
      className={`flex flex-col -mt-20 ${
        brand === 'stone' ? 'gap-8' : 'gap-[0.85rem]'
      }  md:hidden fixed top-[122px] bottom-0 pb-[25vh] overflow-y-scroll`}
    >
      {brand === 'stone' ? (
        <img
          src={`${header?.attributes?.Logo?.data[0]?.attributes?.url}`}
          alt={`${header?.attributes?.Logo?.data[0]?.attributes?.name}`}
          className="w-32 h-9 cursor-pointer"
          onClick={() => navigate('/')}
        />
      ) : (
        <img
          src={`${header?.attributes?.Logo?.data[1]?.attributes?.url}`}
          alt={`${header?.attributes?.Logo?.data[1]?.attributes?.name}`}
          className="w-20 h-14 cursor-pointer"
          onClick={() => navigate('/')}
        />
      )}
      <div
        id="sidemenu"
        className={`flex-col flex-shrink-0 flex gap-5 mr-6 pt-6 border-t-2 pb-32 pr-4 ${
          brand === 'stone' ? 'border-t-hover_stone' : 'border-t-hover_ton'
        }  md:hidden pb-8`}
      >
        {menu?.attributes?.Itens.map((item: any, index: number) => (
          <div key={index}>
            {brand === 'stone' ? (
              <div
                className={`hover:bg-hover_stone border-hover_stone cursor-pointer flex flex-row w-fit justify-center items-center border-2 rounded-2xl px-2 gap-1 ${
                  locationItem === item?.Url.substring(1)
                    ? 'bg-hover_stone'
                    : 'bg-stone'
                }`}
                onClick={() => {
                  navigate(`/${brand}${item?.Url}`, {
                    state: { id: item?.id },
                  });
                }}
                onMouseEnter={() => setIsHovered(item?.id)}
                onMouseLeave={() => setIsHovered('')}
              >
                <TfiArrowTopRight
                  color={
                    locationItem === item?.Url.substring(1)
                      ? '#004132'
                      : isHovered === item?.id
                      ? '#004132'
                      : '#00AF55'
                  }
                />
                <span
                  className={`font-inter cursor-pointer font-medium ${
                    locationItem === item?.Url.substring(1)
                      ? 'text-stone'
                      : isHovered === item?.id
                      ? 'text-stone'
                      : 'text-hover_stone'
                  }`}
                >
                  {item?.Name}
                </span>
              </div>
            ) : (
              <div
                className={`hover:bg-hover_ton border-ton_terciary cursor-pointer flex flex-row w-fit justify-center items-center border-2 rounded-2xl px-2 gap-1 ${
                  locationItem === item?.Url.substring(1)
                    ? 'bg-hover_ton'
                    : 'bg-ton'
                }`}
                onClick={() => {
                  navigate(`/${brand}${item?.Url}`, {
                    state: { id: item?.id },
                  });
                }}
                onMouseEnter={() => setIsHovered(item?.id)}
                onMouseLeave={() => setIsHovered('')}
              >
                <TfiArrowTopRight
                  color={
                    locationItem === item?.Url.substring(1)
                      ? '#C8FFC8'
                      : isHovered === item?.id
                      ? '#C8FFC8'
                      : '#003C00'
                  }
                />
                <span
                  className={`font-inter cursor-pointer font-medium ${
                    locationItem === item?.Url.substring(1)
                      ? 'text-ton'
                      : isHovered === item?.id
                      ? 'text-ton'
                      : 'text-hover_ton'
                  }`}
                >
                  {item?.Name}
                </span>
              </div>
            )}
            {/* O item url do submenu tem que ser igual ao id da ancora do elemento da pagina de destino*/}
            {/* O selectedMenuItemId serve para esconder os demais submenus que nao foram selecionados através da seleção de click de algum dos menus,
            se eu escolher POSICIONAMENTO, apenas os submenus do POSICIONAMENTO irão aparecer*/}
            {item?.Submenu.map((submenu: any, index: number) => (
              <div
                key={`${item?.id}-${index}`}
                className={`${
                  selectedMenuItemId === item?.id &&
                  location.pathname.split('/')[2] !== 'login'
                    ? 'block'
                    : 'hidden'
                } cursor-pointer py-1
                `}
                onMouseEnter={() => setIsHoveredSubmenu(submenu?.id)}
                onMouseLeave={() => setIsHoveredSubmenu('')}
              >
                <Link to={submenu.Url} smooth duration={1000}>
                  <span
                    className={`font-inter cursor-pointer font-medium ${
                      brand === 'stone' ? 'text-hover_stone' : 'text-hover_ton'
                    } ml-4
                    `}
                  >
                    {submenu.Name}
                  </span>
                </Link>
              </div>
            ))}
          </div>
        ))}
      </div>
    </aside>
  );
};

export default Menu;
