import React, { useEffect, useState } from 'react';
import { useBrandBooks } from '../../../hooks/useBrandBooks';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Stone: React.FC = () => {
  const { brandbook, isLoading } = useBrandBooks();
  const [IS_CELLPHONE, setIsCellphone] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );
  const [IS_TABLET_PORT, setIsTabletPort] = useState(
    window.matchMedia('(min-width: 768px) and (max-width: 1279px)').matches,
  );
  const [IS_DESKTOP, setIsDesktop] = useState(
    window.matchMedia('(min-width: 1280px)').matches,
  );

  useEffect(() => {
    setIsCellphone(window.matchMedia('(max-width: 767px)').matches);
    setIsTabletPort(
      window.matchMedia('(min-width: 768px) and (max-width: 1279px)').matches,
    );
    setIsDesktop(window.matchMedia('(min-width: 1280px)').matches);
    window.addEventListener('resize', () => {
      setIsCellphone(window.matchMedia('(max-width: 767px)').matches);
      setIsTabletPort(
        window.matchMedia('(min-width: 768px) and (max-width: 1279px)').matches,
      );
      setIsDesktop(window.matchMedia('(min-width: 1280px)').matches);
    });
  }, []);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] font-sharonDisplay-font sm:w-full sm:tracking-wide text-home_page font-bold"
      >
        {brandbook?.attributes?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] leading-[clamp(22px,2.3vw,44px)] text-[clamp(14px,1.565vw,30px)] md:w-full md:mb-0 md:mt-6 sm:mb-0 sm:mt-5 sm:w-full text-home_page w-full mt-14 mb-14 font-inter whitespace-pre-wrap">
        {brandbook?.attributes?.Descricao}
      </p>
      <section
        id={brandbook?.attributes?.Logotipo?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-16 md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] sm:h-3 font-sharonDisplay-font sm:mt-0 sm:mb-10 sm:w-full sm:tracking-wide text-home_page h-24 font-light">
          {brandbook?.attributes?.Logotipo?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-10 sm:font-medium my-4 font-inter text-home_page font-normal whitespace-pre-wrap">
          {brandbook?.attributes?.Logotipo?.Descricao}
        </p>
        <div className="md:gap-3 sm:gap-2 gap-6 flex flex-col">
          <img
            src={`${brandbook?.attributes?.Logotipo?.Imagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Logotipo?.Imagem?.data?.attributes?.name}`}
          />
          {IS_CELLPHONE && (
            <img
              src={`${brandbook?.attributes?.Logotipo?.MidiaMobile?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Logotipo?.MidiaMobile?.data?.attributes?.name}`}
            />
          )}
          {IS_TABLET_PORT && (
            <img
              src={`${brandbook?.attributes?.Logotipo?.MidiaTablet?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Logotipo?.MidiaTablet?.data?.attributes?.name}`}
            />
          )}
          {IS_DESKTOP && (
            <img
              src={`${brandbook?.attributes?.Logotipo?.MidiaDesktop?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Logotipo?.MidiaDesktop?.data?.attributes?.name}`}
            />
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Potencia?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-20 md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] sm:h-7 font-sharonDisplay-font sm:mt-0 sm:mb-10 sm:w-full sm:tracking-wide text-home_page h-24 font-light">
          {brandbook?.attributes?.Potencia?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:font-medium font-inter text-home_page font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Potencia?.Descricao}
        </p>
        <div className="md:gap-3 sm:gap-2 gap-6 flex flex-col">
          <img
            src={`${brandbook?.attributes?.Potencia?.Imagem?.data?.attributes?.url}`}
            alt={`${brandbook?.attributes?.Potencia?.Imagem?.data?.attributes?.name}`}
          />
          {IS_CELLPHONE && (
            <img
              src={`${brandbook?.attributes?.Potencia?.MidiaMobile?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Potencia?.MidiaMobile?.data?.attributes?.name}`}
            />
          )}
          {IS_TABLET_PORT && (
            <img
              src={`${brandbook?.attributes?.Potencia?.MidiaTablet?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Potencia?.MidiaTablet?.data?.attributes?.name}`}
            />
          )}
          {IS_DESKTOP && (
            <img
              src={`${brandbook?.attributes?.Potencia?.MidiaDesktop?.data?.attributes?.url}`}
              alt={`${brandbook?.attributes?.Potencia?.MidiaDesktop?.data?.attributes?.name}`}
            />
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Arejamento?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-20 md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] sm:h-16 font-sharonDisplay-font sm:mt-0 sm:w-full sm:tracking-wide text-home_page h-24 font-light">
          {brandbook?.attributes?.Arejamento?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:mb-5 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
          {brandbook?.attributes?.Arejamento?.Descricao}
        </p>
        <div className="md:gap-3 sm:gap-3 gap-6 flex flex-col">
          {brandbook?.attributes?.Arejamento?.Imagens?.data.map(
            (imagem: any, index: number) => (
              <div key={index}>
                <img
                  src={`${imagem?.attributes?.url}`}
                  alt={`${imagem?.attributes?.name}`}
                />
              </div>
            ),
          )}
        </div>
      </section>
      <section
        id={brandbook?.attributes?.Reducoes?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-20 md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] font-sharonDisplay-font sm:-mt-2 sm:w-full sm:tracking-wide text-home_page h-24 font-light">
          {brandbook?.attributes?.Reducoes?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
          {brandbook?.attributes?.Reducoes?.Descricao}
        </p>
        {brandbook?.attributes?.Reducoes?.reducaos?.data.map(
          (reduction: any, _: number) =>
            reduction?.attributes?.Reducoes.map((red: any, index: number) => (
              <div
                key={index}
                className="md:flex md:flex-col md:items-start md:w-10/12 md:gap-10 md:text-1xl sm:mb-5 sm:flex sm:flex-col sm:items-baseline sm:w-full sm:gap-8 sm:mt-12 flex flex-row w-11/12 justify-between items-end"
              >
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] md:w-full sm:w-full sm:font-medium font-inter text-home_page font-normal w-2/5 whitespace-pre-wrap">
                  {red?.ReducoesTexto}
                </p>
                <img
                  src={`${red?.Imagem?.data?.attributes?.url}`}
                  alt={`${red?.Imagem?.data?.attributes?.name}`}
                />
              </div>
            )),
        )}
      </section>
      <section
        id={brandbook?.attributes?.Quandousar?.Titulo}
        className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
      >
        <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-16 md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] sm:h-7 font-sharonDisplay-font sm:-mt-2 sm:mb-10 sm:w-full sm:tracking-wide text-home_page h-24 font-light">
          {brandbook?.attributes?.Quandousar?.Titulo}
        </h4>
        <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] sm:font-medium font-inter text-home_page font-medium whitespace-pre-wrap">
          {brandbook?.attributes?.Quandousar?.Descricao}
        </p>
        <div className="md:flex md:flex-col md:mb-0 md:gap-16 sm:flex sm:flex-col sm:mt-5 sm:mb-8 sm:gap-10 grid mb-14 gap-4 grid-cols-2">
          {brandbook?.attributes?.Quandousar?.quando_usars?.data?.map(
            (quandousars: any, _: number) =>
              quandousars?.attributes?.QuandoUsar.map(
                (quandousar: any, index: number) => (
                  <div
                    key={index}
                    className="md:gap-6 sm:gap-7 flex flex-col gap-4"
                  >
                    <span className="text-[clamp(20px,1.57vw,30px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(20px,5.55vw,22px)] sm:leading-[clamp(30px,11.7vw,50px)] md:text-[clamp(18px,5.55vw,24px)] md::leading-[clamp(30px,11.7vw,50px)] md:font-bold sm:font-bold font-inter font-medium text-home_page">
                      {quandousar?.Titulo}
                    </span>
                    <img
                      src={`${quandousar?.Imagem?.data?.attributes?.url}`}
                      alt={`${quandousar?.Imagem?.data?.attributes?.name}`}
                    />
                    <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,4.19vw,18px)] text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,44px)] font-inter font-medium text-home_page">
                      {quandousar?.Descricao}
                    </p>
                  </div>
                ),
              ),
          )}
        </div>
        <div className="sm:gap-2 flex flex-col gap-4">
          {brandbook?.attributes?.Quandousar?.Imagens?.data.map(
            (img: any, index: number) => (
              <img
                key={index}
                src={`${img?.attributes?.url}`}
                alt={`${img?.attributes?.name}`}
              />
            ),
          )}
        </div>
      </section>
    </>
  );
};

export default Stone;
