import React, { useEffect, useState } from 'react';
import { useBrandBooks } from '../../../hooks/useBrandBooks';
import { TfiArrowTopRight } from 'react-icons/tfi';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Stone: React.FC = () => {
  const { brandbook, isLoading } = useBrandBooks();
  const [IS_CELLPHONE, setIsCellphone] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );
  const [IS_TABLET_PORT, setIsTabletPort] = useState(
    window.matchMedia('(min-width: 768px) and (max-width: 1279px)').matches,
  );
  const [IS_DESKTOP, setIsDesktop] = useState(
    window.matchMedia('(min-width: 1280px)').matches,
  );

  useEffect(() => {
    setIsCellphone(window.matchMedia('(max-width: 767px)').matches);
    setIsTabletPort(
      window.matchMedia('(min-width: 768px) and (max-width: 1279px)').matches,
    );
    setIsDesktop(window.matchMedia('(min-width: 1280px)').matches);
    window.addEventListener('resize', () => {
      setIsCellphone(window.matchMedia('(max-width: 767px)').matches);
      setIsTabletPort(
        window.matchMedia('(min-width: 768px) and (max-width: 1279px)').matches,
      );
      setIsDesktop(window.matchMedia('(min-width: 1280px)').matches);
    });
  }, []);

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      {brandbook?.attributes && (
        <>
          <section id="Inicio">
            <h3 className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] font-sharonDisplay-font sm:w-full sm:tracking-wide text-home_page font-bold">
              {brandbook?.attributes?.Titulo}
            </h3>
            <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(20px,1.565vw,30px)] md:w-full md:mb-0 md:mt-6 sm:mb-0 sm:mt-5 sm:w-full text-home_page w-full mt-14 mb-0 font-inter whitespace-pre-wrap">
              {brandbook?.attributes?.Descricao}
            </p>
          </section>
          <section
            id={brandbook?.attributes?.Overview?.Titulo}
            className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
          >
            <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-16 md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] sm:h-3 font-sharonDisplay-font sm:mt-0 sm:mb-14 sm:w-full sm:tracking-wide text-home_page h-24 font-light">
              {brandbook?.attributes?.Overview?.Titulo}
            </h4>
            <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mb-10 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
              {brandbook?.attributes?.Overview?.Descricao}
            </p>
            <div className="flex flex-col gap-2">
              {brandbook?.attributes?.Overview?.Midias?.data.map(
                (midia: any, index: number) => (
                  <img
                    key={index}
                    src={`${midia?.attributes?.url}`}
                    alt={`${midia?.attributes?.name}`}
                  />
                ),
              )}
            </div>
          </section>
          {brandbook?.attributes?.Tipografias.map(
            (tipografia: any, index: number) => (
              <section
                id={tipografia?.Titulo}
                key={index}
                className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
              >
                <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-16 md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(32px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] sm:h-3 font-sharonDisplay-font sm:mt-0 sm:mb-6 sm:w-full sm:tracking-wide text-home_page h-24 font-light">
                  {tipografia?.Titulo}
                </h4>
                {tipografia?.SubTitulo && (
                  <h3
                    className={`${
                      (tipografia?.NomeFonte === 'Sharon Display' &&
                        'font-sharonDisplay-font font-bold ') ||
                      (tipografia?.NomeFonte === 'Sharon Sans' &&
                        'font-sharonSans-font font-bold') ||
                      (tipografia?.NomeFonte === 'Sharon Serif Italic' &&
                        'font-sharonItalic-font')
                    } md:leading-[clamp(103px,6.8vw,144px)] md:w-full md:text-[clamp(80px,10.65vw,107px)] sm:w-9/12 sm:mt-8 sm:leading-[clamp(64px,6.8vw,80px)] sm:text-[clamp(60px,3.65vw,78px)]  sm:tracking-wide text-home_page text-[clamp(126px,3.65vw,140px)] leading-[clamp(130px,6.8vw,144px)] whitespace-pre-wrap`}
                    dangerouslySetInnerHTML={{
                      __html: tipografia?.SubTitulo,
                    }}
                  />
                )}
                <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mt-10 sm:mb-10 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
                  {tipografia?.Descricao}
                </p>
                {IS_CELLPHONE && (
                  <img
                    key={index}
                    src={`${tipografia?.MidiaMobile?.data?.attributes?.url}`}
                    alt={`${tipografia?.MidiaMobile?.data?.attributes?.name}`}
                  />
                )}
                {IS_TABLET_PORT && (
                  <img
                    key={index}
                    src={`${tipografia?.MidiaTablet?.data?.attributes?.url}`}
                    alt={`${tipografia?.MidiaTablet?.data?.attributes?.name}`}
                  />
                )}
                {IS_DESKTOP && (
                  <img
                    key={index}
                    src={`${tipografia?.MidiaDesktop?.data?.attributes?.url}`}
                    alt={`${tipografia?.MidiaDesktop?.data?.attributes?.name}`}
                  />
                )}
                <div className="flex flex-col gap-16 sm:gap-12 md:gap-14">
                  <span className="sm:text-[clamp(14px,6.25vw,25px)] md:text-[clamp(14px,4vw,28px)] text-hover_stone font-inter font-bold text-[clamp(24px,2.25vw,31px)]">
                    {tipografia?.Pesos?.PesoNome}
                  </span>
                  {IS_CELLPHONE && (
                    <img
                      className="sm:w-[82.96%]"
                      src={`${tipografia?.Pesos?.MidiaMobile?.data?.attributes?.url}`}
                      alt={`${tipografia?.Pesos?.MidiaMobile?.data?.attributes?.name}`}
                    />
                  )}
                  {IS_TABLET_PORT && (
                    <img
                      className="md:w-full"
                      src={`${tipografia?.Pesos?.MidiaTablet?.data?.attributes?.url}`}
                      alt={`${tipografia?.Pesos?.MidiaTablet?.data?.attributes?.name}`}
                    />
                  )}
                  {IS_DESKTOP && (
                    <img
                      className="md:w-[45%]"
                      src={`${tipografia?.Pesos?.MidiaDesktop?.data?.attributes?.url}`}
                      alt={`${tipografia?.Pesos?.MidiaDesktop?.data?.attributes?.name}`}
                    />
                  )}
                </div>
              </section>
            ),
          )}
          <section
            id={brandbook?.attributes?.Usos?.Titulo}
            className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
          >
            <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-16 md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] sm:h-3 font-sharonDisplay-font sm:mt-0 sm:mb-6 sm:w-full sm:tracking-wide text-home_page h-24 font-light">
              {brandbook?.attributes?.Usos?.Titulo}
            </h4>
            <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mt-10 sm:mb-10 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
              {brandbook?.attributes?.Usos?.Descricao}
            </p>
            {IS_CELLPHONE && (
              <img
                src={`${brandbook?.attributes?.Usos?.MidiaMobile?.data?.attributes?.url}`}
                alt={`${brandbook?.attributes?.Usos?.MidiaMobile?.data?.attributes?.name}`}
              />
            )}
            {IS_TABLET_PORT && (
              <img
                src={`${brandbook?.attributes?.Usos?.MidiaTablet?.data?.attributes?.url}`}
                alt={`${brandbook?.attributes?.Usos?.MidiaTablet?.data?.attributes?.name}`}
              />
            )}
            {IS_DESKTOP && (
              <img
                src={`${brandbook?.attributes?.Usos?.MidiaDesktop?.data?.attributes?.url}`}
                alt={`${brandbook?.attributes?.Usos?.MidiaDesktop?.data?.attributes?.name}`}
              />
            )}
          </section>
          <section
            id={brandbook?.attributes?.Alinhamentos?.Titulo}
            className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
          >
            <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-16 md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] sm:h-3 font-sharonDisplay-font sm:mt-0 sm:mb-6 sm:w-full sm:tracking-wide text-home_page h-24 font-light">
              {brandbook?.attributes?.Alinhamentos?.Titulo}
            </h4>
            <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mt-10 sm:mb-10 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
              {brandbook?.attributes?.Alinhamentos?.Descricao}
            </p>
            <div className="sm:mb-6 sm:mt-10 flex flex-row items-center gap-5">
              <TfiArrowTopRight
                color="#00AF55"
                size={!IS_CELLPHONE ? 30 : 20}
              />
              <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_stone font-medium font-inter">
                {brandbook?.attributes?.Alinhamentos?.AlinhamentoTitulo}
              </span>
            </div>
            <div className="md:flex md:flex-col md:mb-0 md:gap-0 sm:mt-5 sm:mb-8 sm:flex sm:flex-col sm:gap-10 grid gap-4 grid-cols-2">
              {brandbook?.attributes?.Alinhamentos?.AlinhamentosDeTitulo.map(
                (alinhamento: any, index: number) => (
                  <div
                    key={index}
                    className="md:gap-6 sm:gap-6 sm:mb-4 flex flex-col gap-4 mb-12"
                  >
                    <span className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,3.4vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,20px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(20px,1.565vw,30px)] text-home_page font-inter font-bold">
                      {alinhamento?.Titulo}
                    </span>
                    <img
                      src={`${alinhamento?.Imagem?.data?.attributes?.url}`}
                      alt={`${alinhamento?.Imagem?.data?.attributes?.name}`}
                    />
                    <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mt-0 font-inter text-home_page mt-4">
                      {alinhamento?.Descricao}
                    </p>
                  </div>
                ),
              )}
            </div>
            <div className="sm:mb-6 sm:items-start flex flex-row items-center gap-5">
              <TfiArrowTopRight
                color="#00AF55"
                size={!IS_CELLPHONE ? 30 : 20}
              />
              <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_stone font-medium font-inter">
                {
                  brandbook?.attributes?.Alinhamentos
                    ?.AlinhamentoDeTitulosNosGrafismosTitulo
                }
              </span>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-4 sm:mb-10 sm:mt-12 sm:grid sm:grid-cols-1 grid grid-cols-3 gap-22">
              {brandbook?.attributes?.Alinhamentos?.AlinhamentoTitulosGrafismoImagemVertical.map(
                (alinhamento: any, index: number) => (
                  <div
                    key={index}
                    className="md:mb-4 sm:gap-4 flex flex-col gap-8"
                  >
                    <img
                      src={`${alinhamento?.Imagem?.data?.attributes?.url}`}
                      alt={`${alinhamento?.Imagem?.data?.attributes?.name}`}
                    />
                    <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] text-home_page font-medium font-inter">
                      {alinhamento?.DescricaoImagem}
                    </p>
                  </div>
                ),
              )}
            </div>
            <div className="md:grid md:grid-cols-1 sm:gap-2 sm:grid sm:grid-cols-1 grid grid-cols-2 gap-2">
              {brandbook?.attributes?.Alinhamentos?.AlinhamentoTitulosGrafismoImagemHorizontal.map(
                (alinhamento: any, index: number) => (
                  <div
                    key={index}
                    className="md:mb-8 sm:gap-4 flex flex-col gap-8"
                  >
                    <img
                      src={`${alinhamento?.Imagem?.data?.attributes?.url}`}
                      alt={`${alinhamento?.Imagem?.data?.attributes?.name}`}
                    />
                    <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:w-full text-home_page font-medium font-inter w-5/6">
                      {alinhamento?.DescricaoImagem}
                    </p>
                  </div>
                ),
              )}
            </div>
            <div className="sm:mb-6 sm:mt-10 flex flex-row items-center gap-5">
              <TfiArrowTopRight
                color="#00AF55"
                size={!IS_CELLPHONE ? 30 : 20}
              />
              <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_stone font-medium font-inter">
                {brandbook?.attributes?.Alinhamentos?.AlinhamentoParagrafo}
              </span>
            </div>
            <div className="md:flex md:flex-col md:mb-0 md:gap-0 sm:mt-5 sm:mb-8 sm:flex sm:flex-col sm:gap-10 grid mb-14 gap-4 grid-cols-2 items-end">
              {brandbook?.attributes?.Alinhamentos?.AlinhamentoDeParagrafoExemplos.map(
                (alinhamento: any, index: number) => (
                  <div
                    key={index}
                    className="md:gap-6 sm:gap-6 sm:mb-4 flex flex-col gap-4 mb-12"
                  >
                    <span className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,3.4vw,24px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,20px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(20px,1.565vw,30px)] text-home_page font-inter font-bold">
                      {alinhamento?.Titulo}
                    </span>
                    <img
                      src={`${alinhamento?.Imagem?.data?.attributes?.url}`}
                      alt={`${alinhamento?.Imagem?.data?.attributes?.name}`}
                    />
                    <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] text-home_page font-medium font-inter">
                      {alinhamento?.Descricao}
                    </p>
                  </div>
                ),
              )}
            </div>
          </section>
          <section
            id={brandbook?.attributes?.Espacamentos?.Titulo}
            className="sm:gap-5 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
          >
            <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-[90px] md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] sm:h-3 font-sharonDisplay-font sm:mt-0 sm:mb-6 sm:w-full sm:tracking-wide text-home_page h-24 font-light">
              {brandbook?.attributes?.Espacamentos?.Titulo}
            </h4>
            <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mt-10 sm:mb-10 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
              {brandbook?.attributes?.Espacamentos?.Descricao}
            </p>
            <div className="sm:mt-4 sm:mb-10 md:mb-28 flex flex-col gap-24 mb-48">
              {brandbook?.attributes?.Espacamentos?.Espacamento.map(
                (spacing: any, index: number) => (
                  <div key={index} className="flex flex-col gap-5">
                    <div className="flex flex-row gap-10 items-baseline">
                      <div className="sm:mb-4 md:mb-4 flex flex-row items-center gap-5">
                        <TfiArrowTopRight
                          color="#00AF55"
                          size={!IS_CELLPHONE ? 30 : 20}
                        />
                        <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_stone font-inter">
                          {spacing?.Titulo}
                        </span>
                      </div>
                      <span className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] leading-[clamp(32px,2.3vw,50px)] text-[clamp(20px,1.565vw,30px)] font-inter text-home_page font-medium">
                        {spacing?.Fracao}
                      </span>
                    </div>
                    {IS_CELLPHONE && (
                      <img
                        key={index}
                        src={`${spacing?.MidiaMobile?.data?.attributes?.url}`}
                        alt={`${spacing?.MidiaMobile?.data?.attributes?.name}`}
                      />
                    )}
                    {IS_TABLET_PORT && (
                      <img
                        key={index}
                        src={`${spacing?.MidiaTablet?.data?.attributes?.url}`}
                        alt={`${spacing?.MidiaTablet?.data?.attributes?.name}`}
                      />
                    )}
                    {IS_DESKTOP && (
                      <img
                        key={index}
                        src={`${spacing?.MidiaDesktop?.data?.attributes?.url}`}
                        alt={`${spacing?.MidiaDesktop?.data?.attributes?.name}`}
                      />
                    )}
                  </div>
                ),
              )}
            </div>
          </section>
          <section
            id={brandbook?.attributes?.TipografiasEspecificas?.Titulo}
            className="sm:gap-5 sm:mt-0 md:mt-0 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
          >
            <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:h-[90px] md:-mt-7 md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,34px)] sm:leading-[clamp(80px,10vw,130px)] md:leading-[clamp(90px,16vw,130px)] sm:h-3 font-sharonDisplay-font sm:mt-0 sm:mb-6 sm:w-full text-home_page h-24 font-light">
              {brandbook?.attributes?.TipografiasEspecificas?.Titulo}
            </h4>
            <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:mt-10 sm:mb-10 sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
              {brandbook?.attributes?.TipografiasEspecificas?.Descricao}
            </p>
            <div className="md:grid md:grid-cols-1 sm:mt-0 sm:gap-0 sm:grid sm:grid-cols-1 grid grid-cols-3">
              {brandbook?.attributes?.TipografiasEspecificas?.Exemplos.map(
                (exemplo: any, index: number) => (
                  <div
                    key={index}
                    className="md:mb-16 md:gap-6 sm:gap-7 flex flex-col gap-7"
                  >
                    <span className="md:text-2xl sm:text-1xl sm:text-xl text-home_page font-bold text-3.5xl">
                      {exemplo?.Titulo}
                    </span>
                    <div className="sm:w-full sm:h-[200px] md:h-[377px] bg-hover_stone h-60 w-11/11.45 flex flex-col justify-center items-center">
                      <p className="md:text-[clamp(82px,26vw,96px)] sm:text-[clamp(78px,23.5vw,100px)] text-home_page text-8xl font-bold">
                        {exemplo?.NomeFonte}
                      </p>
                    </div>
                    <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-full sm:w-full sm:mt-0 text-home_page w-4/5">
                      {exemplo?.Descricao}
                    </p>
                  </div>
                ),
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Stone;
