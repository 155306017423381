import { useEffect, useState } from 'react';
import api from '../services/api';
import { useParams } from 'react-router-dom';

interface Menu {
  attributes: {
    Itens: [Name: string, Url: string, Authorization: boolean];
  };
}

export const useMenu = () => {
  const [menu, setMenu] = useState<Menu>();
  const [isLoading, setIsLoading] = useState(false);
  const { brand } = useParams();

  const getMenu = async () => {
    setIsLoading(true)
    // const { data } = await api.get('/menu?populate=Items.Submenu');
    const { data } = await api.get(
      `${
        brand === 'stone'
          ? 'menu-stone?populate=Itens.Submenu'
          : 'menu-ton?populate=Itens.Submenu'
      }`,
    );
    if (data && data.data) setMenu(data.data);
    setIsLoading(false)
  };

  useEffect(() => {
    getMenu();
  }, []);

  return { menu, isLoading };
};
