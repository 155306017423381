import React, { useEffect, useState } from 'react';
import { useAuthBrandBooks } from '../../../hooks/useAuthBrandBooks';
import { TfiArrowTopRight } from 'react-icons/tfi';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Ton: React.FC = () => {
  const { authBrandBook, isLoading } = useAuthBrandBooks();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  function handleButtonClick(url: any) {
    fetch(url, { mode: 'no-cors' })
      .then(response => response.blob())
      .then(blob => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split('/').pop();
        const anchor = document.createElement('a');
        anchor.href = blobURL;
        anchor.setAttribute('download', fileName);
        document.body.appendChild(anchor);
        anchor.click();
        anchor.remove();
      })
      .catch(error => console.log(error));
  }

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] font-tonCondensedVF-font sm:w-full sm:tracking-wide text-hover_ton font-bold"
      >
        {authBrandBook?.attributes?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(20px,1.565vw,30px)] md:mt-4 md:w-full sm:w-full sm:mt-5 sm:mb-0 text-hover_ton w-full mt-14 font-inter whitespace-pre-wrap font-medium">
        {authBrandBook?.attributes?.Descricao}
      </p>
      <section
        id={authBrandBook?.attributes?.Titulo}
        className="flex flex-col mt-14"
      >
        {authBrandBook?.attributes?.Downloads.map(
          (download: any, index: number) => (
            <div
              key={index}
              className="sm:pt-0 md:pt-3 h-36 border-t-2 border-t-hover_ton flex flex-row pt-7 justify-between"
            >
              <div className="sm:gap-3 flex flex-row gap-5 h-11 items-center">
                <TfiArrowTopRight color="#003C00" size={isMobile ? 20 : 35} />
                <span className="md:text-[clamp(10px,4.25vw,29px)] md:leading-[clamp(20px,2.4vw,30px)] sm:text-[clamp(10px,5.25vw,22px)] sm:leading-[clamp(30px,2.4vw,33px)] text-hover_ton font-inter text-[clamp(18px,2.25vw,35px)] leading-[clamp(30px,2.4vw,53px)] font-medium">
                  {download?.Titulo}
                </span>
              </div>
              <div className="sm:flex sm:items-end sm:pb-5 sm:pr-0 md:pr-0 pr-14">
                <button
                  className="text-[clamp(18px,1.25vw,30px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,4.4vw,22px)] sm:leading-[clamp(16px,7.45vw,33px)] sm:text-[clamp(14px,5.19vw,22px)] md:mt-4 md:w-[189px] sm:w-[145px] sm:h-12 w-60 h-16 bg-ton_secondary text-hover_ton rounded-4xl font-inter font-medium"
                  onClick={() => handleButtonClick(download?.DownloadLink)}
                >
                  {download?.TextoBotao}
                </button>
              </div>
            </div>
          ),
        )}
      </section>
    </>
  );
};

export default Ton;
