import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuthBrandBooks } from '../../../hooks/useAuthBrandBooks';
import { TfiArrowTopRight } from 'react-icons/tfi';
import ReactPlayer from 'react-player';
import SkeletonBranded from '../../../components/Skeleton/Branded';

const Stone: React.FC = () => {
  const { brand } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { authBrandBook, isLoading } = useAuthBrandBooks();
  const [observers, setObservers] = useState<IntersectionObserver[]>([]);
  const videoPlayers = useRef<Array<ReactPlayer | null>>([]);
  const playersContainersRef = useRef<Array<React.RefObject<HTMLDivElement>>>(
    [],
  );
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  useEffect(() => {
    videoPlayers.current = Array.from({ length: 1 }, () => null);
    playersContainersRef.current = Array.from({ length: 1 }, () =>
      React.createRef<HTMLDivElement>(),
    );
    const newObservers = playersContainersRef.current.map(
      (_, index) =>
        new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.seekTo(0);
                  videoPlayers.current[index]?.getInternalPlayer().play();
                }
              } else {
                if (videoPlayers.current[index]) {
                  videoPlayers.current[index]?.getInternalPlayer().pause();
                  videoPlayers.current[index]?.seekTo(0);
                }
              }
            });
          },
          { threshold: 0.2 },
        ),
    );

    // Set the state with the initialized arrays
    setObservers(newObservers);

    return () => {
      // Clean up observers when component unmounts
      newObservers.forEach(observer => observer.disconnect());
    };
  }, []);

  useEffect(() => {
    // Observe player containers when isLoading changes
    playersContainersRef.current.forEach((ref, index) => {
      if (ref.current) {
        observers[index].observe(ref.current);
      }
    });
  }, [isLoading]);

  function handleButtonClick(url: any) {
    fetch(url, { mode: 'no-cors' })
      .then(response => response.blob())
      .then(blob => {
        const blobURL = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split('/').pop();
        const anchor = document.createElement('a');
        anchor.href = blobURL;
        anchor.setAttribute('download', fileName);
        document.body.appendChild(anchor);
        anchor.click();
        anchor.remove();
      })
      .catch(error => console.log(error));
  }

  if (authBrandBook === undefined && !isLoading)
    navigate(`/${brand}/login`, {
      state: { from: location.pathname, id: location.state?.id },
    });

  if (isLoading) return <SkeletonBranded />;

  return (
    <>
      <h3
        id="Inicio"
        className="text-[clamp(90px,7.3vw,140px)] leading-[clamp(80px,7vw,130px)] md:text-[clamp(50px,8.16vw,68px)] md:leading-[clamp(90px,16vw,130px)] sm:text-[clamp(34px,10.7vw,46px)] sm:leading-[clamp(80px,10vw,130px)] font-sharonDisplay-font sm:w-full sm:tracking-wide text-home_page font-bold"
      >
        {authBrandBook?.attributes?.Titulo}
      </h3>
      <p className="md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] leading-[clamp(22px,2.3vw,40px)] text-[clamp(20px,1.565vw,30px)] md:mt-4 md:w-full sm:w-full sm:mt-5 sm:mb-0 text-home_page w-full mt-14 font-inter whitespace-pre-wrap">
        {authBrandBook?.attributes?.Descricao}
      </p>
      {authBrandBook?.attributes?.Secao?.map((secao: any, index: number) => (
        <section
          key={index}
          id={secao?.Titulo}
          className="sm:gap-8 flex flex-col gap-14 border-t-2 border-t-hover_stone mt-14"
        >
          <h4 className="text-[clamp(50px,3.65vw,70px)] leading-[clamp(50px,6.8vw,130px)] md:text-[clamp(30px,5.76vw,48px)] sm:text-[clamp(20px,8vw,33px)] sm:leading-[clamp(45px,8vw,59px)] md:h-14 md:mt-5 md:leading-12 sm:h-12 font-sharonDisplay-font sm:mt-5 sm:w-full sm:tracking-wide text-home_page h-20 font-light">
            {secao?.Titulo}
          </h4>
          <div className="sm:gap-3 flex flex-row items-end gap-5">
            <TfiArrowTopRight color="#00AF55" size={isMobile ? 20 : 30} />
            <span className="text-[clamp(30px,3.57vw,41px)] leading-[clamp(30px,2.75vw,50px)] sm:text-[clamp(10px,5.55vw,20px)] sm:leading-[clamp(12px,11.7vw,22px)] md:leading-[clamp(22px,4.318vw,32px)] md:text-[clamp(14px,5.4vw,26px)] text-hover_stone font-inter">
              {secao?.TituloSecao}
            </span>
          </div>
          <p className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] sm:font-medium font-inter text-home_page font-normal whitespace-pre-wrap">
            {secao?.Descricao}
          </p>
          <div key={index} className="md:w-full sm:w-full w-full">
            <div ref={playersContainersRef.current[0]}>
              <ReactPlayer
                ref={ref => (videoPlayers.current[0] = ref)}
                muted
                className="md:w-full sm:mt-1 sm:w-full w-full mt-6"
                url={`${secao?.Video?.data?.attributes?.url}`}
                controls={false}
                height={'100%'}
                width={'100%'}
              />
            </div>
          </div>
          <button
            className="text-[clamp(18px,1.25vw,24px)] leading-[clamp(30px,2.4vw,40px)] md:leading-[clamp(22px,4.318vw,36px)] md:text-[clamp(14px,2.4vw,20px)] sm:leading-[clamp(16px,7.45vw,32px)] sm:text-[clamp(14px,5.19vw,18px)] md:w-472 sm:w-52 w-72 h-12 bg-hover_stone text-stone rounded-3xl font-inter font-medium"
            onClick={() => handleButtonClick(secao?.DownloadLink)}
          >
            {secao?.DownloadTexto}
          </button>
        </section>
      ))}
    </>
  );
};

export default Stone;
