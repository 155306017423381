import React from 'react';
import { Skeleton as SkeletonBase } from 'antd';

const SkeletonHome: React.FC = () => (
  <div className="w-screen sm:h-full min-h-screen flex flex-col items-center justify-center bg-home_page pt-10">
    <SkeletonBase
      active
      className="sm:mt-5 w-[80%] invert mb-[10%] max-w-[1500px]"
    />
    <SkeletonBase
      active
      title={false}
      className="sm:w-[70%] w-[56%] mr-[10%] self-end max-w-[1500px] invert"
    />
    <div className="mt-36 flex flex-row gap-24 w-full justify-center md:flex-col items-center">
      <SkeletonBase.Image
        active
        style={{ width: '250px', height: '250px', filter: 'invert(1)' }}
      />
      <SkeletonBase.Image
        active
        className="sm:mb-5 mb-0"
        style={{ width: '250px', height: '250px', filter: 'invert(1)' }}
      />
    </div>
  </div>
);

export default SkeletonHome;
