import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFooter } from '../../hooks/useFooter';
import Layout from '../../components/Layout';
import ScrollToTop from '../../components/ScrollToTop';
import Stone from './Stone';
import Ton from './Ton';

const Cores: React.FC = () => {
  const { brand } = useParams();
  const { footer } = useFooter();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  return (
    <Layout brand={brand} footer={footer} isMobile={isMobile}>
      {brand === 'stone' ? <Stone /> : <Ton />}
      <ScrollToTop />
    </Layout>
  );
};

export default Cores;
