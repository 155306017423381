import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Menu from '../Menu';
import Footer from '../Footer';
import ResponsiveSideBarMenu from '../ResponsiveSidebarMenu';
import { InstantSearch } from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch/lite';
import SearchBox from '../SearchBox';
import { useHeader } from '../../hooks/useHeader';
import '../Header/styles.css';

const searchClient = algoliasearch(
  `${process.env.REACT_APP_ALGOLIA_APP_ID}`,
  `${process.env.REACT_APP_ALGOLIA_APP_KEY}`,
);

const Layout = ({ brand, footer, isMobile, children }: any) => {
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState('');
  const [isHoveredSubmenu, setIsHoveredSubmenu] = useState('');
  const [isClicked, setIsClicked] = useState(false);
  const { header } = useHeader();
  const [isCellPhone, setIsCellPhone] = useState(
    window.matchMedia('(max-width: 1279px)').matches,
  );

  useEffect(() => {
    setIsCellPhone(window.matchMedia('(max-width: 1279px)').matches);
    window.addEventListener('resize', () => {
      setIsCellPhone(window.matchMedia('(max-width: 1279px)').matches);
    });
  }, []);

  return (
    <div
      className={`${
        brand === 'stone' ? 'bg-stone' : 'bg-ton'
      } h-full overflow-hidden w-screen mx-auto min-h-screen pb-8`}
    >
      <ResponsiveSideBarMenu
        isOpen={open}
        setOpen={setOpen}
        brand={brand}
        isHovered={isHovered}
        setIsHovered={setIsHovered}
        isHoveredSubmenu={isHoveredSubmenu}
        setIsHoveredSubmenu={setIsHoveredSubmenu}
      />
      <Header
        open={open}
        setOpen={setOpen}
        setIsClicked={setIsClicked}
        isClicked={isClicked}
        isCellPhone={isCellPhone}
      />
      {isCellPhone && isClicked && (
        <InstantSearch
          searchClient={searchClient}
          indexName={brand === 'stone' ? 'stone-brandbook' : 'ton-brandbook'}
        >
          <div className="flex flex-col items-center px-9 md:w-full">
            <SearchBox
              brand={brand}
              placeholder={`${header?.attributes?.TextoDeBusca}`}
              className={`${
                brand === 'stone'
                  ? 'custom-SearchBox-input-stone'
                  : 'custom-SearchBox-input-ton'
              } w-full border-b-2 ${
                brand === 'stone'
                  ? 'border-b-home_page placeholder-home_page'
                  : 'border-b-hover_ton placeholder-hover_ton'
              } bg-transparent w-full text-sm pt-5 pb-2 outline-none`}
            />
          </div>
        </InstantSearch>
      )}
      <div id="content" className="flex flex-row mt-10 px-36 md:px-20 sm:px-9">
        <Menu
          brand={brand}
          isHovered={isHovered}
          setIsHovered={setIsHovered}
          isHoveredSubmenu={isHoveredSubmenu}
          setIsHoveredSubmenu={setIsHoveredSubmenu}
        />
        <main
          className={`${
            open
              ? 'h-0 ease-in duration-300'
              : 'h-full w-full sm:mb-0 sm:px-0 xm:pt-0 md:mb-16 md:px-0 pt-5 flex flex-col pl-72'
          }`}
        >
          {children}
          <Footer
            footer={footer}
            isMobile={isMobile}
            open={open}
            brand={brand}
          />
        </main>
      </div>
    </div>
  );
};
export default Layout;
