import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFooter } from '../../hooks/useFooter';
import Layout from '../../components/Layout';
import ScrollToTop from '../../components/ScrollToTop';
import { useAuthBrandBooks } from '../../hooks/useAuthBrandBooks';
import Stone from './Stone';
import Ton from './Ton';

const TomDeVoz: React.FC = () => {
  const { brand } = useParams();
  const { footer } = useFooter();
  const location = useLocation();
  const { authBrandBook, isLoading } = useAuthBrandBooks();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 767px)').matches,
  );

  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    window.addEventListener('resize', () => {
      setIsMobile(window.matchMedia('(max-width: 767px)').matches);
    });
  }, []);

  if (authBrandBook === undefined && !isLoading)
    navigate(`/${brand}/login`, {
      state: { from: location.pathname, id: location.state?.id },
    });

  return (
    <Layout brand={brand} footer={footer} isMobile={isMobile}>
      {brand === 'stone' ? <Stone /> : <Ton />}
      <ScrollToTop />
    </Layout>
  );
};

export default TomDeVoz;
