import React from 'react';
import { Skeleton, Skeleton as SkeletonBase } from 'antd';
import { useParams } from 'react-router-dom';

const SkeletonMenu: React.FC = () => {
  const { brand } = useParams()
  return (
    <aside className="flex flex-col -mt-20 gap-8 md:hidden sm:hidden fixed overflow-scroll h-screen w-full max-w-[12rem]">
      <Skeleton.Avatar active size="large" style={{ width: "30%", aspectRatio: 1, height: "auto" }}/>
      <div
        className={`h-screen flex-col flex-shrink-0 flex gap-5 mr-6 pt-6 border-t-2 ${brand === 'stone' ? 'border-t-hover_stone' : 'border-t-hover_ton'
          }  md:hidden pb-8`}>
        <SkeletonBase active title={false} />
        <SkeletonBase active title={false} />
        <SkeletonBase active title={false} />
        <SkeletonBase active title={false} />
        <SkeletonBase active title={false} />
        <SkeletonBase active title={false} />
        <SkeletonBase active title={false} />
        <SkeletonBase active title={false} />
        <SkeletonBase active title={false} />
      </div>
    </aside>
  )
};

export default SkeletonMenu;